import React, { useEffect, useMemo, useState } from "react";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import AuditsListing from "../../components/Audits/AuditsListing";
import PageHeader from "../../components/UI/PageWrapper/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { getAuditsAction } from "../../store/Audits/actions";
import { formatTimeWithSec } from "../../utils/timeUtils";
import AuditFilterCard from "./AuditFilterCard";
import { useSearchParams } from "react-router-dom";
import { HasPermission } from "../../utils/HasPermission";
import NotPermitted from "../../components/UI/NotPermitted"

function getTodayAndBefore7Days() {
  const currentDate = new Date();

  const sevenDaysAgo = new Date(currentDate);
  sevenDaysAgo.setDate(currentDate.getDate() - 7);

  const todayDateString = currentDate.toISOString().split("T")[0];
  const sevenDaysAgoDateString = sevenDaysAgo.toISOString().split("T")[0];

  return {
    today: todayDateString,
    sevenDaysAgo: sevenDaysAgoDateString,
  };
}

const dates = getTodayAndBefore7Days();

const filtersInitialState = {
  policyName: [],
  startDate: dates.sevenDaysAgo,
  endDate: dates.today,
  currentPage: 1,
  perPage: 50,
  actionBy:[]
};
function Audits({ permissions }) {
  const dispatch = useDispatch();
  const { AuditsList, auditFilters } = useSelector((state) => state.Audits);
  let [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState({
    ...filtersInitialState,
    currentPage: 1,
    perPage: 50,
  });

  // useEffect(() => {
  //   dispatch(
  //     getAuditsAction({
  //       ...query,
  //     })
  //   );
  // }, []);
  
  const toSentenceCase = (str = "") => {
    return str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : "";
  };
  const generateActionByOptions = (data) => {
    const uniqueNames = [...new Set(data.map(item => item.action_by.name))];
    console.log("uniqueNames", uniqueNames)
  }

  const rows = useMemo(
    () =>
      AuditsList?.data?.map(
        (
          { _id, module_name, policyName, action_type, action_by, createdAt,userInfo },
          key
        ) => ({
          id: key + 1,
          _id: _id,
          policyName: policyName,
          module: toSentenceCase(module_name),
          action_type: toSentenceCase(action_type),
          action_by: action_by,
          userInfo,
          date: formatTimeWithSec(createdAt),
        })
      ),
    [AuditsList]
  );
  useEffect(() => {
    setSearchParams((params) => {
      params.set("policyName", query?.policyName);
      params.set("currentPage", query.currentPage);
      params.set("perPage", query.perPage);
      params.set("startDate", query?.startDate);
      params.set("endDate", query?.endDate);
      params.set("actionBy", query?.actionBy);
      return params;
    });
  }, [query]);

  if (!HasPermission("crawler_audit_view")) {
    return <NotPermitted />;
  }
  return (
    <PageWrapper>
      <PageHeader header="Audit" />
      <AuditFilterCard
        filters={auditFilters}
        filtersInitialState={filtersInitialState}
        paginationData={AuditsList}
        query={query}
        setQuery={setQuery}
      />
      <AuditsListing
        permissions={permissions}
        rows={rows}
        pagination={AuditsList}
        query={query}
        setQuery={setQuery}
      />
    </PageWrapper>
  );
}

export default Audits;
