import {
  Autocomplete,
  Button,
  Card,
  Grid,
  TablePagination,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import variables from "../../Assets/Styles/variables";
import { SecondaryButton } from "../../components/UI/Buttons/Buttons";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-date-range";
import "./policyReportFilterStyle.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  getPolicyReportAction,
  filtersAction,
} from "../../store/PolicyReport/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomAutoCheckbox from "../../components/UI/CustomAutoCheckbox/CustomAutoCheckbox";
import { useLocation } from "react-router-dom";

const initialSuggestions = {
  policyName: [],
  locations: [],
  network: [],
  device: [{ title: "Mobile" }, { title: "Tablet" }, { title: "Desktop" }],
  reportStatus: ["Skipped", "Running", "Pending", "Completed"],
};

function PolicyReportFilterCard({
  children,
  filters,
  setFilters,
  filtersInitialState,
  pagination,
  query,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const [paginate, setPaginate] = useState({
    currentPage: pagination.currentPage - 1 || 0,
    perPage: pagination.perPage || 10,
    totalCount: pagination.totalCount || 0,
    totalPages: Math.ceil(pagination.totalCount / pagination.perPage) || 0,
  });
  const [suggestions, setSuggestions] = useState(initialSuggestions);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const { filterOptions } = useSelector((state) => state.PolicyReport);

  const handleSelectionChange = (result, label) => {
    const filterKeys = {
      "Policy Name": "policiesName",
      Locations: "locations",
      Device: "device",
      Networks: "networks",
    };

    const valueKeyMap = {
      Locations: "|",
      default: ",",
    };

    const key = filterKeys[label];
    const delimiter = valueKeyMap[label] || valueKeyMap.default;
    const valueToSave = result
      .map((itm) => (label === "Networks" ? itm.id : itm.title))
      .join(delimiter);

    if (key) {
      setFilters({ ...filters, [key]: valueToSave });
    }
  };

  const filterPolicyReports = () => {
    dispatch(
      getPolicyReportAction({
        startDate: filters.startDate,
        endDate: filters.endDate,
        policyName: filters.policiesName,
        location: filters.locations,
        device: filters.device,
        network: filters.networks,
        reportStatus: filters.status,
        currentPage: query.currentPage,
        perPage: query.perPage,
      })
    );
  };

  const clearFilters = () => {
    setFilters(filtersInitialState);
    dispatch(filtersAction(filtersInitialState));
    filterPolicyReports();
  };

  const handleInput = ({ name, value }) => {
    setFilters((data) => ({ ...data, [name]: value }));
  };

  const onDateChange = (ranges) => {
    const startDate = new Date(ranges?.selection?.startDate);
    const endDate = new Date(ranges?.selection?.endDate);
    startDate.setHours(23, 59, 59, 999);
    endDate.setHours(23, 59, 59, 999);
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];
    handleInput({ name: "startDate", value: formattedStartDate });
    handleInput({ name: "endDate", value: formattedEndDate });
  };

  const getFilters = () => ({
    startDate: filters.startDate,
    endDate: filters.endDate,
    policyName: filters.policyName,
    location: filters.locations,
    device: filters.device,
    network: filters.networks,
    reportStatus: filters.status,
  });

  const handleChangePage = (event, newPage) => {
    const params = getFilters();
    const updatedPaginate = {
      ...paginate,
      currentPage: newPage,
    };
    setPaginate(updatedPaginate);
    dispatch(
      getPolicyReportAction({
        ...params,
        currentPage: newPage + 1,
        perPage: paginate.perPage,
      })
    );
  };
  const getUniqueSuggestions = (filterArray, titleKey) => {
    return filterArray?.reduce((uniqueNames, element) => {
      const KeyId =
        titleKey === "title" ? uniqueNames?.length + 1 : element._id;
      const title = titleKey === "title" ? element : element[titleKey];
      const existingName = uniqueNames?.find((item) => item?.title === title);

      if (!existingName) {
        uniqueNames?.push({
          id: KeyId,
          title: title,
        });
      }

      return uniqueNames;
    }, []);
  };

  const handleChangeRowsPerPage = (event) => {
    const params = getFilters();
    const perPage = parseInt(event.target.value, 10);
    const updatedPaginate = {
      ...paginate,
      perPage: perPage,
      currentPage: 0,
      totalPages: Math.ceil(paginate.totalCount / perPage),
    };
    setPaginate(updatedPaginate);
    dispatch(
      getPolicyReportAction({
        ...params,
        perPage: perPage,
        currentPage: 1,
      })
    );
  };

  const transformStringToArrayOfObjects = (str, key) => {
    if (str === "" || str.length === 0) {
      return [];
    } else {
      if (key === "locations") {
        return str?.split("|").map((item) => ({ title: item.trim() }));
      } else if (key === "networks") {
        const idList = str?.split(",").map((item) => ({ title: item.trim() }));
        const foundSuggestions = suggestions?.network?.filter((suggestion) =>
          idList.some((idObj) => suggestion.id === idObj.title)
        );
        return foundSuggestions;
      } else {
        return str?.split(",").map((item) => ({ title: item.trim() }));
      }
    }
  };


  useEffect(() => {
    let temp = paginate;
    if (pagination.hasOwnProperty("currentPage")) {
      temp.currentPage = pagination.currentPage - 1;
    }
    if (pagination.hasOwnProperty("perPage")) {
      temp.perPage = pagination.perPage;
    }
    if (pagination.hasOwnProperty("totalCount")) {
      temp.totalCount = pagination.totalCount;
    }
    if (pagination.hasOwnProperty("totalPages")) {
      temp.totalPages = pagination.totalPages;
    }
    setPaginate({ ...temp });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let searchQuery = {};

    const addToSearchQuery = (key) => {
      const values = searchParams.getAll(key);
      if (values.length > 0) {
        searchQuery[key] = values;
      }
    };

    addToSearchQuery("policiesName");
    addToSearchQuery("startDate");
    addToSearchQuery("endDate");
    addToSearchQuery("locations");
    addToSearchQuery("device");
    addToSearchQuery("networks");

    const cleanedObj = Object.fromEntries(
      Object.entries(searchQuery).filter(
        ([_, value]) =>
          !(Array.isArray(value) && value.length === 1 && value[0] === "")
      )
    );
    setFilters({
      ...filters,
      policiesName: Array.isArray(cleanedObj?.policiesName)
        ? cleanedObj?.policiesName[0] || []
        : cleanedObj?.policiesName || [],
      startDate: Array.isArray(cleanedObj?.startDate)
        ? cleanedObj?.startDate[0] || filters.startDate
        : cleanedObj?.startDate || filters.startDate,
      endDate: Array.isArray(cleanedObj?.endDate)
        ? cleanedObj?.endDate[0] || filters.endDate
        : cleanedObj?.endDate || filters.endDate,
      locations: Array.isArray(cleanedObj?.locations)
        ? cleanedObj?.locations[0] || []
        : cleanedObj?.locations || [],
      device: Array.isArray(cleanedObj?.device)
        ? cleanedObj?.device[0] || []
        : cleanedObj?.device || [],
      networks: Array.isArray(cleanedObj?.networks)
        ? cleanedObj?.networks[0] || []
        : cleanedObj?.networks || [],
    });

    dispatch(
      getPolicyReportAction({
        startDate: Array.isArray(cleanedObj?.startDate)
          ? cleanedObj?.startDate[0] || filters.startDate
          : cleanedObj?.startDate || filters.startDate,
        endDate: Array.isArray(cleanedObj?.endDate)
          ? cleanedObj?.endDate[0] || filters.endDate
          : cleanedObj?.endDate || filters.endDate,
        policyName: Array.isArray(cleanedObj?.policiesName)
          ? cleanedObj?.policiesName[0] || ""
          : cleanedObj?.policiesName || "",
        location: Array.isArray(cleanedObj?.locations)
          ? cleanedObj?.locations[0] || ""
          : cleanedObj?.locations || "",
        device: Array.isArray(cleanedObj?.device)
          ? cleanedObj?.device[0] || ""
          : cleanedObj?.device || "",
        network: Array.isArray(cleanedObj?.networks)
          ? cleanedObj?.networks[0] || ""
          : cleanedObj?.networks || "",
        reportStatus: filters.status,
        currentPage: query.currentPage,
        perPage: query.perPage,
      })
    );
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target))
        setShowCalendar(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const uniquePolicyNames = getUniqueSuggestions(
      filterOptions?.titleFilter,
      "title"
    );
    const getLocationSuggestions = getUniqueSuggestions(
      filterOptions?.locationFilter,
      "canonicalName"
    );
    const getNetworkSuggestions = getUniqueSuggestions(
      filterOptions?.networkFilter,
      "name",
      "id"
    );

    setSuggestions({
      ...suggestions,
      policyName: uniquePolicyNames,
      locations: getLocationSuggestions,
      network: getNetworkSuggestions,
    });
  }, [filterOptions]);

  return (
    <Card style={{ borderRadius: "0px" }}>
      <div style={{ padding: "20px" }}>
        <p style={{ fontWeight: "bold", marginBottom: "10px" }}>Filters</p>
        <Grid container spacing={2}>
          <Grid item sm={2.2}>
            <CustomAutoCheckbox
              label="Policy Name"
              items={suggestions.policyName}
              selectAllLabel="Select All"
              value={
                transformStringToArrayOfObjects(
                  filters?.policiesName,
                  "policiesName"
                ) || []
              }
              onChange={handleSelectionChange}
            />
          </Grid>
          <Grid item sm={2.2}>
            <div className="relative-class">
              <input
                type="text"
                placeholder="Click to select dates"
                onClick={() => setShowCalendar(!showCalendar)}
                value={`${filters.startDate} - ${filters.endDate}`}
              />
              {showCalendar && (
                <div ref={calendarRef} className="date-range-picker-wrapper">
                  <DateRangePicker
                    startDatePlaceholder="Start Date"
                    endDatePlaceholder="End Date"
                    ranges={[selectionRange]}
                    onChange={(ranges) => {
                      setSelectionRange(ranges.selection);
                      onDateChange(ranges);
                    }}
                    inputRanges={[]}
                  ></DateRangePicker>
                </div>
              )}
            </div>
          </Grid>
          <Grid item sm={2.2}>
            <CustomAutoCheckbox
              label="Locations"
              items={suggestions.locations}
              selectAllLabel="Select All"
              value={
                transformStringToArrayOfObjects(
                  filters?.locations,
                  "locations"
                ) || []
              }
              onChange={handleSelectionChange}
            />
          </Grid>
          <Grid item sm={2.2}>
            <CustomAutoCheckbox
              label="Device"
              items={suggestions.device}
              selectAllLabel="Select All"
              value={
                transformStringToArrayOfObjects(filters?.device, "device") || []
              }
              onChange={handleSelectionChange}
            />
          </Grid>
          <Grid item sm={2.1}>
            <CustomAutoCheckbox
              label="Networks"
              items={suggestions.network}
              selectAllLabel="Select All"
              value={
                transformStringToArrayOfObjects(
                  filters?.networks,
                  "networks"
                ) || []
              }
              onChange={handleSelectionChange}
            />
          </Grid>

          <Grid item sm={2.1}>
            <Autocomplete
              id="status-select"
              size="small"
              options={suggestions.reportStatus}
              fullWidth
              value={filters.status}
              onChange={(e, value) =>
                setFilters((prevFilters) => {
                  return {
                    ...prevFilters,
                    status: value || ""
                  }
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  variant="filled"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "2px" }}>
          <Grid item sm={4}>
            <Button
              size="small"
              style={{
                marginRight: "10px",
                backgroundColor: variables.primaryColor,
                color: variables.whiteText,
              }}
              onClick={clearFilters}
            >
              clear
            </Button>
            <SecondaryButton size="small" onClick={filterPolicyReports}>
              Apply
            </SecondaryButton>
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: "20px" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 300, 500]}
          component="div"
          count={paginate.totalCount}
          rowsPerPage={paginate.perPage}
          page={paginate.currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <div>{children}</div>
    </Card>
  );
}

export default PolicyReportFilterCard;
