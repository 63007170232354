export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const VERIFY_USERS = "VERIFY_USERS";
export const VERIFY_USERS_SUCCESS = "VERIFY_USERS_SUCCESS";
export const VERIFY_USERS_FAILURE = "VERIFY_USERS_FAILURE";

export const GET_USERS_BY_ID = "GET_USERS_BY_ID";
export const GET_USERS_BY_ID_SUCCESS = "GET_USERS_BY_ID_SUCCESS";
export const GET_USERS_BY_ID_FAILURE = "GET_USERS_BY_ID_FAILURE";

export const VERIFY_TEMP_TOKEN = "VERIFY_TEMP_TOKEN";
export const VERIFY_TEMP_TOKEN_SUCCESS = "VERIFY_TEMP_TOKEN_SUCCESS";
export const VERIFY_TEMP_TOKEN_FAILURE = "VERIFY_TEMP_TOKEN_FAILURE";

export const CREATE_USERS = "CREATE_USERS";
export const CREATE_USERS_SUCCESS = "CREATE_USERS_SUCCESS";
export const CREATE_USERS_FAILURE = "CREATE_USERS_FAILURE";

export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const UPDATE_USERS_FAILURE = "UPDATE_USERS_FAILURE";

export const DELETE_USERS = "DELETE_USERS";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_FAILURE = "DELETE_USERS_FAILURE";

export const SET_USERS_LOADING = "SET_USERS_LOADING";
