/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import NetworksListing from "../../components/Networks/NetworksListings";
import PageHeader from "../../components/UI/PageWrapper/PageHeader";
import { PrimaryButton } from "../../components/UI/Buttons/Buttons";
import { Add } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import NetworkFilterCard from "../../components/Networks/NetworkFilterCard";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import Collapse from "@mui/material/Collapse";
import { getNetworksAction } from "../../store/Networks/actions";
import { HasPermission } from "../../utils/HasPermission";
import NotPermitted from "../../components/UI/NotPermitted";

const ViewLink = ({ showLess, setShowLess }) => {
  return (
    <a
      style={{ color: "blue", cursor: "pointer" }}
      onClick={() => setShowLess(!showLess)}
    >
      {" "}
      &nbsp;View {showLess ? "More" : "Less"}{" "}
    </a>
  );
};

const Notes = ({ notes, cutoff }) => {
  const [showLess, setShowLess] = useState(true);

  if (notes && notes.length > cutoff) {
    return (
      <p>
        <Collapse in={!showLess} collapsedSize={40}>
          {notes}
        </Collapse>
        <ViewLink showLess={showLess} setShowLess={setShowLess} />
      </p>
    );
  } else {
    return <p>{notes}</p>;
  }
};

function Networks({ permissions, lastLocationBeforeUnmount }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalBody = useRef(null);
  const { NetworksList, filters } = useSelector((state) => state.Networks);
  const filtersInitialState = {
    search: "",
    archived: "false",
  };
  let [filterData, setFilters] = useState(filtersInitialState);
  let [openImportModal, setOpenImportModal] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  const rows = useMemo(
    () =>
      NetworksList?.data?.map(
        ({ _id, name, email, domain, country, notes, is_archived }, key) => ({
          id: key + 1,
          _id: _id,
          network: name,
          email: email,
          website: domain,
          country: country,
          notes: <Notes notes={notes} cutoff={85} />,
          archived: is_archived,
        })
      ),
    [NetworksList]
  );

  useEffect(() => {
    if (openImportModal == false) {
      if (modalBody.current) modalBody.current.modalClosed();
    }
  }, [openImportModal]);

  useEffect(() => {
    setSearchParams((params) => {
      params.set("search", filterData?.search);
      params.set("archived", filterData?.archived);
      return params;
    });
  }, [filterData]);

  useEffect(() => {
    setFilters(filters);
  }, [filters]);

  useEffect(() => {
    console.log("from useEffect");
    dispatch(
      getNetworksAction({
        archived: false,
        currentPage: 1,
        perPage: 50,
        ...filters,
      })
    );
  }, []);

  if (!HasPermission("crawler_networks_view")) {
    return <NotPermitted />;
  }

  return (
    <PageWrapper>
      <PageHeader
        header="Networks"
        headerButton={
          HasPermission("crawler_networks_create") ? (
            <div>
              <PrimaryButton
                startIcon={<Add />}
                variant="contained"
                onClick={() => setOpenImportModal(true)}
                style={{ marginRight: "10px" }}
              >
                Import Bulk
              </PrimaryButton>
              <PrimaryButton
                startIcon={<Add />}
                variant="contained"
                onClick={() => navigate("/networks/create")}
              >
                Create Networks
              </PrimaryButton>
            </div>
          ) : (
            <span></span>
          )
        }
      />

      <NetworkFilterCard
        permissions={permissions}
        filtersInitialState={filtersInitialState}
        filters={filters}
        filterData={filterData}
        setFilters={setFilters}
        lastLocationBeforeUnmount={lastLocationBeforeUnmount}
      />

      <NetworksListing
        permissions={permissions}
        rows={rows}
        pagination={NetworksList}
        filters={filters}
      />
    </PageWrapper>
  );
}

export default Networks;
