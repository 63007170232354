import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import variables from "../../Assets/Styles/variables";
import { DateRangePicker } from "react-date-range";
import "react-dates/lib/css/_datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { AdViewFiltersAction } from "../../store/PolicyReport/actions";
import ResetFilters from "../../components/UI/Buttons/ResetFilters";
import CustomAutoCheckbox from "../../components/UI/CustomAutoCheckbox/CustomAutoCheckbox";
import { SecondaryButton } from "../../components/UI/Buttons/Buttons";
import { useLocation } from "react-router-dom";
import { getAllPoliciesTitles } from "../../store/Policy/actions";
import { getAuditsAction } from "../../store/Audits/actions";

const initialSuggestions = {
  policyName: [],
  actionBy: [],
};

function AuditFilterCard({
  filters,
  filtersInitialState,
  paginationData,
  query,
  setQuery,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const calendarRef = useRef(null);

  const { titles } = useSelector((state) => state.Policy);

  const [height, setHeight] = useState(200);
  // const [filter, setFilter] = useState(query);
  const [showCalendar, setShowCalendar] = useState(false);
  const [suggestions, setSuggestions] = useState(initialSuggestions);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const filterHeading = useRef();
  const filterFields = useRef();

  const clearFilters = () => {
    dispatch(
      getAuditsAction({
        ...filtersInitialState,
        currentPage: paginationData?.currentPage,
        perPage: paginationData?.perPage,
      })
    );
    setQuery({ ...filtersInitialState });
  };

  const handleInput = ({ name, value }) => {
    setQuery((data) => ({ ...data, [name]: value }));
  };

  const onDateChange = (ranges) => {
    const startDate = new Date(ranges?.selection?.startDate);
    const endDate = new Date(ranges?.selection?.endDate);
    startDate.setHours(23, 59, 59, 999);
    endDate.setHours(23, 59, 59, 999);
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];
    handleInput({ name: "startDate", value: formattedStartDate });
    handleInput({ name: "endDate", value: formattedEndDate });
  };

  const getUniqueSuggestions = (filterArray, titleKey) => {
    return filterArray?.reduce((uniqueNames, element) => {
      const KeyId =
        titleKey === "keyword" ? uniqueNames?.length + 1 : element._id;
      const title = titleKey === "title" ? element : element[titleKey];
      const existingName = uniqueNames?.find((item) => item?.title === title);

      if (!existingName) {
        uniqueNames?.push({
          id: KeyId,
          title: title,
        });
      }

      return uniqueNames;
    }, []);
  };

  const transformStringToArrayOfObjects = (str, key) => {
    if (str === "" || str.length === 0) {
      return [];
    } else {
      if (key === "locations") {
        return str?.split("|").map((item) => ({ title: item.trim() }));
      } else if (key === "networks") {
        const idList = str?.split(",").map((item) => ({ title: item.trim() }));
        const foundSuggestions = suggestions?.network?.filter((suggestion) =>
          idList.some((idObj) => suggestion.id === idObj.title)
        );
        return foundSuggestions;
      } else {
        return str?.split(",").map((item) => ({ title: item.trim() }));
      }
    }
  };

  const handleSelectionChange = (result, label) => {
    const filterKeys = {
      "Policy Name": "policyName",
    };

    const valueKeyMap = {
      Locations: "|",
      default: ",",
    };

    const key = filterKeys[label];
    const delimiter = valueKeyMap[label] || valueKeyMap.default;
    const valueToSave = result
      .map((itm) => (label === "Networks" ? itm.id : itm.title))
      .join(delimiter);

    if (key) {
      setQuery({ ...query, [key]: valueToSave });
    }
  };

  const filterPolicies = () => {
    dispatch(
      getAuditsAction({
        ...query,
        currentPage: paginationData?.currentPage,
        perPage: paginationData?.perPage,
      })
    );
  };

  useEffect(() => {
    const uniquePolicyNames = getUniqueSuggestions(
      titles?.titleFilter,
      "title"
    );

    setSuggestions({
      ...suggestions,
      policyName: uniquePolicyNames,
      actionBy: ["All", ...(titles?.actionByFilter || [])],
    });
  }, [titles]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target))
        setShowCalendar(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    setHeight(
      filterHeading.current.clientHeight +
        filterFields.current.clientHeight +
        20
    );
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let searchQuery = {};

    const addToSearchQuery = (key) => {
      const values = searchParams.getAll(key);
      if (values.length > 0) {
        searchQuery[key] = values;
      }
    };
    addToSearchQuery("policyName");
    addToSearchQuery("startDate");
    addToSearchQuery("endDate");
    addToSearchQuery("actionBy");

    const cleanedObj = Object.fromEntries(
      Object.entries(searchQuery).filter(
        ([_, value]) =>
          !(Array.isArray(value) && value.length === 1 && value[0] === "")
      )
    );

    const handleArrayField = (cleanedObj, fieldName, defaultValue = []) =>
      Array.isArray(cleanedObj?.[fieldName])
        ? cleanedObj?.[fieldName][0] || defaultValue
        : cleanedObj?.[fieldName] || defaultValue;
    const updatedFilter = {
      ...query,
      policyName: handleArrayField(cleanedObj, "policyName", query.policyName),
      startDate: handleArrayField(cleanedObj, "startDate", query.startDate),
      endDate: handleArrayField(cleanedObj, "endDate", query.endDate),
      actionBy: handleArrayField(cleanedObj, "actionBy", query.actionBy),
    };

    setQuery(updatedFilter);
    dispatch(getAuditsAction(updatedFilter));
    dispatch(getAllPoliciesTitles());
  }, []);

  return (
    <Box style={{ position: "relative" }}>
      <Card
        style={{
          borderRadius: "0px",
          width: "100%",
          height: height + "px",
          position: "absolute",
          zIndex: "-9",
        }}
      ></Card>
      <Grid
        container
        ref={filterHeading}
        style={{ padding: "20px 20px 0 20px" }}
      >
        <Grid item sm={12}>
          <p
            style={{
              fontWeight: "bold",
              marginRight: "5px",
              display: "inline-block",
            }}
          >
            Filters{" "}
          </p>
          <ResetFilters resetFilters={clearFilters} />
        </Grid>
      </Grid>
      <Grid
        container
        ref={filterFields}
        spacing={2}
        style={{ margin: "0 0 40px 4px" }}
      >
        <Grid item sm={2.2}>
          <CustomAutoCheckbox
            label="Policy Name"
            items={suggestions.policyName}
            selectAllLabel="Select All"
            value={
              transformStringToArrayOfObjects(
                query?.policyName,
                "policyName"
              ) || []
            }
            onChange={handleSelectionChange}
          />
        </Grid>
        <Grid item sm={2.2}>
          <div className="relative-class">
            <input
              type="text"
              placeholder="Click to select dates"
              onClick={() => setShowCalendar(!showCalendar)}
              value={`${query.startDate} - ${query.endDate}`}
            />
            {showCalendar && (
              <div ref={calendarRef} className="date-range-picker-wrapper">
                <DateRangePicker
                  startDatePlaceholder="Start Date"
                  endDatePlaceholder="End Date"
                  ranges={[selectionRange]}
                  onChange={(ranges) => {
                    setSelectionRange(ranges.selection);
                    onDateChange(ranges);
                  }}
                  inputRanges={[]}
                ></DateRangePicker>
              </div>
            )}
          </div>
        </Grid>
        <Grid item sm={2.2}>
          <Autocomplete
            id="status-select"
            size="small"
            options={suggestions?.actionBy || []}
            fullWidth
            value={query.actionBy}
            onChange={(e, value) =>
              handleInput({
                name: "actionBy",
                value: value !== null ? [value] : [],
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Action By"
                variant="filled"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid container spacing={2} style={{ padding: "20px 20px 0 20px" }}>
          <Grid item sm={4}>
            <Button
              size="small"
              style={{
                marginRight: "10px",
                backgroundColor: variables.primaryColor,
                color: variables.whiteText,
              }}
              onClick={clearFilters}
            >
              clear
            </Button>
            <SecondaryButton size="small" onClick={filterPolicies}>
              Apply
            </SecondaryButton>
          </Grid>
        </Grid>
      </Grid>
      {/* <div>{children}</div> */}
    </Box>
  );
}

export default AuditFilterCard;
