import { Grid, Paper } from "@mui/material";
import PageWrapper from "./PageWrapper/PageWrapper";
import LinearProgress from "@mui/material/LinearProgress";

function ComingSoon() {
  return (
    <PageWrapper>
      <Grid style={{ marginTop: "20px" }}>
        <div>
          <Paper style={{ padding: "30px", borderRadius: "15px" }}>
            <Grid
              style={{ height: "550px" }}
              container
              justifyContent="center"
              alignItems="center"
            >
              <div style={{ width: "300px" }}>
                <LinearProgress color="error" />
              </div>
            </Grid>
          </Paper>
        </div>
      </Grid>
    </PageWrapper>
  );
}

export default ComingSoon;
