import { ThemeProvider, makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import variable from "../../../Assets/Styles/variables";
import ADBLOGO from "../../../Assets/adb-crawler-logo.png";

let theme = createTheme();

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      height: "auto",
    },
  },
  imageContainer: {
    background: `url(${ADBLOGO}) no-repeat center center/contain`,
    backgroundColor: variable.primaryColor,
    width: "50%",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "30vh",
    },
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "70vh",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    background: "#fff",
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

function UnauthPageWrapper(props) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.imageContainer}></div>
        <div className={classes.formContainer}>{props.children}</div>
      </div>
    </ThemeProvider>
  );
}

export default UnauthPageWrapper;
