export const DO_LOGIN = "DO_LOGIN";
export const DO_LOGIN_SUCCESS = "DO_LOGIN_SUCCESS";
export const DO_LOGIN_FAILURE = "DO_LOGIN_FAILURE";

export const DO_ONBOARDING = "DO_ONBOARDING";
export const DO_ONBOARDING_SUCCESS = "DO_ONBOARDING_SUCCESS";
export const DO_ONBOARDING_FAILURE = "DO_ONBOARDING_FAILURE";

export const DO_LOGOUT = "DO_LOGOUT";
export const DO_LOGOUT_SUCCESS = "DO_LOGOUT_SUCCESS";
export const DO_LOGOUT_FAILURE = "DO_LOGOUT_FAILURE";

export const SET_ACCOUNT_LOADING = "SET_ACCOUNT_LOADING";
export const SET_TOKEN_FROM_LOCALSTORAGE = "SET_TOKEN_FROM_LOCALSTORAGE";

export const SEND_RESET_PASSWORD_LINK = "SEND_RESET_PASSWORD_LINK";
export const SEND_RESET_PASSWORD_LINK_SUCCESS = "SEND_RESET_PASSWORD_LINK_SUCCESS";
export const SEND_RESET_PASSWORD_LINK_FAILURE = "SEND_RESET_PASSWORD_LINK_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";