import { put, all, takeLatest, call, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../utils/API";
import axios from "axios";

const prepareLocations = (locations) => {
  return locations.map((location) => {
    if (location.hasOwnProperty("canonical_name")) {
      return {
        _id: location._id || "",
        name: location.name || "",
        canonicalName: location.canonical_name || "",
        countryCode: location.country_code || "",
        criteriaId: location.criteria_id || "",
        targetType: location.target_type || "",
        timezones: location.timezones || "",
        languages: location.languages || "",
        googleDomain: location.google_domain || "",
      };
    } else {
      return location;
    }
  });
};

function* createPolicySaga({ params }) {
  const { navigate, ...rest } = params;
  rest.location = prepareLocations(rest.location);

  try {
    yield put({ type: actionTypes.SET_POLICY_LOADING });
    const { data } = yield API.post("/api/policies", rest);

    if (data.success) {
      yield put({
        type: actionTypes.CREATE_POLICY_SUCCESS,
        payload: data,
      });
      toast.success("Successfully Create The policy ");
      navigate("/policies");
    } else {
      toast.error("Unable Create The policy");
      yield put({
        type: actionTypes.CREATE_POLICY_FAILURE,
        errorMessage: data.message || "Unable Create The policy",
      });
    }
  } catch (error) {
    toast.error("Unable Create The policy");
    yield put({
      type: actionTypes.CREATE_POLICY_FAILURE,
      errorMessage: error.message || "Unable Create The policy",
    });
  }
}

function* updatePolicySaga({ params }) {
  const { navigate, id, ...rest } = params;
  rest.location = prepareLocations(rest.location);
  try {
    yield put({ type: actionTypes.SET_POLICY_LOADING });
    const { data } = yield API.put(`/api/policies/${id}/update`, rest);
    if (data.success) {
      yield put({
        type: actionTypes.UPDATE_POLICY_SUCCESS,
        payload: data,
      });
      toast.success("Successfully Update The policy ");
      navigate("/policies");
    } else {
      toast.error("Unable Update The policy");
      yield put({
        type: actionTypes.UPDATE_POLICY_FAILURE,
        errorMessage: data.message || "Unable Update The policy",
      });
    }
  } catch (error) {
    toast.error("Unable Update The policy");
    yield put({
      type: actionTypes.UPDATE_POLICY_FAILURE,
      errorMessage: error.message || "Unable Update The policy",
    });
  }
}

function* GetPolicySaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_POLICY_LOADING });
    const { data } = yield API.get("/api/policies", { params: params });

    if (data.success) {
      yield put({
        type: actionTypes.GET_POLICY_SUCCESS,
        payload: data,
      });
    } else {
      toast.error("Unable Get The Policies");
      yield put({
        type: actionTypes.GET_POLICY_FAILURE,
        errorMessage: "Unable Get The Policies",
      });
    }
  } catch (error) {
    toast.error("Unable Get The Policies");
    yield put({
      type: actionTypes.GET_POLICY_FAILURE,
      errorMessage: "Unable Get The Policies",
    });
  }
}

function* GetPolicyByIdSaga({ Id }) {
  try {
    yield put({ type: actionTypes.SET_POLICY_LOADING });
    const { data } = yield API.get(`/api/policies/${Id}`);

    if (data.success) {
      yield put({
        type: actionTypes.GET_POLICY_BY_ID_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error("Unable Get The Policies");
      yield put({
        type: actionTypes.GET_POLICY_BY_ID_FAILURE,
        errorMessage: "Unable Get The Policies",
      });
    }
  } catch (error) {
    toast.error("Unable Get The Policies");
    yield put({
      type: actionTypes.GET_POLICY_BY_ID_FAILURE,
      errorMessage: "Unable Get The Policies",
    });
  }
}

function* DeletePolicyByIdSaga({ params }) {
  const { navigate, Id } = params;
  try {
    yield put({ type: actionTypes.SET_POLICY_LOADING });
    const { data } = yield API.post(`/api/policies/single/${Id}`);
    if (data.success) {
      yield put({
        type: actionTypes.DELETE_POLICY_SUCCESS,
        payload: data.data,
      });
      toast.success("Successfully Delete The Policies ");
      if (navigate) {
        navigate("/policies");
      }
      yield put({ type: actionTypes.GET_POLICY, currentPage: 1, perPage: 20 });
    } else {
      toast.error("Unable Delete The Policies");
      yield put({
        type: actionTypes.DELETE_POLICY_FAILURE,
        errorMessage: "Unable Delete The Policies",
      });
    }
  } catch (error) {
    toast.error("Unable Delete The Policies");
    yield put({
      type: actionTypes.DELETE_POLICY_FAILURE,
      errorMessage: "Unable Delete The Policies",
    });
  }
}

function* bulkDeletePoliciesSaga({ payload }) {
  const { policyIds, navigate } = payload;
  try {
    yield put({ type: actionTypes.SET_POLICY_LOADING });

    const { data } = yield API.post("/api/policies/bulk", { policyIds });
    if (data.success) {
      yield put({
        type: actionTypes.BULK_DELETE_POLICIES_SUCCESS,
        payload: data.data,
      });
      navigate("/policies");
      yield put({ type: actionTypes.GET_POLICY });
      toast.success("Successfully Deleted The Policies");
    } else {
      toast.error("Unable to Delete The Policies");
      yield put({
        type: actionTypes.BULK_DELETE_POLICIES_FAILURE,
        errorMessage: "Unable to Delete The Policies",
      });
    }
  } catch (error) {
    toast.error("Unable to Delete The Policies");
    yield put({
      type: actionTypes.BULK_DELETE_POLICIES_FAILURE,
      errorMessage: "Unable to Delete The Policies",
    });
  }
}

function* runNowPolicySaga({ params }) {
  const { dispatch, singleRun, ...rest } = params;
  const policies = rest?.policies;
  try {
    const setLoading = policies.map(({ id, value }) => {
      return { id: id, status: "loading" };
    });
    yield put({ type: actionTypes.SET_RUN_NOW_PROGRESS, payload: setLoading });

    if (!singleRun) {
      const promises = policies.map((policy) => call(runNowPolicyById, policy));
      const result = yield all([...promises]);
      const hasError = result.some((res) => res !== "success");
      if (!hasError) {
        yield put({ type: actionTypes.SET_RUN_NOW_SUCCESS, payload: result });
        toast.success("All selected policies were run succesfully!");
        setTimeout(() => {
          dispatch({ type: actionTypes.SET_RUN_NOW_PROGRESS, payload: [] });
        }, 2000);
      } else {
        yield put({
          type: actionTypes.SET_RUN_NOW_FAILURE,
          errorMessage: "Error while performing bulk policy run.",
        });
      }
    } else {
      const result = yield call(runNowPolicyById, policies[0]);
      if (result === "success") {
        yield put({
          type: actionTypes.SET_SINGLE_RUN_NOW_SUCCESS,
          payload: policies[0],
        });
        toast.success("The policy was run successfully!");
      } else {
        yield put({
          type: actionTypes.SET_SINGLE_RUN_NOW_FAILURE,
          errorMessage: "Not able to run the policy.",
        });
        toast.error("Not able to run the policy.");
      }
    }
  } catch (error) {
    yield put({
      type: actionTypes.SET_RUN_NOW_FAILURE,
      errorMessage: "Not able to skip the next policy run",
    });
  }
}

function* runNowPolicyById({ id, value }) {
  let runNowProgressArr = yield select(getRunNowProgress);
  const index = runNowProgressArr.findIndex((p) => p.id === id);
  try {
    const params = { policy_id: id };
    const { data } = yield API.post("/api/policies/run-now", params);
    if (data.success) {
      runNowProgressArr[index].status = "success";
      yield put({
        type: actionTypes.SET_RUN_NOW_PROGRESS,
        payload: runNowProgressArr,
      });
      toast.success("Policy executed successfully");
    } else {
      runNowProgressArr[index].status = "error";
      yield put({
        type: actionTypes.SET_RUN_NOW_PROGRESS,
        payload: runNowProgressArr,
      });
      toast.error(`Policy ${value} failed to execute`);
    }
    return data.success ? "success" : "error";
  } catch (error) {
    runNowProgressArr[index].status = "error";
    yield put({
      type: actionTypes.SET_RUN_NOW_PROGRESS,
      payload: runNowProgressArr,
    });
    toast.error(`Policy ${value} failed to execute`);
    return "error";
  }
}

function* runNowPolicyMultiple({ parameters }) {
  try {
    const { params, body } = parameters;

    const requestData = { ...body };

    const queryString = new URLSearchParams(params).toString();

    const url = `/api/policies/run-now?${queryString}`;

    const { data } = yield API.post(url, requestData);

    if (data.success) {
      yield put({ type: actionTypes.RUN_NOW_POLICY_MULTIPLE_SUCCESS });
      toast.success("Policies run successfully");
    } else {
      yield put({ type: actionTypes.RUN_NOW_POLICY_MULTIPLE_FAILURE });
      toast.error(`Couldn't run policies.`);
    }
    return data.success ? "success" : "error";
  } catch (error) {
    yield put({ type: actionTypes.RUN_NOW_POLICY_MULTIPLE_FAILURE });
    toast.error(`Couldn't run policies.`);
    return "error";
  }
}

function* pauseResumePoliciesSaga({ parameters }) {
  const { params, body, getParams } = parameters;
  try {
    const requestData = { ...body };

    const queryString = new URLSearchParams(params).toString();

    const url = `/api/policies/pause-resume-policies?${queryString}`;

    const { data } = yield API.post(url, requestData);
    if (data.success) {
      toast.success(data?.message || "Selected policy action successfully");
      yield put({ type: actionTypes.GET_POLICY, params: getParams });
      yield put({ type: actionTypes.PAUSE_RESUME_POLICIES_SUCCESS });
    } else {
      yield put({ type: actionTypes.PAUSE_RESUME_POLICIES_FAILURE });
      toast.error(`Couldn't pause or resume policies.`);
    }
    return data.success ? "success" : "error";
  } catch (error) {
    yield put({ type: actionTypes.PAUSE_RESUME_POLICIES_FAILURE });
    toast.error(`Couldn't pause or resume policies.`);
    return "error";
  }
}

function* skipNextPolicyMultipleSaga({ parameters }) {
  try {
    const { params, body } = parameters;

    const requestData = { ...body };

    const queryString = new URLSearchParams(params).toString();

    const url = `/api/policies/skip-next?${queryString}`;

    const { data } = yield API.put(url, requestData);
    if (data.success) {
      toast.success("Selected policies skip successfully");
      yield put({ type: actionTypes.SKIP_NEXT_POLICY_MULTIPLE_SUCCESS });
    } else {
      yield put({ type: actionTypes.SKIP_NEXT_POLICY_MULTIPLE_FAILURE });
      toast.error(`Couldn't skip policies.`);
    }
    return data.success ? "success" : "error";
  } catch (error) {
    yield put({ type: actionTypes.SKIP_NEXT_POLICY_MULTIPLE_FAILURE });
    toast.error(`Couldn't skip policies.`);
    return "error";
  }
}

export const getRunNowProgress = (state) => {
  return state.Policy.runNowProgress;
};

function* skipNextPolicySaga({ params }) {
  const { dispatch, singleRun, ...rest } = params;
  const policies = rest?.policies;
  try {
    const setLoading = policies.map(({ id, value }) => {
      return { id: id, status: "loading" };
    });
    yield put({
      type: actionTypes.SET_SKIP_POLICY_PROGRESS,
      payload: setLoading,
    });

    if (!singleRun) {
      const promises = policies.map((policy) => call(skipPolicyById, policy));
      const result = yield all([...promises]);
      const hasError = result.some((res) => res !== "success");
      if (!hasError) {
        yield put({
          type: actionTypes.SET_SKIP_POLICY_SUCCESS,
          payload: result,
        });
        toast.success(
          "All selected policies were skipped for the next run succesfully!"
        );
        setTimeout(() => {
          dispatch({ type: actionTypes.SET_SKIP_POLICY_PROGRESS, payload: [] });
        }, 2000);
      } else {
        yield put({
          type: actionTypes.SET_SKIP_POLICY_FAILURE,
          errorMessage: "Error while performing bulk skip policy",
        });
      }
    } else {
      const result = yield call(skipPolicyById, policies[0]);
      if (result === "success") {
        yield put({
          type: actionTypes.SET_SINGLE_SKIP_POLICY_SUCCESS,
          payload: policies[0],
        });
        toast.success("The next policy run was successfully skipped!");
      } else {
        yield put({
          type: actionTypes.SET_SINGLE_SKIP_POLICY_FAILURE,
          errorMessage: "Not able to skip the next policy run",
        });
        toast.error("Not able to skip the next policy run");
      }
    }
  } catch (error) {
    yield put({
      type: actionTypes.SET_SKIP_POLICY_FAILURE,
      errorMessage: "Not able to skip the next policy run",
    });
  }
}

function* filtersSaga({ payload }) {
  try {
    yield put({
      type: actionTypes.POLICY_FILTERS_ACTION_SUCCESS,
      payload: payload,
    });
    // yield put({
    //   type: actionTypes.GET_POLICY,   // Created seperate action for this from frontend.
    // });
  } catch (err) {
    console.log("err", err);
  }
}

function* skipPolicyById({ id, value }) {
  let skipPolicyProgressArr = yield select(getSkipPolicyProgress);
  const index = skipPolicyProgressArr.findIndex((p) => p.id === id);
  try {
    const params = { policy_id: id };
    const { data } = yield API.put("/api/policies/skip-next", params);
    if (data.success) {
      skipPolicyProgressArr[index].status = "success";
      yield put({
        type: actionTypes.SET_SKIP_POLICY_PROGRESS,
        payload: skipPolicyProgressArr,
      });
      toast.success("Policy skip successfully");
    } else {
      skipPolicyProgressArr[index].status = "error";
      yield put({
        type: actionTypes.SET_SKIP_POLICY_PROGRESS,
        payload: skipPolicyProgressArr,
      });
      toast.error(`Policy ${value} failed to execute`);
    }
    return data.success ? "success" : "error";
  } catch (error) {
    skipPolicyProgressArr[index].status = "error";
    yield put({
      type: actionTypes.SET_SKIP_POLICY_PROGRESS,
      payload: skipPolicyProgressArr,
    });
    toast.error(`Policy ${value} failed to execute`);
    return "error";
  }
}

export const getSkipPolicyProgress = (state) => {
  return state.Policy.skipPolicyProgress;
};

function* getAllPoliciesTitlesSaga() {
  try {
    yield put({ type: actionTypes.SET_POLICY_LOADING });
    const { data } = yield API.get("/api/policies/get-all-policy-title");

    if (data.success) {
      yield put({
        type: actionTypes.GET_ALL_POLICIES_TITLES_SUCCESS,
        payload: data?.data,
      });
    } else {
      toast.error("Unable Get The Policies Titles");
      yield put({
        type: actionTypes.GET_ALL_POLICIES_TITLES_FAILURE,
        errorMessage: "Unable Get The Policies Titles",
      });
    }
  } catch (error) {
    toast.error("Unable Get The Policies Titles");
    yield put({
      type: actionTypes.GET_ALL_POLICIES_TITLES_FAILURE,
      errorMessage: "Unable Get The Policies Titles",
    });
  }
}

function* PolicySaga() {
  yield all([
    takeLatest(actionTypes.GET_POLICY, GetPolicySaga),
    takeLatest(actionTypes.GET_POLICY_BY_ID, GetPolicyByIdSaga),
    takeLatest(actionTypes.CREATE_POLICY, createPolicySaga),
    takeLatest(actionTypes.UPDATE_POLICY, updatePolicySaga),
    takeLatest(actionTypes.DELETE_POLICY, DeletePolicyByIdSaga),
    takeLatest(actionTypes.RUN_NOW_POLICY, runNowPolicySaga),
    // takeLatest(actionTypes.SINGLE_RUN_NOW_POLICY, runNowPolicySingle),
    takeLatest(actionTypes.SKIP_NEXT_POLICY, skipNextPolicySaga),
    takeLatest(
      actionTypes.SKIP_NEXT_POLICY_MULTIPLE,
      skipNextPolicyMultipleSaga
    ),
    takeLatest(actionTypes.POLICY_FILTERS_ACTION, filtersSaga),
    takeLatest(actionTypes.RUN_NOW_POLICY_MULTIPLE, runNowPolicyMultiple),
    takeLatest(actionTypes.PAUSE_RESUME_POLICIES, pauseResumePoliciesSaga),
    takeLatest(actionTypes.BULK_DELETE_POLICIES, bulkDeletePoliciesSaga),
    takeLatest(actionTypes.GET_ALL_POLICIES_TITLES, getAllPoliciesTitlesSaga)
  ]);
}

export default PolicySaga;
