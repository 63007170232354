/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import PageHeader from "../../components/UI/PageWrapper/PageHeader";
import PolicyReportFilterCard from "./PolicyReportFilter";
import { useDispatch, useSelector } from "react-redux";
import { getPolicyReportFiltersAction } from "../../store/PolicyReport/actions";
import Table from "./components/Table";
import Collapse from "@mui/material/Collapse";
import { useSearchParams } from "react-router-dom";
import { HasPermission } from "../../utils/HasPermission";
import NotPermitted from "../../components/UI/NotPermitted"

const ViewLink = ({ showLess, setShowLess }) => {
  return (
    <a
      style={{ color: "blue", cursor: "pointer" }}
      onClick={() => setShowLess(!showLess)}
    >
      {" "}
      &nbsp;View {showLess ? "More" : "Less"}{" "}
    </a>
  );
};

const Locations = ({ locations, cutoff = 3 }) => {
  const [showLess, setShowLess] = useState(true);
  const style = {
    marginLeft: "10px",
  };

  if (locations?.length <= cutoff) {
    return locations?.map((location) => (
      <div style={style}>{location.canonicalName}</div>
    ));
  } else {
    return (
      <div style={style}>
        <Collapse in={!showLess} collapsedSize={60}>
          {locations?.map((location, index) => (
            <div>
              {location.canonicalName}
              {showLess && index === cutoff - 1 && (
                <ViewLink showLess={showLess} setShowLess={setShowLess} />
              )}
              {!showLess && index === locations?.length - 1 && (
                <ViewLink showLess={showLess} setShowLess={setShowLess} />
              )}
            </div>
          ))}
        </Collapse>
      </div>
    );
  }
};

function getTodayAndBefore7Days() {
  const currentDate = new Date();

  const sevenDaysAgo = new Date(currentDate);
  sevenDaysAgo.setDate(currentDate.getDate() - 7);

  const todayDateString = currentDate.toISOString().split("T")[0];
  const sevenDaysAgoDateString = sevenDaysAgo.toISOString().split("T")[0];

  return {
    today: todayDateString,
    sevenDaysAgo: sevenDaysAgoDateString,
  };
}

const dates = getTodayAndBefore7Days();

const filtersInitialState = {
  policiesName: [],
  locations: [],
  device: [],
  networks: [],
  status: "",
  startDate: dates.sevenDaysAgo,
  endDate: dates.today,
};

function PolicyReports() {
  const dispatch = useDispatch();
  let { PolicyReportsList, loading } = useSelector(
    (state) => state.PolicyReport
  );

  const [rows, setRows] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState({
    currentPage:
      PolicyReportsList.currentPage ||
      parseInt(searchParams.get("currentPage")),
    perPage: PolicyReportsList.perPage || parseInt(searchParams.get("perPage")),
  });
  let [filters, setFilters] = useState(filtersInitialState);

  useEffect(() => {
    dispatch(getPolicyReportFiltersAction());
  }, []);

  useEffect(() => {
    let updatedData = PolicyReportsList?.report?.map(
      (
        { _id, policy_data, status, createdAt, updatedAt, UniqueNetworks },
        key
      ) => {
        return {
          id: _id,
          keyword: policy_data.search_keyword?.map((item) => (
            <div style={{ marginLeft: "10px" }}>{item}</div>
          )),
          "Policy name": policy_data.title,
          device: policy_data.device_type?.map((item) => (
            <div style={{ marginLeft: "30px" }}>{item}</div>
          )),
          location: <Locations locations={policy_data.location} cutoff={3} />,
          last_run: updatedAt,
          next_run: policy_data.scheduled_time,
          next_run_skiped: policy_data.next_run_skiped,
          start_time: createdAt,
          end_time: updatedAt,
          status: status,
          networks: UniqueNetworks.filter(
            (item) => item.is_archived == false
          ).map((item) => item),
          // networks: UniqueNetworks.map(item => item.name),
          // "networks": policy_data.networks?.split(",").map(item => (<div style={{ marginLeft: '10px' }}>{item} <IconButton size='small' > <OpenInNewIcon  fontSize='small' style={{color:variables.primaryButtonColor}} /></IconButton> </div>)),
        };
      }
    );
    setRows(updatedData);
  }, [PolicyReportsList]);

  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      currentPage: PolicyReportsList.currentPage,
      perPage: PolicyReportsList.perPage,
    }));
  }, [PolicyReportsList]);

  useEffect(() => {
    setSearchParams((params) => {
      params.set("currentPage", query.currentPage);
      params.set("perPage", query.perPage);
      params.set("policiesName", filters?.policiesName);
      params.set("startDate", filters?.startDate);
      params.set("endDate", filters?.endDate);
      params.set("locations", filters?.locations);
      params.set("device", filters?.device);
      params.set("networks", filters?.networks);
      params.set("status", filters?.status);

      return params;
    });
  }, [query]);

  if (!HasPermission("crawler_policy_reports_view")) {
    return <NotPermitted />;
  }

  return (
    <div>
      <PageWrapper>
        <PageHeader header="Reports" />
        <PolicyReportFilterCard
          filters={filters}
          setFilters={setFilters}
          filtersInitialState={filtersInitialState}
          pagination={PolicyReportsList}
          query={query}
        >
          {rows && (
            <Table
              rows={rows}
              pagination={PolicyReportsList}
              filters={filters}
              loading={loading}
            />
          )}
        </PolicyReportFilterCard>
      </PageWrapper>
    </div>
  );
}

export default PolicyReports;
