import * as actionTypes from "./actionTypes";

export const createBlockedDomainAction = (params) => ({
  type: actionTypes.CREATE_BLOCKED_DOMAIN,
  params,
});
export const updateBlockedDomainAction = (params) => ({
  type: actionTypes.UPDATE_BLOCKED_DOMAIN,
  params,
});

export const getBlockedDomainAction = (params) => ({
  type: actionTypes.GET_BLOCKED_DOMAIN,
  params,
});

export const deleteBlockedDomainAction = (params) => ({
  type: actionTypes.DELETE_BLOCKED_DOMAIN,
  params,
});
