import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  message: "",
  loading: false,
  UsersList: [],
  selectedRole: {},
  permissions: [],
  rolesAndPermission: {},
  frontendIds: [],
};

const Users = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USERS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.CREATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.UPDATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        UsersList: action.payload,
      };
    case actionTypes.GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.VERIFY_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        rolesAndPermission: action.payload,
        permissions: action.payload.permission,
        frontendIds: action.payload.frontendIds,
      };
    case actionTypes.VERIFY_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.VERIFY_TEMP_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.VERIFY_TEMP_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_USERS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRole: action.payload,
      };
    case actionTypes.GET_USERS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.DELETE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
};

export default Users;
