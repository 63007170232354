import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@mui/material";

import { Logout } from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PolicyIcon from "@mui/icons-material/Policy";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CellTowerIcon from "@mui/icons-material/CellTower";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import GoogleIcon from "@mui/icons-material/Google";
import Logo from "../../../Assets/logo.png";
import AvatarIcon from "../../../Assets/avatar.png";
import variables from "../../../Assets/Styles/variables";
import "./PageWrapper.scss";
import { useDispatch, useSelector } from "react-redux";
import { doLogoutAction } from "../../../store/Account/actions";
import LinearProgress from "@mui/material/LinearProgress";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";

function PageWrapper(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [permissionData, setPermissionData] = useState([]);

  const { permissions, rolesAndPermission } = useSelector(
    (state) => state.Users
  );
  const { websiteLoading } = useSelector((state) => state.Dashboard);
  const [showTopLoader, setShowTopLoader] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [progress, setProgress] = useState(0);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(doLogoutAction());
    navigate("/login");
  };

  const hasViewPermission = (moduleName) => {
    if (permissionData) {
      const module = permissionData?.find(
        (perm) => perm.moduleName === moduleName
      );
      return module && module.actions.includes(`${moduleName} view`);
    } else {
      return false;
    }
  };

  useEffect(() => {
    // Initialize timeout IDs
    let loadingTimeout, completionTimeout;

    if (websiteLoading) {
      // When the website is loading, show progress up to a certain point
      setShowTopLoader(true);
      loadingTimeout = setTimeout(() => {
        setProgress(25); // Set your desired progress value
      }, 100); // Minimum duration for loading progress
      setTimeout(() => {
        setProgress(50); // Adjust this value and time as needed
      }, 200);
    } else {
      // When the website loading is completed, finish the progress and hide the loader
      completionTimeout = setTimeout(() => {
        setProgress(100); // Set progress to 100% when loading is completed
      }, 500); // You can add a small delay if needed
      setTimeout(() => {
        setShowTopLoader(false); // Hide the loader after completion
      }, 1500); // Adjust the time as needed
    }

    // Return a cleanup function to clear the timeouts
    return () => {
      clearTimeout(loadingTimeout);
      clearTimeout(completionTimeout);
    };
  }, [websiteLoading]);

  useEffect(() => {
    if (permissions) {
      setPermissionData(permissions);
    }
  }, [permissions]);

  return (
    <div className="page_wrapper">
      {showTopLoader && (
        <div
          style={{ position: "fixed", top: "0", width: "100vw", zIndex: 999 }}
        >
          <LinearProgress
            sx={{
              backgroundColor: "#fff",
              height: "4px",
              "& .MuiLinearProgress-bar1Determinate": {
                backgroundColor: "#F59E0B",
              },
            }}
            variant="determinate"
            value={progress}
          />
        </div>
      )}
      <Grid container>
        <Grid item xs={2.5} md={2.5} className="sidebar_container">
          <div className="fixed">
            <div className="sidebar">
              <Grid
                container
                style={{
                  marginBottom: "20px",
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <div className="logoWrapper">
                  <img src={Logo} alt="ADB" />
                </div>
                <div className="logoWrapper">
                  <h4>CRAWLER</h4>
                </div>
              </Grid>
              <div className="divider" />
              <div />
              <List>
                {hasViewPermission("Dashboard") && (
                  <ListItemButton
                    sx={{
                      "&:hover": {
                        backgroundColor: variables.primaryDarkColor,
                        color: "white",
                        borderRadius: "5px",
                      },
                    }}
                    component={NavLink}
                    to="/"
                    activeclassname="Mui-test"
                    selected={location.pathname === "/"}
                  >
                    <ListItemIcon>
                      <DashboardIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                )}
                {hasViewPermission("Policies") && (
                  <ListItemButton
                    sx={{
                      "&:hover": {
                        backgroundColor: variables.primaryDarkColor,
                        color: "white",
                        borderRadius: "5px",
                      },
                    }}
                    component={NavLink}
                    to="/policies"
                    selected={location.pathname === "/policies"}
                  >
                    <ListItemIcon>
                      <PolicyIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Policies" />
                  </ListItemButton>
                )}
                {hasViewPermission("Policy Reports") && (
                  <ListItemButton
                    component={NavLink}
                    to="/policies-reports"
                    sx={{
                      "&:hover": {
                        backgroundColor: variables.primaryDarkColor,
                        color: "white",
                        borderRadius: "5px",
                      },
                    }}
                    selected={location.pathname === "/policies-reports"}
                  >
                    <ListItemIcon>
                      <AssignmentIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Policy Reports" />
                  </ListItemButton>
                )}

                {hasViewPermission("Ad View") && (
                  <ListItemButton
                    component={NavLink}
                    to="/ad-view"
                    sx={{
                      "&:hover": {
                        backgroundColor: variables.primaryDarkColor,
                        color: "white",
                        borderRadius: "5px",
                      },
                    }}
                    selected={location.pathname === "/ad-view"}
                  >
                    <ListItemIcon>
                      <GoogleIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Ad View" />
                  </ListItemButton>
                )}
                {/* {hasViewPermission("Users") && (
                  <ListItemButton
                    sx={{
                      "&:hover": {
                        backgroundColor: variables.primaryDarkColor,
                        color: "white",
                        borderRadius: "5px",
                      },
                    }}
                    component={NavLink}
                    to="/users"
                    selected={location.pathname === "/users"}
                  >
                    <ListItemIcon>
                      <PeopleIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItemButton>
                )} */}
                {/* {hasViewPermission("Roles") && (
                  <ListItemButton
                    sx={{
                      "&:hover": {
                        backgroundColor: variables.primaryDarkColor,
                        color: "white",
                        borderRadius: "5px",
                      },
                    }}
                    component={NavLink}
                    to="/roles"
                    selected={location.pathname === "/roles"}
                  >
                    <ListItemIcon>
                      <ManageAccountsIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Roles" />
                  </ListItemButton>
                )} */}
                {hasViewPermission("Networks") && (
                  <ListItemButton
                    sx={{
                      "&:hover": {
                        backgroundColor: variables.primaryDarkColor,
                        color: "white",
                        borderRadius: "5px",
                      },
                    }}
                    component={NavLink}
                    to="/networks"
                    selected={location.pathname === "/networks"}
                  >
                    <ListItemIcon>
                      <CellTowerIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Networks" />
                  </ListItemButton>
                )}
                {hasViewPermission("Audit") && (
                  <ListItemButton
                    sx={{
                      "&:hover": {
                        backgroundColor: variables.primaryDarkColor,
                        color: "white",
                        borderRadius: "5px",
                      },
                    }}
                    component={NavLink}
                    to="/audits"
                    selected={location.pathname === "/audits"}
                  >
                    <ListItemIcon>
                      <ManageSearchIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Audit" />
                  </ListItemButton>
                )}

                {hasViewPermission("Blocked Domain") && (
                  <ListItemButton
                    sx={{
                      "&:hover": {
                        backgroundColor: variables.primaryDarkColor,
                        color: "white",
                        borderRadius: "5px",
                      },
                    }}
                    component={NavLink}
                    to="/blocked-domain"
                    selected={location.pathname === "/blocked-domain"}
                  >
                    <ListItemIcon>
                      <BlockOutlinedIcon
                        fontSize="medium"
                        style={{ color: "#ffffff" }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Blocked Domain" />
                  </ListItemButton>
                )}
              </List>
            </div>
          </div>
        </Grid>
        <Grid item xs={9} md={9} className="sidebar_container_flex">
          <div className="navbar">
            <Toolbar
              sx={{
                pr: "24px",
                pl: "30px",
              }}
            >
              <Grid container justifyContent="space-between">
                <div></div>
                <Grid>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title="Account settings">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 1 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        label="label"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <Button
                          sx={{ color: "black" }}
                          endIcon={
                            <Avatar
                              alt="avatar"
                              sx={{ width: 32, height: 32 }}
                              src={AvatarIcon}
                            />
                          }
                        >
                          {" "}
                          {rolesAndPermission?.user_name}
                        </Button>
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Toolbar>
          </div>
          {props.children}
        </Grid>
      </Grid>
    </div>
  );
}

export default PageWrapper;
