import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../utils/API";
import axios from "axios";

function* getLocationsSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_UTILITIES_LOADING });
    // const { data } = yield axios.get(
    //   `https://us-central1-dhappa-j.cloudfunctions.net/searchGeoTargets?text=${
    //     params.search
    //   }${params.filter ? "&filters=" + params.filter : ""}`
    // );
    const url = "https://retag-data.aldebaranmedia.com/geo-targets/";
    const api = params.type === 'random' ? `random?country_code=${params.code}&number=${params.number}` : `search?keyword=${params.search}&targets=${params.filter}`;
    const { data } = yield axios.get(url+api);
    
    // const { data } =  yield API.get("/api/location",{params:params})

    if (data.status === 1) {
      params.type === 'random' ?
      yield put({
        type: actionTypes.GET_RANDOM_LOCATIONS_SUCCESS,
        payload: data.data,
      }) : yield put({
        type: actionTypes.GET_SELECT_LOCATIONS_SUCCESS,
        payload: data.data,
      })
    } else {
      params.type === 'random' ?
      yield put({
        type: actionTypes.GET_RANDOM_LOCATIONS_FAILURE,
        errorMessage: "Unable to load Locations.",
      }) : yield put({
        type: actionTypes.GET_SELECT_LOCATIONS_FAILURE,
        errorMessage: "Unable to load Locations.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Locations.");
    params.type === 'random' ?
    yield put({
      type: actionTypes.GET_RANDOM_LOCATIONS_FAILURE,
      errorMessage: "Unable to load Locations.",
    }) : yield put({
      type: actionTypes.GET_SELECT_LOCATIONS_FAILURE,
      errorMessage: "Unable to load Locations.",
    });
  }
}

function* getCountriesSaga() {
  try {
    const { data } = yield axios.get('https://restcountries.com/v3.1/all?fields=name,cca2');
    // console.log('data', data)
    
    if (data[0].name) {
      const countries = data.map((country) => {
        return `${country.cca2} (${country.name.common})`
      })
      yield put({
        type: actionTypes.GET_COUNTRIES_SUCCESS,
        payload: countries,
      });
    } else {
      yield put({
        type: actionTypes.GET_COUNTRIES_FAILURE,
        errorMessage: "Unable to load countries.",
      });
    }
  } catch (error) {
    toast.error("Unable to load countries.");
    yield put({
      type: actionTypes.GET_COUNTRIES_FAILURE,
      errorMessage: "Unable to load countries.",
    });
  }
}


function* UtilitiesSaga() {
  yield all([takeLatest(actionTypes.GET_LOCATIONS, getLocationsSaga)]);
  yield all([takeLatest(actionTypes.GET_COUNTRIES, getCountriesSaga)]);
}

export default UtilitiesSaga;
