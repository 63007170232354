import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  SvgIcon,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Skeleton from "react-loading-skeleton";
import NoDataMessage from "../NoDataMessage/NoDataMessage";

function createData(name, device, status, id) {
  return { name, device, status, id };
}
const sx = { height: "100%" };

let rows = [];

export default function BasicTable({ data, loading }) {
  const navigate = useNavigate();
  if (data.length) {
    rows = data.map((obj) =>
      createData(
        obj.policy_id.title,
        obj.policy_id.device_type.join(", "),
        obj.status,
        obj._id
      )
    );
  }
  if (!rows.length && loading) {
    rows = [{}, {}, {}, {}, {}, {}, {}];
  }
  return (
    <Card sx={sx}>
      <CardHeader title="Recent Policy Reports" />
      <CardContent>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="left">Device</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/reports/${row.id}`)}
                  >
                    {row.name ? row.name : <Skeleton />}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/reports/${row.id}`)}
                  >
                    {row.device ? row.device : <Skeleton />}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ maxWidth: "200px" }}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/reports/${row.id}`)}
                  >
                    <Alert
                      severity={
                        row.status === "completed" ? "success" : "warning"
                      }
                    >
                      {row.status ? row.status : <Skeleton />}
                    </Alert>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {rows.length < 1 && (
            <NoDataMessage message="Data not available for selected options." />
          )}
        </TableContainer>
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          onClick={() => navigate("/policies-reports")}
          color="inherit"
          endIcon={
            <SvgIcon fontSize="small">
              <ArrowForwardIcon />
            </SvgIcon>
          }
          size="small"
        >
          View all
        </Button>
      </CardActions>
    </Card>
  );
}
