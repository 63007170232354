export const GET_NETWORKS = "GET_NETWORKS";
export const GET_NETWORKS_SUCCESS = "GET_NETWORKS_SUCCESS";
export const GET_NETWORKS_FAILURE = "GET_NETWORKS_FAILURE";

export const GET_NETWORK_BY_ID = "GET_NETWORK_BY_ID";
export const GET_NETWORK_BY_ID_SUCCESS = "GET_NETWORK_BY_ID_SUCCESS";
export const GET_NETWORK_BY_ID_FAILURE = "GET_NETWORK_BY_ID_FAILURE";

export const CREATE_NETWORK = "CREATE_NETWORK";
export const CREATE_NETWORK_SUCCESS = "CREATE_NETWORK_SUCCESS";
export const CREATE_NETWORK_FAILURE = "CREATE_NETWORK_FAILURE";

export const UPDATE_NETWORK = "UPDATE_NETWORK";
export const UPDATE_NETWORK_SUCCESS = "UPDATE_NETWORK_SUCCESS";
export const UPDATE_NETWORK_FAILURE = "UPDATE_NETWORK_FAILURE";

export const ARCHIVE_NETWORK = "ARCHIVE_NETWORK";
export const ARCHIVE_NETWORK_SUCCESS = "ARCHIVE_NETWORK_SUCCESS";
export const ARCHIVE_NETWORK_FAILURE = "ARCHIVE_NETWORK_FAILURE";

export const UNARCHIVE_NETWORK = "UNARCHIVE_NETWORK";
export const UNARCHIVE_NETWORK_SUCCESS = "UNARCHIVE_NETWORK_SUCCESS";
export const UNARCHIVE_NETWORK_FAILURE = "UNARCHIVE_NETWORK_FAILURE";

export const SET_NETWORKS_LOADING = "SET_NETWORKS_LOADING";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";

export const CHANGE_SELECTED_NETWORKS = "CHANGE_SELECTED_NETWORKS";

export const GROUP_ARCHIVE_NETWORK = "GROUP_ARCHIVE_NETWORK";
export const GROUP_ARCHIVE_NETWORK_SUCCESS = "GROUP_ARCHIVE_NETWORK_SUCCESS";
export const GROUP_ARCHIVE_NETWORK_FAILURE = "GROUP_ARCHIVE_NETWORK_FAILURE";

export const BULK_IMPORT_NETWORKS = "BULK_IMPORT_NETWORKS";
export const BULK_IMPORT_NETWORKS_SUCCESS = "BULK_IMPORT_NETWORKS_SUCCESS";
export const BULK_IMPORT_NETWORKS_FAILURE = "BULK_IMPORT_NETWORKS_FAILURE";

export const BULK_EXPORT_NETWORKS = "BULK_EXPORT_NETWORKS";
export const BULK_EXPORT_NETWORKS_SUCCESS = "BULK_EXPORT_NETWORKS_SUCCESS";
export const BULK_EXPORT_NETWORKS_FAILURE = "BULK_EXPORT_NETWORKS_FAILURE";

export const NETWORKS_FILTERS_ACTION = "NETWORKS_FILTERS_ACTION";
export const NETWORKS_FILTERS_ACTION_SUCCESS = "NETWORKS_FILTERS_ACTION_SUCCESS"