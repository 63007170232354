import { Grid } from "@mui/material";
import React from "react";

function PageHeader({ header, headerButton }) {
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "20px" }}
      >
        <Grid item>
          <h2 style={{ fontSize: "40px", opacity: ".4" }}>{header}</h2>
        </Grid>
        <Grid item>{headerButton}</Grid>
      </Grid>
    </>
  );
}

export default PageHeader;
