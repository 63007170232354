import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import LoginPage from "./pages/Auth/Login";
import PlatformLogin from "./pages/Auth/PlatformLogin";
import ResetPasswordPage from "./pages/Auth/ResetPassword";
import ForgotPasswordPage from "./pages/Auth/ForgotPassword";
import PoliciesPage from "./pages/Policies/Policies";
import CreatePoliciesPage from "./pages/Policies/CreatePolicies/CreatePolicies";
import PolicyReportsPage from "./pages/PolicieReports/PolicyReports";
import OnboardingPage from "./pages/Onboarding/Onboarding";
import TestPage from "./pages/Test/test";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { setTokenFromLocalstorage } from "./store/Account/actions";
import { verifyUsersAction } from "./store/Users/actions";
import PolicyReportDetailView from "./pages/PolicyReports/PolicyReportDetailView";
import PolicyReportTree from "./pages/PolicieReports/PolicyReportTree";
import RolesPage from "./pages/Roles/Roles";
import CreateRolesPage from "./pages/Roles/Create";
import UsersPage from "./pages/Users/Users";
import CreateUserPage from "./pages/Users/CreateUser";
import EditUser from "./pages/Users/EditUser";
import NetworksPage from "./pages/Networks/Networks";
import CreateNetworkPage from "./pages/Networks/CreateNetwork";
import AuditsPage from "./pages/Audits/Audits";
import SettingsPage from "./pages/Settings/Settings";
import AuditDetailViewPage from "./pages/Audits/AuditDetailView";
import NotPermitted from "./components/UI/NotPermitted";
import { useLocation } from "react-router-dom";
import dashboardView from "./pages/Dashboard/Dashboard";
import ProfilePage from "./pages/Profile/Profile";
import AdView from "./pages/AdView/AdView";
import LoaderWithLayout from "./components/UI/LoaderWithLayout";

const authProtectedRoutes = [
  {
    path: "/",
    component: dashboardView,
    module: "Dashboard",
    type: "view",
  },
  {
    path: "/blocked-domain",
    component: SettingsPage,
    module: "Blocked Domain",
    type: "view",
  },
  {
    path: "/profile",
    component: ProfilePage,
    type: "view",
  },
  {
    path: "/policies",
    component: PoliciesPage,
    module: "Policies",
    type: "view",
  },
  {
    path: "/policies/view/:id",
    component: CreatePoliciesPage,
    module: "policies",
    type: "read",
  },
  {
    path: "/policies/create",
    component: CreatePoliciesPage,
    module: "policies",
    type: "create",
  },
  {
    path: "/policies/edit/:id",
    component: CreatePoliciesPage,
    module: "policies",
    type: "update",
  },
  {
    path: "/policies/clone/:id",
    component: CreatePoliciesPage,
    module: "policies",
    type: "create",
  },
  {
    path: "/policies-reports",
    component: PolicyReportsPage,
    module: "Policy Reports",
    type: "view",
  },
  {
    path: "ad-view",
    component: AdView,
    module: "Ad View",
    type: "view",
  },
  {
    path: "/new",
    component: PolicyReportsPage,
    module: "policy reports",
    type: "create",
  },
  {
    path: "/reports/:id",
    component: PolicyReportDetailView,
    module: "policy reports",
    type: "view",
  },
  {
    path: "/reports/treeview/:id",
    component: PolicyReportTree,
    module: "policy reports",
    type: "view",
  },
  // {
  //   path: "/roles",
  //   component: RolesPage,
  //   module: "Roles",
  //   type: "view",
  // },
  // {
  //   path: "/role/create",
  //   component: CreateRolesPage,
  //   module: "roles",
  //   type: "create",
  // },
  // {
  //   path: "/role/edit/:id",
  //   component: CreateRolesPage,
  //   module: "roles",
  //   type: "update",
  // },
  // {
  //   path: "/role/view/:id",
  //   component: CreateRolesPage,
  //   module: "roles",
  //   type: "view",
  // },
  {
    path: "/test",
    component: TestPage,
  },
  // {
  //   path: "/users",
  //   component: UsersPage,
  //   module: "Users",
  //   type: "view",
  // },
  // {
  //   path: "/user/create",
  //   component: CreateUserPage,
  //   module: "users",
  //   type: "create",
  // },
  // {
  //   path: "/user/view/:id",
  //   component: EditUser,
  //   module: "users",
  //   type: "view",
  // },
  // {
  //   path: "/user/edit/:id",
  //   component: EditUser,
  //   module: "users",
  //   type: "update",
  // },
  {
    path: "/networks",
    component: NetworksPage,
    module: "Networks",
    type: "view",
  },
  {
    path: "/networks/create",
    component: CreateNetworkPage,
    module: "networks",
    type: "create",
  },
  {
    path: "/networks/edit/:id",
    component: CreateNetworkPage,
    module: "networks",
    type: "update",
  },
  {
    path: "/audits",
    component: AuditsPage,
    module: "Audit",
    type: "view",
  },
  {
    path: "/audit/view/:id",
    component: AuditDetailViewPage,
    module: "audit",
    type: "view",
  },
  {
    path: "/tt/:token",
    component: PlatformLogin,
  },
];

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();

  const { isAuthenticated } = useSelector((state) => state.Account);
  const { permissions, loading, frontendIds } = useSelector(
    (state) => state.Users
  );

  const [lastLocation, setLastLocation] = useState("");
  const [lastLocationBeforeUnmount, setLastLocationBeforeUnmount] =
    useState("/policies");

  const isPermitted = useCallback(
    (module, type) => {
      if (type == "view") {
      } else {
        if (!module) {
          return true; // for login page etc.
        }
        if (!Object.keys(permissions).includes(module)) {
          return false;
        }
        if (type === "create" && !permissions[module].includes("create")) {
          return false;
        }
        if (type === "update" && !permissions[module].includes("update")) {
          return false;
        }
        return true;
      }
    },
    [permissions]
  );

  const privateRoutes = useMemo(
    () =>
      authProtectedRoutes.map(
        ({ component: Component, path, module, type }, key) => {
          if (isAuthenticated || localStorage.getItem("token")) {
            return (
              <Route
                path={path}
                key={key}
                exact={true}
                element={
                  isPermitted(module, type) ? (
                    <Component
                      permissions={permissions[module]}
                      lastRouteInHistory={lastLocation}
                      lastLocationBeforeUnmount={lastLocationBeforeUnmount}
                    />
                  ) : loading ? (
                    <LoaderWithLayout />
                  ) : (
                    <Component
                      permissions={permissions[module]}
                      lastRouteInHistory={lastLocation}
                      lastLocationBeforeUnmount={lastLocationBeforeUnmount}
                    />
                  )
                }
              />
            );
          } else {
            return (
              <Route
                path={path}
                key={key}
                element={<Navigate to={{ pathname: "/login" }} />}
              />
            );
          }
        }
      ),
    [isAuthenticated, permissions, isPermitted]
  );

  useEffect(() => {
    setLastLocationBeforeUnmount(location.pathname);
    return () => setLastLocation(location.pathname);
  }, [location]);

  useEffect(() => {
    if (!isAuthenticated) {
      let token = localStorage.getItem("token");
      if (token) {
        dispatch(setTokenFromLocalstorage(token));
      }
    }
  }, [dispatch, isAuthenticated, navigate]);
  useEffect(() => {
    const cpToken = localStorage.getItem("cpToken");
    dispatch(verifyUsersAction(cpToken));
  }, [dispatch, location]);

  return (
    <>
      <ToastContainer enableMultiContainer />
      <ToastContainer
        enableMultiContainer
        containerId={"bottomToast"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={false}
      />

      {localStorage.getItem("token") ? (
        <Routes>
          {privateRoutes}

          <Route
            path="*"
            element={
              <Navigate to={{ pathname: generateRoute(lastLocation) }} />
            }
          />
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/login"
            element={<LoginPage lastRouteInHistory={lastLocation} />}
          />
          <Route path="/tt/:token" element={<PlatformLogin />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route
            path="/reset-password/:token"
            element={<ResetPasswordPage />}
          />
          <Route path="/onboarding" element={<OnboardingPage />} />
          <Route path="*" element={<Navigate to={{ pathname: "/login" }} />} />
        </Routes>
      )}
    </>
  );
}

const generateRoute = (lastLocation) => {
  function matchPathWithVariables(path, routes) {
    for (const route of routes) {
      const pattern = route.replace(/:[^/]+/g, "([^/]+)");
      const regex = new RegExp(`^${pattern}$`);

      if (regex.test(path)) {
        return true;
      }
    }

    return false;
  }
  if (
    matchPathWithVariables(
      lastLocation,
      authProtectedRoutes.map((obj) => obj.path)
    )
  ) {
    return lastLocation;
  }
  return "/policies";
};

export default App;
