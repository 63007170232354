import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import PageHeader from "../../components/UI/PageWrapper/PageHeader";
import ReportDetails from "../../components/PolicyReports/ReportDetails";
import { useParams } from "react-router-dom";
// import PolicyReportRowTab from "./PolicyReportRowTab";
import { useDispatch, useSelector } from "react-redux";
import { getPolicyByIdAction } from "../../store/PolicyReport/actions";
import HeaderWithBackButton from "../../components/UI/HeaderWithBackButton";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import { parseISO } from "date-fns";

function PolicyReportDetailView() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { selectedPolicy } = useSelector((state) => state.PolicyReport);
  const [formatedDate, setFormatedDate] = useState("");

  useEffect(() => {
    dispatch(
      getPolicyByIdAction({
        Id: id,
      })
    );
  }, [dispatch, id]);
  useEffect(() => {
    if(selectedPolicy?.policy?.start_date_time){
      const dateToFormat = selectedPolicy?.policy?.start_date_time;
      const parsedDate = parseISO(dateToFormat);
      setFormatedDate(format(parsedDate, "dd MMM yyyy"));
    }
  }, [selectedPolicy]);
  return (
    <>
      <Helmet>
        <title>
          {`${selectedPolicy?.policy?.title || ""} | ${formatedDate || ""}`}
        </title>
      </Helmet>
      <PageWrapper>
        <PageHeader header={<HeaderWithBackButton header="Reports" />} />
        <ReportDetails Individual_Policy_Report={selectedPolicy} />
      </PageWrapper>
    </>
  );
}

export default PolicyReportDetailView;
