import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../utils/API";

function* GetAuditsSaga({ params }) {
  try {
    const policyNameParam = params?.policyName;

    let decodedPolicyName;

    if (!Array.isArray(policyNameParam)) {
      decodedPolicyName = decodeURIComponent(
        policyNameParam?.replace(/\+/g, " ")
      );
    } else {
      decodedPolicyName = "";
    }
    yield put({ type: actionTypes.SET_AUDITS_LOADING });
    const { data } = yield API.get("/api/audit", {
      params: {
        ...params,
        policyName: decodedPolicyName,
        actionBy:params?.actionBy[0] || ""
      },
    });

    if (data.success) {
      yield put({
        type: actionTypes.GET_AUDITS_SUCCESS,
        payload: data,
      });
    } else {
      toast.error("Unable To Get The Audits List");
      yield put({
        type: actionTypes.GET_AUDITS_FAILURE,
        errorMessage: "Unable To Get The Audits List",
      });
    }
  } catch (error) {
    toast.error("Unable Get The Audits List");
    yield put({
      type: actionTypes.GET_AUDITS_FAILURE,
      errorMessage: "Unable To Get The Audits List",
    });
  }
}

function* GetAuditByIdSaga({ Id }) {
  try {
    yield put({ type: actionTypes.SET_AUDITS_LOADING });
    const { data } = yield API.get(`/api/audit/${Id}/get-by-id`);

    if (data.success) {
      yield put({
        type: actionTypes.GET_AUDIT_BY_ID_SUCCESS,
        payload: data.data,
      });
      toast.success("Successfully Get Audit Details");
    } else {
      toast.error("Unable To Get Audit Details");
      yield put({
        type: actionTypes.GET_AUDIT_BY_ID_FAILURE,
        errorMessage: "Unable To Get Audit Details",
      });
    }
  } catch (error) {
    toast.error("Unable To Get Audit Details");
    yield put({
      type: actionTypes.GET_AUDIT_BY_ID_FAILURE,
      errorMessage: "Unable To Get Audit Details",
    });
  }
}

function* restoreDataByIdSaga({ id }) {
  try {
    yield put({ type: actionTypes.SET_AUDITS_LOADING });
    const { data } = yield API.put(`/api/audit/${id}/restore`);

    if (data.success) {
      yield put({
        type: actionTypes.RESTORE_DATA_BY_ID_SUCCESS,
        payload: data.data,
      });
      toast.success("Restored successfully");
      yield put({
        type: actionTypes.GET_AUDITS,
        params: {
          currentPage: 1,
          perPage: 10,
        },
      });
    } else {
      toast.error("Unable to Restore");
      yield put({
        type: actionTypes.RESTORE_DATA_BY_ID_FAILURE,
        errorMessage: "Unable to Restore",
      });
    }
  } catch (error) {
    toast.error("Unable to Restore");
    yield put({
      type: actionTypes.RESTORE_DATA_BY_ID_FAILURE,
      errorMessage: "Unable to Restore",
    });
  }
}

function* AuditsSaga() {
  yield all([
    takeLatest(actionTypes.GET_AUDITS, GetAuditsSaga),
    takeLatest(actionTypes.GET_AUDIT_BY_ID, GetAuditByIdSaga),
    takeLatest(actionTypes.RESTORE_DATA_BY_ID, restoreDataByIdSaga),
  ]);
}

export default AuditsSaga;
