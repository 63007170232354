import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../utils/API";

function* createNetworkSaga({ params }) {
  const { navigate, ...rest } = params;
  try {
    yield put({ type: actionTypes.SET_NETWORKS_LOADING });
    const { data } = yield API.post("/api/network", rest);

    if (data.success) {
      yield put({
        type: actionTypes.CREATE_NETWORK_SUCCESS,
        payload: data,
      });
      toast.success("Successfully Created The Network ");
      navigate("/networks");
    } else {
      toast.error("Unable to Create The Network");
      yield put({
        type: actionTypes.CREATE_NETWORK_FAILURE,
        errorMessage: "Unable to Create The Network",
      });
    }
  } catch (error) {
    toast.error("Unable to Create The Network");
    yield put({
      type: actionTypes.CREATE_NETWORK_FAILURE,
      errorMessage: "Unable to Create The Network",
    });
  }
}

function* updateNetworkSaga({ params }) {
  const { id, navigate, ...rest } = params;
  try {
    yield put({ type: actionTypes.SET_NETWORKS_LOADING });
    const { data } = yield API.put(`/api/network/${id}/update`, rest);

    if (data.success) {
      yield put({
        type: actionTypes.UPDATE_NETWORK_SUCCESS,
        payload: data,
      });
      toast.success("Successfully Updated The Network ");
      if (navigate) {
        navigate("/networks");
      }
    } else {
      toast.error("Unable to Update The Network");
      yield put({
        type: actionTypes.UPDATE_NETWORK_FAILURE,
        errorMessage: "Unable to Update The Network",
      });
    }
  } catch (error) {
    toast.error("Unable to Update The Network");
    yield put({
      type: actionTypes.UPDATE_NETWORK_FAILURE,
      errorMessage: "Unable Update The Networks",
    });
  }
}

function* GetNetworksSaga({ params }) {

  try {
    yield put({ type: actionTypes.SET_NETWORKS_LOADING });
    const { data } = yield API.get("/api/network", { params: params });

    if (data.success) {
      yield put({
        type: actionTypes.GET_NETWORKS_SUCCESS,
        payload: data,
      });
    } else {
      toast.error("Unable Get The Networks");
      yield put({
        type: actionTypes.GET_NETWORKS_FAILURE,
        errorMessage: "Unable Get The Networks",
      });
    }
  } catch (error) {
    toast.error("Unable Get The Networks");
    yield put({
      type: actionTypes.GET_NETWORKS_FAILURE,
      errorMessage: "Unable Get The Network",
    });
  }
}

function* GetNetworkByIdSaga({ Id }) {
  try {
    yield put({ type: actionTypes.SET_NETWORKS_LOADING });
    const { data } = yield API.get(`/api/network/${Id}`);

    if (data.success) {
      yield put({
        type: actionTypes.GET_NETWORK_BY_ID_SUCCESS,
        payload: data.data,
      });
      toast.success("Successfully Get The Network ");
    } else {
      toast.error("Unable Get The Network");
      yield put({
        type: actionTypes.GET_NETWORK_BY_ID_FAILURE,
        errorMessage: "Unable Get The Network",
      });
    }
  } catch (error) {
    toast.error("Unable Get The Network");
    yield put({
      type: actionTypes.GET_NETWORK_BY_ID_FAILURE,
      errorMessage: "Unable Get The Network",
    });
  }
}

function* ArchiveNetworkByIdSaga({ Id }) {
  try {
    const params = { ids: [Id], action: "archive" };
    yield put({ type: actionTypes.SET_NETWORKS_LOADING });
    const { data } = yield API.post(`/api/network/group-archive`, params);

    if (data.success) {
      yield put({
        type: actionTypes.ARCHIVE_NETWORK_SUCCESS,
        payload: data.data,
      });
      toast.success("Successfully Archived The Network");
    } else {
      toast.error("Unable to Archive The Network");
      yield put({
        type: actionTypes.ARCHIVE_NETWORK_FAILURE,
        errorMessage: "Unable to Archive The Network",
      });
    }
  } catch (error) {
    toast.error("Unable to Archive The Network");
    yield put({
      type: actionTypes.ARCHIVE_NETWORK_FAILURE,
      errorMessage: "Unable to Archive The Network",
    });
  }
}

function* UnarchiveNetworkByIdSaga({ Id }) {
  try {
    const params = { ids: [Id], action: "unarchive" };
    yield put({ type: actionTypes.SET_NETWORKS_LOADING });
    const { data } = yield API.post(`/api/network/group-archive`, params);

    if (data.success) {
      yield put({
        type: actionTypes.UNARCHIVE_NETWORK_SUCCESS,
        payload: data.data,
      });
      toast.success("Successfully Unarchived The Network");
    } else {
      toast.error("Unable to Unarchive The Network");
      yield put({
        type: actionTypes.UNARCHIVE_NETWORK_FAILURE,
        errorMessage: "Unable to Unarchive The Network",
      });
    }
  } catch (error) {
    toast.error("Unable to Unarchive The Network");
    yield put({
      type: actionTypes.UNARCHIVE_NETWORK_FAILURE,
      errorMessage: "Unable to Unarchive The Network",
    });
  }
}

function* GroupArchiveNetworkSaga({ params }) {
  const count = params?.ids ? params?.ids?.length : 0;
  try {
    yield put({ type: actionTypes.SET_NETWORKS_LOADING });
    const { data } = yield API.post(`/api/network/group-archive`, params);

    if (data.success) {
      yield put({
        type: actionTypes.GROUP_ARCHIVE_NETWORK_SUCCESS,
        payload: params?.action === "archive" ? ["0"] : ["1"],
      });
      toast.success(
        `Successfully Archived ${count} Network${count > 1 ? "s" : ""}.`
      );
    } else {
      toast.error(`Unable to Archive ${count} Network${count > 1 ? "s" : ""}.`);
      yield put({
        type: actionTypes.GROUP_ARCHIVE_NETWORK_FAILURE,
        errorMessage: `Unable to Archive ${count} Network${
          count > 1 ? "s" : ""
        }.`,
      });
    }
  } catch (error) {
    toast.error(`Unable to Archive ${count} Network${count > 1 ? "s" : ""}.`);
    yield put({
      type: actionTypes.GROUP_ARCHIVE_NETWORK_FAILURE,
      errorMessage: `Unable to Archive ${count} Network${
        count > 1 ? "s" : ""
      }.`,
    });
  }
}

function* BulkImportNetworksSaga({ params }) {
  try {
    const { dispatch, formData } = params;
    const { data } = yield API.post(
      `/api/network/network-bulk-import`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (data) => {
          // Set the progress value to show the progress bar
          var progress = Math.round((100 * data.loaded) / data.total);
          if (progress <= 100)
            dispatch({
              type: actionTypes.SET_UPLOAD_PROGRESS,
              payload: progress,
            });
        },
      }
    );

    if (data.success) {
      yield put({
        type: actionTypes.BULK_IMPORT_NETWORKS_SUCCESS,
        payload: data,
      });
      setTimeout(() => {
        dispatch({ type: actionTypes.SET_UPLOAD_PROGRESS, payload: 0 });
      }, 1000);
      toast.success(`Successfully Imported The Networks`);
    } else {
      toast.error(`Unable to Import The Networks`);
      yield put({
        type: actionTypes.BULK_IMPORT_NETWORKS_FAILURE,
        errorMessage: `Unable to Import The Networks`,
      });
    }
  } catch (error) {
    toast.error(`Unable to Import The Networks`);
    yield put({
      type: actionTypes.GROUP_ARCHIVE_NETWORK_FAILURE,
      errorMessage: `Unable to Import The Networks`,
    });
  }
}

function* BulkExportNetworksSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_NETWORKS_LOADING });
    const { data } = yield API.post(
      `/api/network/network-bulk-export`,
      payload
    );

    if (data.success) {
      yield put({
        type: actionTypes.BULK_EXPORT_NETWORKS_SUCCESS,
        payload: {
          ...data,
          link: `${API.defaults.baseURL.slice(0, -1)}${data.data}`,
        },
      });
      toast.success(`Successfully Exported The Networks`);
    } else {
      toast.error(`Unable to Export The Networks`);
      yield put({
        type: actionTypes.BULK_EXPORT_NETWORKS_FAILURE,
        errorMessage: `Unable to Export The Networks`,
      });
    }
  } catch (error) {
    toast.error(`Unable to Import The Networks`);
    yield put({
      type: actionTypes.GROUP_ARCHIVE_NETWORK_FAILURE,
      errorMessage: `Unable to Import The Networks`,
    });
  }
}

function* networksFiltersSaga({ payload }) {
  try {
    yield put({
      type: actionTypes.NETWORKS_FILTERS_ACTION_SUCCESS,
      payload: payload,
    });
    // yield put({
    //   type: actionTypes.GET_NETWORKS,
    // });
  } catch (err) {
    console.log("err", err);
  }
}

function* NetworksSaga() {
  yield all([
    takeLatest(actionTypes.GET_NETWORKS, GetNetworksSaga),
    takeLatest(actionTypes.GET_NETWORK_BY_ID, GetNetworkByIdSaga),
    takeLatest(actionTypes.CREATE_NETWORK, createNetworkSaga),
    takeLatest(actionTypes.UPDATE_NETWORK, updateNetworkSaga),
    takeLatest(actionTypes.ARCHIVE_NETWORK, ArchiveNetworkByIdSaga),
    takeLatest(actionTypes.UNARCHIVE_NETWORK, UnarchiveNetworkByIdSaga),
    takeLatest(actionTypes.GROUP_ARCHIVE_NETWORK, GroupArchiveNetworkSaga),
    takeLatest(actionTypes.BULK_IMPORT_NETWORKS, BulkImportNetworksSaga),
    takeLatest(actionTypes.BULK_EXPORT_NETWORKS, BulkExportNetworksSaga),
    takeLatest(actionTypes.NETWORKS_FILTERS_ACTION, networksFiltersSaga),
  ]);
}

export default NetworksSaga;
