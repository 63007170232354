import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { visuallyHidden } from "@mui/utils";
import { Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import variables from "../../Assets/Styles/variables";
import { useNavigate } from "react-router-dom";
import {
  getNetworksAction,
  archiveNetworkByIdAction,
  unarchiveNetworkByIdAction,
  changeSelectedNetworks,
} from "../../store/Networks/actions";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NoDataMessage from "../NoDataMessage/NoDataMessage";
import { HasPermission } from "../../utils/HasPermission";

const MySwal = withReactContent(Swal);

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    permissions,
  } = props;

  const headCells = [
    {
      id: "name",
      disablePadding: true,
      label: "Network Name",
    },
    {
      id: "website",
      disablePadding: false,
      label: "Website",
    },
    {
      id: "notes",
      disablePadding: false,
      label: "Notes",
    },
  ];

  if (
    HasPermission("crawler_networks_create") ||
    HasPermission("crawler_networks_edit") ||
    HasPermission("crawler_networks_delete")
  ) {
    headCells.push({
      id: "actions",
      disablePadding: false,
      label: "Actions",
    });
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: variables.backgroundDark }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: "bold" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton></IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function NetworksListing({
  rows,
  pagination,
  permissions,
  filters,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [paginate, setPaginate] = useState({
    currentPage: pagination.currentPage - 1 || 0,
    perPage: pagination.perPage || 10,
    totalCount: pagination.totalCount || 0,
    totalPages: Math.ceil(pagination.totalCount / pagination.perPage) || 0,
  });

  const { selectedNetworks } = useSelector((state) => state.Networks);

  useEffect(() => {
    let temp = paginate;
    if (pagination.hasOwnProperty("currentPage")) {
      temp.currentPage = pagination.currentPage - 1;
    }
    if (pagination.hasOwnProperty("perPage")) {
      temp.perPage = pagination.perPage;
    }
    if (pagination.hasOwnProperty("totalCount")) {
      temp.totalCount = pagination.totalCount;
    }
    if (pagination.hasOwnProperty("totalPages")) {
      temp.totalPages = pagination.totalPages;
    }
    setPaginate({ ...temp });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const reloadCurrentPage = () => {
    let params = { ...filters };
    if (params.archived === "all") delete params.archived;
    else params.archived = JSON.parse(params.archived);
    console.log("from reloadCurrentPage");
    dispatch(
      getNetworksAction({
        ...params,
        currentPage: pagination.currentPage,
        perPage: pagination.perPage,
      })
    );
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    console.log("form handleChangePage");
    let params = { ...filters };
    if (params.archived === "all") delete params.archived;
    else params.archived = JSON.parse(params.archived);
    dispatch(
      getNetworksAction({
        ...params,
        currentPage: newPage + 1,
        perPage: paginate.perPage,
      })
    );
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("from handleChangeRowPerPage");
    let params = { ...filters };
    if (params.archived === "all") delete params.archived;
    else params.archived = JSON.parse(params.archived);
    dispatch(
      getNetworksAction({
        ...params,
        currentPage: 1,
        perPage: parseInt(event.target.value, 10),
      })
    );
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = 0;

  const archiveNetwork = (Id) => {
    MySwal.fire({
      title: "Are you sure you want to archive this network?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(archiveNetworkByIdAction(Id));
        if (filters.archived === "false") {
          setTimeout(() => {
            reloadCurrentPage();
          }, 100);
        } else {
          const index = rows.findIndex((row) => row._id === Id);
          rows[index].archived = true;
        }
      }
    });
  };

  const unArchiveNetwork = (Id) => {
    MySwal.fire({
      title: "Are you sure you want to activate this network?",
      // text: "You won't be able to Delete this Role!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, activate it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(unarchiveNetworkByIdAction(Id));
        if (filters.archived === "all") {
          const index = rows.findIndex((row) => row._id === Id);
          rows[index].archived = false;
        } else {
          setTimeout(() => {
            reloadCurrentPage();
          }, 100);
        }
      }
    });
  };

  useEffect(() => {
    dispatch(changeSelectedNetworks(selected));
  }, [selected]);

  useEffect(() => {
    // to catch bulk archive/unarchive succesfull event
    if (selectedNetworks.length > 0 && selectedNetworks[0] === "0") {
      // archived
      if (filters.archived === "false") {
        setTimeout(() => {
          reloadCurrentPage();
        }, 100);
      } else {
        selected.forEach((Id) => {
          const index = rows.findIndex((row) => row._id === Id);
          rows[index].archived = true;
        });
      }
      setSelected([]);
    }
    if (selectedNetworks.length > 0 && selectedNetworks[0] === "1") {
      // unarchived
      if (filters.archived === "true") {
        setTimeout(() => {
          reloadCurrentPage();
        }, 100);
      } else {
        selected.forEach((Id) => {
          const index = rows.findIndex((row) => row._id === Id);
          rows[index].archived = false;
        });
      }
      setSelected([]);
    }
  }, [selectedNetworks]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              permissions={permissions}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.network}
                    selected={isItemSelected}
                    style={{
                      backgroundColor: row.archived
                        ? variables.backgroundDeleted
                        : "",
                    }}
                  >
                    <TableCell
                      padding="checkbox"
                      style={{ verticalAlign: "top", paddingTop: "15px" }}
                      sx={{
                        color: row.archived ? variables.disabledTextColor : "",
                      }}
                    >
                      <Checkbox
                        onClick={(event) => handleClick(event, row._id)}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      style={{ padding: "10px" }}
                      sx={{
                        color: row.archived ? variables.disabledTextColor : "",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>{row.network}</span>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: row.archived ? variables.disabledTextColor : "",
                      }}
                    >
                      {row.website}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: row.archived ? variables.disabledTextColor : "",
                        maxWidth: "300px",
                      }}
                    >
                      {row.notes}
                    </TableCell>
                    {(HasPermission("crawler_networks_create") ||
                      HasPermission("crawler_networks_edit") ||
                      HasPermission("crawler_networks_delete") ||
                      true) && (
                      <TableCell
                        sx={{
                          color: row.archived
                            ? variables.disabledTextColor
                            : "",
                        }}
                      >
                        <Grid>
                          {HasPermission("crawler_networks_edit") ? (
                            <IconButton
                              color="primary"
                              component="label"
                              onClick={() =>
                                navigate(`/networks/edit/${row._id}`)
                              }
                            >
                              <EditIcon
                                style={{
                                  color: variables.secondaryButtonColor,
                                }}
                              />
                            </IconButton>
                          ) : (
                            <span></span>
                          )}
                          {!row.archived &&
                          HasPermission("crawler_networks_edit") ? (
                            <IconButton
                              color="primary"
                              component="label"
                              onClick={() => archiveNetwork(row._id)}
                            >
                              <ArchiveIcon style={{ color: "red" }} />
                            </IconButton>
                          ) : (
                            <span></span>
                          )}
                          {row.archived &&
                          HasPermission("crawler_networks_edit") ? (
                            <IconButton
                              color="primary"
                              component="label"
                              onClick={() => unArchiveNetwork(row._id)}
                            >
                              <UnarchiveIcon
                                style={{
                                  color: variables.secondaryButtonColor,
                                }}
                              />
                            </IconButton>
                          ) : (
                            <span></span>
                          )}
                        </Grid>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {!(rows.length > 0) && (
                <TableRow style={{ height: 53 }}>
                  <TableCell
                    colSpan={6}
                    scope="row"
                    padding="none"
                    sx={{ padding: "15px", color: variables.disabledTextColor }}
                    align="center"
                  >
                    No results found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {rows.length < 1 && (
            <NoDataMessage message="Data not available for selected options." />
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={paginate.totalCount}
          rowsPerPage={paginate.perPage}
          page={paginate.currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
