import { combineReducers } from "redux";

import Utilities from "./Utilities/reducer";
import Account from "./Account/reducer";
import Policy from "./Policy/reducer";
import PolicyReport from "./PolicyReport/reducer";
import Roles from "./Roles/reducer";
import Users from "./Users/reducer";
import Networks from "./Networks/reducer";
import Audits from "./Audits/reducer";
import Dashboard from "./Dashboard/reducer";
import BlockedDomain from "./Settings/reducer";

const rootReducer = combineReducers({
  Utilities,
  Account,
  Policy,
  PolicyReport,
  Roles,
  Users,
  Networks,
  Audits,
  Dashboard,
  BlockedDomain,
});

export default rootReducer;
