import { Grid, Paper, Typography } from "@mui/material";
import access from "../../Assets/images/access.svg";
import PageWrapper from "./PageWrapper/PageWrapper";

function ComingSoon() {
  return (
    <PageWrapper>
      <Grid style={{ marginTop: "20px" }}>
        <div>
          <Paper style={{ padding: "30px", borderRadius: "15px" }}>
            <Grid
              style={{ height: "550px" }}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <img
                  src={access}
                  alt="Loading"
                  style={{ display: "block", margin: "0 auto" }}
                  loop
                  height="50"
                  width="50"
                />
                <Typography
                  style={{
                    color: "#D64045",
                    fontWeight: "600",
                    marginTop: "5px",
                  }}
                  variant="h6"
                  align="center"
                >
                  Access Denied
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Grid>
    </PageWrapper>
  );
}

export default ComingSoon;
