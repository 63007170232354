import { Box, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import cronstrue from "cronstrue";
import { formatTimeWithSec } from "../../utils/timeUtils";

const getLocations = (locations) => {
  return locations.map((location) => location.canonicalName);
};

const formatCronSchedule = (str) => {
  return cronstrue.toString(str, { verbose: true }).toLocaleString();
};

const getAddress = (data) => {
  const result = [];
  const fields = ["address1", "address2", "city", "state", "country", "zip"];
  fields.map((field) => {
    if (data[field].trim() !== "") result.push(data[field]);
  });

  return result.join(", ");
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function PolicyData({ data }) {
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        {" "}
        <Typography>
          {" "}
          <span style={{ fontWeight: "bold", marginRight: "5px" }}>
            Title :
          </span>
          {data?.title}
        </Typography>{" "}
      </Grid>

      <Grid item sm={12}>
        {" "}
        <Typography>
          {" "}
          <span style={{ fontWeight: "bold", marginRight: "5px" }}>
            Keywords :
          </span>
          {data?.search_keyword?.join(", ")}
        </Typography>{" "}
      </Grid>

      <Grid item sm={12}>
        {" "}
        <Typography>
          {" "}
          <span style={{ fontWeight: "bold", marginRight: "5px" }}>
            Locations :
          </span>
          {data?.location?.length > 0
            ? getLocations(data?.location).join(", ")
            : ""}
        </Typography>{" "}
      </Grid>

      <Grid item sm={12}>
        {" "}
        <Typography>
          {" "}
          <span style={{ fontWeight: "bold", marginRight: "5px" }}>
            Devices :
          </span>
          {typeof data?.device_type === "object"
            ? data?.device_type?.join(", ")
            : data?.device_type}
        </Typography>{" "}
      </Grid>

      {data?.schedule_time && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Schedule Time :
            </span>
            {data?.schedule_time?.includes("*")
              ? formatCronSchedule(data?.schedule_time)
              : formatTimeWithSec(data?.schedule_time)}
          </Typography>{" "}
        </Grid>
      )}

      {data?.schedule_type && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Schedule Type :
            </span>
            {data?.schedule_type}
          </Typography>{" "}
        </Grid>
      )}

      {data?.schedule_frequency && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Schedule Frequency :
            </span>
            {data?.schedule_frequency}
          </Typography>{" "}
        </Grid>
      )}
    </Grid>
  );
}

function NetworkData({ data }) {
  return (
    <Grid container spacing={2}>
      {data?.name && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Company Name :
            </span>
            {data?.name}
          </Typography>{" "}
        </Grid>
      )}
      {data?.email && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Email :
            </span>
            {data?.email}
          </Typography>{" "}
        </Grid>
      )}
      {data?.contact_person && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Contact Person :
            </span>
            {data?.contact_person}
          </Typography>{" "}
        </Grid>
      )}
      {data?.skype && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Skype :
            </span>
            {data?.skype}
          </Typography>{" "}
        </Grid>
      )}
      {data?.domain && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Website :
            </span>
            {data?.domain}
          </Typography>{" "}
        </Grid>
      )}
      {(data?.address1 ||
        data?.address2 ||
        data?.city ||
        data?.state ||
        data?.country ||
        data?.zip) && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Address :
            </span>
            {getAddress(data)}
          </Typography>{" "}
        </Grid>
      )}
      {data?.is_archived && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Status :
            </span>
            {data?.is_archived ? "Archived" : "Unarchived"}
          </Typography>{" "}
        </Grid>
      )}
    </Grid>
  );
}

function RolesData({ data }) {
  return (
    <Grid container spacing={2}>
      {data?.title && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Name :
            </span>
            {data?.title}
          </Typography>{" "}
        </Grid>
      )}

      {data?.description && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Description :
            </span>
            {data?.description}
          </Typography>{" "}
        </Grid>
      )}

      {data?.permissions && (
        <Grid item sm={12}>
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Permissions :{" "}
            </span>
          </Typography>
          <Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                marginRight: "5px",
                fontSize: "0.9rem",
              }}
            >
              Dashboard:{" "}
            </span>
            {data?.permissions["dashboard"]?.join(", ")}
          </Typography>
          <Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                marginRight: "5px",
                fontSize: "0.9rem",
              }}
            >
              Networks:{" "}
            </span>
            {data?.permissions["networks"]?.join(", ")}
          </Typography>
          <Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                marginRight: "5px",
                fontSize: "0.9rem",
              }}
            >
              Policies:{" "}
            </span>
            {data?.permissions["policies"]?.join(", ")}
          </Typography>
          <Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                marginRight: "5px",
                fontSize: "0.9rem",
              }}
            >
              Policy Reports:{" "}
            </span>
            {data?.permissions["policy reports"]?.join(", ")}
          </Typography>
          <Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                marginRight: "5px",
                fontSize: "0.9rem",
              }}
            >
              Policies:{" "}
            </span>
            {data?.permissions["roles"]?.join(", ")}
          </Typography>
          <Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                marginRight: "5px",
                fontSize: "0.9rem",
              }}
            >
              Policies:{" "}
            </span>
            {data?.permissions["users"]?.join(", ")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

function UsersData({ data }) {
  return (
    <Grid container spacing={2}>
      {(data?.first_name || data?.last_name) && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Name :
            </span>
            {`${data?.first_name} ${data?.last_name}`}
          </Typography>{" "}
        </Grid>
      )}

      {data?.email && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Email :
            </span>
            {data?.email}
          </Typography>{" "}
        </Grid>
      )}

      {data?.phone && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Phone :
            </span>
            {data?.phone}
          </Typography>{" "}
        </Grid>
      )}

      {data?.type && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Type :
            </span>
            {data?.type}
          </Typography>{" "}
        </Grid>
      )}

      {data?.is_active && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Status :
            </span>
            {data?.is_active ? "Active" : "Inactive"}
          </Typography>{" "}
        </Grid>
      )}
    </Grid>
  );
}

function OrganizationsData({ data }) {
  return (
    <Grid container spacing={2}>
      {data?.business_name && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Business Name :
            </span>
            {data?.business_name}
          </Typography>{" "}
        </Grid>
      )}

      {data?.name && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Name :
            </span>
            {data?.name}
          </Typography>{" "}
        </Grid>
      )}

      {data?.email && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              Email :
            </span>
            {data?.email}
          </Typography>{" "}
        </Grid>
      )}

      {data?.phone && (
        <Grid item sm={12}>
          {" "}
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
              phone :
            </span>
            {data?.phone}
          </Typography>{" "}
        </Grid>
      )}
    </Grid>
  );
}

function AuditDetailsRowTab({ audit }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        {audit?.module_name === "policies" && (
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <TabPanel value={value} index={0}>
                <Paper
                  elevation={3}
                  style={{ padding: "30px", borderRadius: "20px" }}
                >
                  <Grid item sm={12} md={12} lg={12}>
                    {" "}
                    <Typography>
                      {" "}
                      <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        Previous Data{" "}
                      </h3>
                    </Typography>{" "}
                  </Grid>
                  {audit?.previous_data ? (
                    <PolicyData data={audit?.previous_data} />
                  ) : (
                    "No data Found"
                  )}
                </Paper>
              </TabPanel>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <TabPanel value={value} index={0}>
                <Paper
                  elevation={3}
                  style={{ padding: "30px", borderRadius: "20px" }}
                >
                  <Grid item sm={12} md={12} lg={12}>
                    {" "}
                    <Typography>
                      {" "}
                      <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        Updated Data{" "}
                      </h3>
                    </Typography>{" "}
                  </Grid>
                  {audit?.updated_data ? (
                    <PolicyData data={audit?.updated_data} />
                  ) : (
                    "No data Found"
                  )}
                </Paper>
              </TabPanel>
            </Grid>
          </Grid>
        )}

        {audit?.module_name === "network" && (
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <TabPanel value={value} index={0}>
                <Paper
                  elevation={3}
                  style={{ padding: "30px", borderRadius: "20px" }}
                >
                  <Grid item sm={12} md={12} lg={12}>
                    {" "}
                    <Typography>
                      {" "}
                      <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        Previous Data{" "}
                      </h3>
                    </Typography>{" "}
                  </Grid>
                  {audit?.previous_data ? (
                    <NetworkData data={audit?.previous_data} />
                  ) : (
                    "--"
                  )}
                </Paper>
              </TabPanel>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <TabPanel value={value} index={0}>
                <Paper
                  elevation={3}
                  style={{ padding: "30px", borderRadius: "20px" }}
                >
                  <Grid item sm={12} md={12} lg={12}>
                    {" "}
                    <Typography>
                      {" "}
                      <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        Updated Data{" "}
                      </h3>
                    </Typography>{" "}
                  </Grid>
                  {audit?.updated_data ? (
                    <NetworkData data={audit?.updated_data} />
                  ) : (
                    "--"
                  )}
                </Paper>
              </TabPanel>
            </Grid>
          </Grid>
        )}

        {audit?.module_name === "roles" && (
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <TabPanel value={value} index={0}>
                <Paper
                  elevation={3}
                  style={{ padding: "30px", borderRadius: "20px" }}
                >
                  <Grid item sm={12} md={12} lg={12}>
                    {" "}
                    <Typography>
                      {" "}
                      <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        Previous Data{" "}
                      </h3>
                    </Typography>{" "}
                  </Grid>
                  {audit?.previous_data ? (
                    <RolesData data={audit?.previous_data} />
                  ) : (
                    "--"
                  )}
                </Paper>
              </TabPanel>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <TabPanel value={value} index={0}>
                <Paper
                  elevation={3}
                  style={{ padding: "30px", borderRadius: "20px" }}
                >
                  <Grid item sm={12} md={12} lg={12}>
                    {" "}
                    <Typography>
                      {" "}
                      <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        Updated Data{" "}
                      </h3>
                    </Typography>{" "}
                  </Grid>
                  {audit?.updated_data ? (
                    <RolesData data={audit?.updated_data} />
                  ) : (
                    "--"
                  )}
                </Paper>
              </TabPanel>
            </Grid>
          </Grid>
        )}

        {audit?.module_name === "users" && (
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <TabPanel value={value} index={0}>
                <Paper
                  elevation={3}
                  style={{ padding: "30px", borderRadius: "20px" }}
                >
                  <Grid item sm={12} md={12} lg={12}>
                    {" "}
                    <Typography>
                      {" "}
                      <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        Previous Data{" "}
                      </h3>
                    </Typography>{" "}
                  </Grid>
                  {audit?.previous_data ? (
                    <UsersData data={audit?.previous_data} />
                  ) : (
                    "--"
                  )}
                </Paper>
              </TabPanel>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <TabPanel value={value} index={0}>
                <Paper
                  elevation={3}
                  style={{ padding: "30px", borderRadius: "20px" }}
                >
                  <Grid item sm={12} md={12} lg={12}>
                    {" "}
                    <Typography>
                      {" "}
                      <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        Updated Data{" "}
                      </h3>
                    </Typography>{" "}
                  </Grid>
                  {audit?.updated_data ? (
                    <UsersData data={audit?.updated_data} />
                  ) : (
                    "--"
                  )}
                </Paper>
              </TabPanel>
            </Grid>
          </Grid>
        )}

        {audit?.module_name === "organizations" && (
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <TabPanel value={value} index={0}>
                <Paper
                  elevation={3}
                  style={{ padding: "30px", borderRadius: "20px" }}
                >
                  <Grid item sm={12} md={12} lg={12}>
                    {" "}
                    <Typography>
                      {" "}
                      <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        Previous Data{" "}
                      </h3>
                    </Typography>{" "}
                  </Grid>
                  {audit?.previous_data ? (
                    <OrganizationsData data={audit?.previous_data} />
                  ) : (
                    "--"
                  )}
                </Paper>
              </TabPanel>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <TabPanel value={value} index={0}>
                <Paper
                  elevation={3}
                  style={{ padding: "30px", borderRadius: "20px" }}
                >
                  <Grid item sm={12} md={12} lg={12}>
                    {" "}
                    <Typography>
                      {" "}
                      <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        Updated Data{" "}
                      </h3>
                    </Typography>{" "}
                  </Grid>
                  {audit?.updated_data ? (
                    <OrganizationsData data={audit?.updated_data} />
                  ) : (
                    "--"
                  )}
                </Paper>
              </TabPanel>
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  );
}

export default AuditDetailsRowTab;
