import {
  Autocomplete,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import variables from "../../Assets/Styles/variables";
import { v4 as uuidv4 } from "uuid";
import {
  DangerButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/UI/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  getPolicyAction,
  runNowPolicy,
  skipNextPolicyRun,
  policyFiltersAction,
  runNowPolicyMultiple,
  pauseResumePolicies,
  bulkDeletePoliciesAction,
} from "../../store/Policy/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useLocation, useNavigate } from "react-router-dom";
import { getPolicyReportFiltersAction } from "../../store/PolicyReport/actions";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomAutoCheckbox from "../../components/UI/CustomAutoCheckbox/CustomAutoCheckbox";

const MySwal = withReactContent(Swal);
const initialSuggestions = {
  policyName: [],
  keywords: [],
  locations: [],
};

function PoliciesFilterCard({
  permissions,
  setFilters,
  filters,
  filtersInitialState,
  isAllSelected,
  setIsAllSelected,
  lastRouteInHistory,
  lastLocationBeforeUnmount,
  pagination,
  query,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { filterOptions } = useSelector((state) => state.PolicyReport);
  const { selectedPolicies, runNowProgress, skipPolicyProgress } = useSelector(
    (state) => state.Policy
  );

  const [disableGroupRunNow, setDisableGroupRunNow] = useState(true);
  const [disableGroupSkipPolicy, setDisableGroupSkipPolicy] = useState(true);
  const [suggestions, setSuggestions] = useState(initialSuggestions);

  const [paginate, setPaginate] = useState({
    currentPage: pagination.currentPage - 1 || 0,
    perPage: pagination.perPage || 10,
    totalCount: pagination.totalCount || 0,
    totalPages: Math.ceil(pagination.totalCount / pagination.perPage) || 0,
  });

  const a = 10;

  const handleBulkDelete = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const policyIds = selectedPolicies.map((obj) => obj.id);
        dispatch(bulkDeletePoliciesAction({ policyIds, navigate }));
        setIsAllSelected(false);
      }
    });
  };

  const handleFilterInput = ({ name, value }) => {
    dispatch(policyFiltersAction({ ...filters, [name]: value }));
    setFilters((data) => ({ ...data, [name]: value }));
  };

  const filterPolicies = () => {
    dispatch(
      getPolicyAction({
        title: Array.isArray(filters.policiesName)
          ? filters.policiesName.map((itm) => itm.title).join(",")
          : filters.policiesName,
        keyword: Array.isArray(filters.search_keyword)
          ? filters.search_keyword.map((itm) => itm.title).join(",")
          : filters.search_keyword,
        location: Array.isArray(filters.locations)
          ? filters.locations.map((itm) => itm.title).join("|")
          : filters.locations,
        device_type: Array.isArray(filters.device)
          ? filters.device.map((itm) => itm.title).join(",")
          : filters.device,
        run_paused: Array.isArray(filters.status)
          ? filters.status.join("|")
          : filters.status,
        currentPage: query.currentPage,
        perPage: query.perPage,
      })
    );
  };

  const clearFilters = () => {
    dispatch(policyFiltersAction(filtersInitialState));
    setFilters(filtersInitialState);
    dispatch(getPolicyAction());
  };

  const getFilters = () => ({
    title: filters.policiesName,
    keyword: filters.search_keyword,
    location: filters.locations,
    device_type: filters.device,
    run_paused: Array.isArray(filters.status)
      ? filters.status.join("|")
      : filters.status,
  });

  const handleChangePage = (event, newPage) => {
    let params = getFilters();
    dispatch(
      getPolicyAction({
        ...params,
        currentPage: newPage + 1,
        perPage: paginate.perPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    let params = getFilters();
    dispatch(
      getPolicyAction({
        ...params,
        currentPage: 1,
        perPage: parseInt(event.target.value, 10),
      })
    );
  };

  const runSelectedPolicies = () => {
    MySwal.fire({
      title: "Are you sure you want to manually run the selected policies?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Run them!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          runNowPolicyMultiple({
            params: {
              title: filters.policiesName,
              keyword: filters.search_keyword,
              location: filters.locations,
              device_type: filters.device,
            },
            body: {
              // policy_id: selectedPolicies.map((obj) => obj.id),
              policy_id: selectedPolicies.map((obj) => obj.id).join(", "),
              type: isAllSelected ? "all" : "",
            },
          })
        );
      }
    });
  };

  const handleSelectionChange = (result, label) => {
    const filterKeys = {
      "Policy Name": "policiesName",
      Keywords: "search_keyword",
      Locations: "locations",
      Device: "device",
    };
    const key = filterKeys[label];
    if (Array.isArray(result)) {
      if (key) {
        dispatch(policyFiltersAction({ ...filters, [key]: result }));
      }
    }
  };

  const playSelectedPolicies = ({ type }) => {
    MySwal.fire({
      title: `Are you sure you want to ${type} the selected policies?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${type} them!`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          pauseResumePolicies({
            params: {
              title: filters.policiesName,
              keyword: filters.search_keyword,
              location: filters.locations,
              device_type: filters.device,
            },
            body: {
              policy_ids: [...selectedPolicies.map((obj) => obj.id)],
              action: isAllSelected ? "all" : "",
              type,
            },
            getParams: {
              title: Array.isArray(filters.policiesName)
                ? filters.policiesName.map((itm) => itm.title).join(",")
                : filters.policiesName,
              keyword: Array.isArray(filters.search_keyword)
                ? filters.search_keyword.map((itm) => itm.title).join(",")
                : filters.search_keyword,
              location: Array.isArray(filters.locations)
                ? filters.locations.map((itm) => itm.title).join("|")
                : filters.locations,
              device_type: Array.isArray(filters.device)
                ? filters.device.map((itm) => itm.title).join(",")
                : filters.device,
              run_paused: Array.isArray(filters.status)
                ? filters.status.join("|")
                : filters.status,
              currentPage: query.currentPage,
              perPage: query.perPage,
            },
          })
        );
      }
    });
  };

  const skipSelectedPolicies = () => {
    MySwal.fire({
      title:
        "Are you sure you want to skip the next scheduled run for the selected policies?",
      // text: "This Policy will not run for this time, then will continue",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Skip them!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          skipNextPolicyRun({
            body: {
              policy_ids: selectedPolicies.map((obj) => obj.id),
              type: isAllSelected ? "all" : "",
            },
            params: { ...filters },
          })
        );
      }
    });
  };

  const getUniqueSuggestions = (filterArray, titleKey) => {
    return filterArray?.reduce((uniqueNames, element) => {
      const title = titleKey === "title" ? element : element[titleKey];
      const existingName = uniqueNames?.find((item) => item?.title === title);

      if (!existingName) {
        uniqueNames?.push({
          id: uniqueNames?.length + 1,
          title: title,
        });
      }

      return uniqueNames;
    }, []);
  };
  useEffect(() => {
    dispatch(
      getPolicyAction({
        title: Array.isArray(filters.policiesName)
          ? filters.policiesName.join(",")
          : filters.policiesName,
        keyword: Array.isArray(filters.search_keyword)
          ? filters.search_keyword.join(",")
          : filters.search_keyword,
        location: Array.isArray(filters.locations)
          ? filters.locations.join("|")
          : filters.locations,
        device_type: Array.isArray(filters.device)
          ? filters.device.join(",")
          : filters.device,
        run_paused: Array.isArray(filters.status)
          ? filters.status.join(",")
          : filters.status,
        currentPage: query.currentPage,
        perPage: query.perPage,
      })
    );
    dispatch(getPolicyReportFiltersAction());
  }, [dispatch]);

  useEffect(() => {
    if (!lastLocationBeforeUnmount.includes("/policies/view")) {
      clearFilters();
    }
  }, []);

  useEffect(() => {
    let temp = paginate;
    if (pagination.hasOwnProperty("currentPage")) {
      temp.currentPage = pagination.currentPage - 1;
    }
    if (pagination.hasOwnProperty("perPage")) {
      temp.perPage = pagination.perPage;
    }
    if (pagination.hasOwnProperty("totalCount")) {
      temp.totalCount = pagination.totalCount;
    }
    if (pagination.hasOwnProperty("totalPages")) {
      temp.totalPages = pagination.totalPages;
    }
    setPaginate({ ...temp });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useEffect(() => {
    if (selectedPolicies?.length > 0) {
      setDisableGroupRunNow(false);
      setDisableGroupSkipPolicy(false);
    } else {
      setDisableGroupRunNow(true);
      setDisableGroupSkipPolicy(true);
    }
  }, [selectedPolicies]);

  useEffect(() => {
    if (
      filters &&
      Object.keys(filters).some((key) =>
        Array.isArray(filters[key]) ? filters[key].length > 0 : filters[key]
      )
    ) {
      dispatch(
        getPolicyAction({
          title: Array.isArray(filters.policiesName)
            ? filters.policiesName.join(",")
            : filters.policiesName,
          keyword: Array.isArray(filters.search_keyword)
            ? filters.search_keyword.join(",")
            : filters.search_keyword,
          location: Array.isArray(filters.locations)
            ? filters.locations.join("|")
            : filters.locations,
          device_type: Array.isArray(filters.device)
            ? filters.device.join(",")
            : filters.device,
          run_paused: Array.isArray(filters.status)
            ? filters.status.join(",")
            : filters.status,
          currentPage: query.currentPage,
          perPage: query.perPage,
        })
      );
    }
  }, []);

  useEffect(() => {
    const uniquePolicyNames = getUniqueSuggestions(
      filterOptions?.titleFilter,
      "title"
    );
    const getLocationSuggestions = getUniqueSuggestions(
      filterOptions?.locationFilter,
      "canonicalName"
    );
    const getkeywordSuggestions = getUniqueSuggestions(
      filterOptions?.keywordFilter,
      "keyword"
    );

    setSuggestions({
      ...suggestions,
      policyName: uniquePolicyNames,
      keywords: getkeywordSuggestions,
      locations: getLocationSuggestions,
    });
  }, [filterOptions]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let searchQuery = {};

    const addToSearchQuery = (key) => {
      const values = searchParams.getAll(key);
      if (values.length > 0) {
        searchQuery[key] = values;
      }
    };

    addToSearchQuery("policiesName");
    addToSearchQuery("Keywords");
    addToSearchQuery("locations");
    addToSearchQuery("device");
    addToSearchQuery("status");

    const cleanedObj = Object.fromEntries(
      Object.entries(searchQuery).filter(
        ([_, value]) =>
          !(Array.isArray(value) && value.length === 1 && value[0] === "")
      )
    );
    dispatch(
      policyFiltersAction({
        policiesName: Array.isArray(cleanedObj?.policiesName)
          ? cleanedObj?.policiesName[0] || []
          : cleanedObj?.policiesName || [],
        search_keyword: Array.isArray(cleanedObj?.search_keyword)
          ? cleanedObj?.search_keyword[0] || []
          : cleanedObj?.search_keyword || [],
        locations: Array.isArray(cleanedObj?.locations)
          ? cleanedObj?.locations[0] || []
          : cleanedObj?.locations || [],
        device: Array.isArray(cleanedObj?.device)
          ? cleanedObj?.device[0] || []
          : cleanedObj?.device || [],
        status: Array.isArray(cleanedObj?.status)
          ? cleanedObj?.status[0] || []
          : cleanedObj?.status || [],
      })
    );
    dispatch(
      getPolicyAction({
        title: Array.isArray(cleanedObj?.policiesName)
          ? cleanedObj?.policiesName[0] || ""
          : cleanedObj?.policiesName || "",
        keyword: Array.isArray(cleanedObj?.search_keyword)
          ? cleanedObj?.search_keyword[0] || ""
          : cleanedObj?.search_keyword || "",
        location: Array.isArray(cleanedObj?.locations)
          ? cleanedObj?.locations[0] || ""
          : cleanedObj?.locations || "",
        device_type: Array.isArray(cleanedObj?.device)
          ? cleanedObj?.device[0] || ""
          : cleanedObj?.device || "",
        run_paused: cleanedObj?.status?.join(",") || "",
        currentPage: query.currentPage || "",
        perPage: query.perPage || "",
      })
    );
  }, []);
  const transformStringToArrayOfObjects = (str, key) => {
    if (str === "" || str.length === 0) {
      return [];
    } else if (str[0].title) {
      return str;
    } else {
      if (key === "locations") {
        return str?.split("|").map((item) => ({ title: item.trim() }));
      } else {
        return str?.split(",").map((item) => ({ title: item.trim() }));
      }
    }
  };

  return (
    <Card style={{ padding: "20px", borderRadius: "0px" }}>
      <Grid container spacing={2}>
        <Grid item xs={10} md={10} lg={10}>
          <p style={{ fontWeight: "bold", marginBottom: "10px" }}>Filters </p>
          <Grid container spacing={2}>
            <Grid item sm={2.2}>
              <CustomAutoCheckbox
                label="Policy Name"
                items={suggestions?.policyName || []}
                selectAllLabel="Select All"
                value={
                  transformStringToArrayOfObjects(
                    filters?.policiesName,
                    "policiesName"
                  ) || []
                }
                onChange={handleSelectionChange}
              />
            </Grid>
            <Grid item sm={2.2}>
              <CustomAutoCheckbox
                label="Keywords"
                items={suggestions?.keywords || []}
                selectAllLabel="Select All"
                value={
                  transformStringToArrayOfObjects(
                    filters?.search_keyword,
                    "search_keyword"
                  ) || []
                }
                onChange={handleSelectionChange}
              />
            </Grid>
            <Grid item sm={2.2}>
              <CustomAutoCheckbox
                label="Locations"
                items={suggestions?.locations || []}
                selectAllLabel="Select All"
                value={
                  transformStringToArrayOfObjects(
                    filters?.locations,
                    "locations"
                  ) || []
                }
                onChange={handleSelectionChange}
              />
            </Grid>
            <Grid item sm={2.2}>
              <CustomAutoCheckbox
                label="Device"
                items={[
                  { title: "Mobile" },
                  { title: "Tablet" },
                  { title: "Desktop" },
                ]}
                selectAllLabel="Select All"
                value={filters?.device || []}
                onChange={handleSelectionChange}
              />
            </Grid>

            <Grid item sm={2.2}>
              <Autocomplete
                id="status-select"
                size="small"
                options={["Paused", "Live", "All"]}
                fullWidth
                value={filters.status}
                onChange={(e, value) =>
                  handleFilterInput({
                    name: "status",
                    value: value !== null ? [value] : [],
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="filled"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "2px" }}>
            <Grid item sm={4}>
              <Button
                size="small"
                style={{
                  marginRight: "10px",
                  backgroundColor: variables.primaryColor,
                  color: variables.whiteText,
                }}
                onClick={clearFilters}
              >
                clear
              </Button>
              <SecondaryButton size="small" onClick={filterPolicies}>
                Apply
              </SecondaryButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={2}
          md={2}
          lg={2}
          style={{
            justifyContent: "end",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Grid
            item
            spacing={2}
            style={{
              justifyContent: "flex-end",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="Delete">
              {
                <DeleteIcon
                  onClick={() => {
                    if (!disableGroupRunNow) {
                      handleBulkDelete();
                    }
                  }}
                  style={{
                    ...playPauseStyles,
                    color: disableGroupRunNow ? "rgba(0, 0, 0, 0.26)" : "red",
                  }}
                />
              }
            </Tooltip>
            <Tooltip title="Pause">
              <PauseCircleIcon
                sx={{
                  ...playPauseStyles,
                  color: disableGroupRunNow ? "rgba(0, 0, 0, 0.26)" : "#3085d6",
                }}
                onClick={() => {
                  if (!disableGroupRunNow) {
                    playSelectedPolicies({ type: "pause" });
                  }
                }}
              />
            </Tooltip>
            <Tooltip title="Play">
              <PlayCircleIcon
                sx={{
                  ...playPauseStyles,
                  color: disableGroupRunNow ? "rgba(0, 0, 0, 0.26)" : "#3085d6",
                }}
                onClick={() => {
                  if (!disableGroupRunNow) {
                    playSelectedPolicies({ type: "play" });
                  }
                }}
              />
            </Tooltip>
            &nbsp;
            <DangerButton
              size="small"
              style={{
                marginRight: "10px",
                minWidth: "70px",
              }}
              disabled={disableGroupSkipPolicy}
              onClick={skipSelectedPolicies}
            >
              Skip
            </DangerButton>
            <PrimaryButton
              size="small"
              disabled={disableGroupRunNow}
              onClick={runSelectedPolicies}
              style={{
                minWidth: "100px",
              }}
            >
              &nbsp;Run Now
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ marginTop: "20px" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 300, 500]}
          component="div"
          count={paginate.totalCount}
          rowsPerPage={paginate.perPage}
          page={paginate.currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </Card>
  );
}

const playPauseStyles = {
  fontSize: "33px",
  cursor: "pointer",
  color: "#3085d6",
};

export default PoliciesFilterCard;
