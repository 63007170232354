export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

export const GET_ROLES_BY_ID = "GET_ROLES_BY_ID";
export const GET_ROLES_BY_ID_SUCCESS = "GET_ROLES_BY_ID_SUCCESS";
export const GET_ROLES_BY_ID_FAILURE = "GET_ROLES_BY_ID_FAILURE";

export const CREATE_ROLES = "CREATE_ROLES";
export const CREATE_ROLES_SUCCESS = "CREATE_ROLES_SUCCESS";
export const CREATE_ROLES_FAILURE = "CREATE_ROLES_FAILURE";

export const UPDATE_ROLES = "UPDATE_ROLES";
export const UPDATE_ROLES_SUCCESS = "UPDATE_ROLES_SUCCESS";
export const UPDATE_ROLES_FAILURE = "UPDATE_ROLES_FAILURE";

export const DELETE_ROLES = "DELETE_ROLES";
export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS";
export const DELETE_ROLES_FAILURE = "DELETE_ROLES_FAILURE";



export const SET_ROLES_LOADING = "SET_ROLES_LOADING";


