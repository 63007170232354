import { Tooltip } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

export default function HeaderWithBackButton({ header, fontSize }) {
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Tooltip title="Go back">
        <ArrowBackIosIcon
          onClick={() => navigate(-1)}
          sx={{
            fontSize: fontSize ? fontSize : "33px",
            cursor: "pointer",
            color: "black",
            opacity: "1",
          }}
        />
      </Tooltip>{" "}
      {header}
    </div>
  );
}
