export const GET_DASHBOARD_CARD_DATA = "GET_DASHBOARD_CARD_DATA";
export const GET_DASHBOARD_CARD_DATA_SUCCESS =
  "GET_DASHBOARD_CARD_DATA_SUCCESS";
export const GET_DASHBOARD_CARD_DATA_FAILURE =
  "GET_DASHBOARD_CARD_DATA_FAILURE";

export const GET_DASHBOARD_CHART_DATA = "GET_DASHBOARD_CHART_DATA";
export const GET_DASHBOARD_CHART_DATA_SUCCESS =
  "GET_DASHBOARD_CHART_DATA_SUCCESS";
export const GET_DASHBOARD_CHART_DATA_FAILURE =
  "GET_DASHBOARD_CHART_DATA_FAILURE";

export const GET_DASHBOARD_PIE_CHART_DATA = "GET_DASHBOARD_PIE_CHART_DATA";
export const GET_DASHBOARD_PIE_CHART_DATA_SUCCESS =
  "GET_DASHBOARD_PIE_CHART_DATA_SUCCESS";
export const GET_DASHBOARD_PIE_CHART_DATA_FAILURE =
  "GET_DASHBOARD_PIE_CHART_DATA_FAILURE";

export const GET_DASHBOARD_NETWORKS_CHART_DATA =
  "GET_DASHBOARD_NETWORKS_CHART_DATA";
export const GET_DASHBOARD_NETWORKS_CHART_DATA_SUCCESS =
  "GET_DASHBOARD_NETWORKS_CHART_DATA_SUCCESS";
export const GET_DASHBOARD_NETWORKS_CHART_DATA_FAILURE =
  "GET_DASHBOARD_NETWORKS_CHART_DATA_FAILURE";

export const GET_DASHBOARD_POLICIES_CHART_DATA =
  "GET_DASHBOARD_POLICIES_CHART_DATA";
export const GET_DASHBOARD_POLICIES_CHART_DATA_SUCCESS =
  "GET_DASHBOARD_POLICIES_CHART_DATA_SUCCESS";
export const GET_DASHBOARD_POLICIES_CHART_DATA_FAILURE =
  "GET_DASHBOARD_POLICIES_CHART_DATA_FAILURE";

export const SET_DASHBOARD_LOADING = "SET_AUDITS_LOADING";
export const SET_WEBSITE_LOADING = "SET_WEBSITE_LOADING";
export const SET_WEBSITE_LOADING_SUCCESS = "SET_WEBSITE_LOADING_SUCCESS";
