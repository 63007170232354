import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import { PrimaryButton } from "../UI/Buttons/Buttons";
import variables from "../../Assets/Styles/variables";
import "./Onboarding.scss";
import { getLocationAction } from "../../store/Utilities/actions";
import { useDispatch, useSelector } from "react-redux";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, 1000);
  });
}

const topFilms = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
];

function OnboardingPolicyLocation({ handleInput, handleNext }) {
  let dispatch = useDispatch();
  const { locations, loading } = useSelector((state) => state.Utilities);

  const [open, setOpen] = React.useState(null);
  const [options, setOptions] = React.useState([]);

  function fetchLocation(e, value) {
    if (value.length > 3) {
      dispatch(getLocationAction({ search: value }));
    }
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...topFilms]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <div>
      <Grid
        container
        style={{ marginTop: "10px", textAlign: "center" }}
        flexDirection="column"
        alignItems="center"
        gap={3}
      >
        <Grid item sm={10} className="Question">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: variables.primaryTextColor,
            }}
          >
            <h1>Enter location that you are targetting</h1>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginTop: "30px",
              textAlign: "center",
              color: variables.subTextColor,
            }}
          >
            <h2>Lorem ipsum dolor sit amet consectetur</h2>
          </Box>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "50px",
            }}
            spacing={2}
          >
            <Autocomplete
              multiple
              fullWidth
              id="asynchronous-demo"
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              isOptionEqualToValue={(option, value) =>
                option.canonicalName === value.canonicalName
              }
              getOptionLabel={(option) => option.canonicalName}
              options={locations}
              loading={loading}
              onInputChange={(e, value) => fetchLocation(e.target, value)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="filled"
                    style={{
                      backgroundColor: variables.primaryColor,
                      color: variables.whiteText,
                    }}
                    label={option.canonicalName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "20px",
            }}
            spacing={2}
          >
            <PrimaryButton
              style={{ height: "60px", minWidth: "240px" }}
              onClick={() => handleNext()}
            >
              Next
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default OnboardingPolicyLocation;
