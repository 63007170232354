import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import { Button, Divider, TextField, Typography } from "@mui/material";
import variables from "../../Assets/Styles/variables";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/UI/Buttons/Buttons";
import {
  archiveNetworkByIdAction,
  createNetworkAction,
  getNetworkByIdAction,
  updateNetworkAction,
} from "../../store/Networks/actions";
import { useMemo, useEffect } from "react";
import Joi from "joi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import HeaderWithBackButton from "../../components/UI/HeaderWithBackButton";
import PageHeader from "../../components/UI/PageWrapper/PageHeader";
import { useState } from "react";
import { HasPermission } from "../../utils/HasPermission";
import NotPermitted from "../../components/UI/NotPermitted";

const MySwal = withReactContent(Swal);

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, 1000);
  });
}

const INITIALSTATE = {
  name: "",
  email: "",
  contact_person: "",
  skype: "",
  domain: "",
  city: "",
  state: "",
  country: "",
  zip: "",
  address1: "",
  address2: "",
  notes: "",
};

function CreateNetwork() {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector((state) => state.Utilities);
  const { selectedNetwork } = useSelector((state) => state.Networks);
  const [networkData, setNetworkData] = useState({ ...INITIALSTATE });
  const [errors, setErrors] = useState({});
  const [readOnly, setReadOnly] = useState(false);

  const handleInput = ({ name, value }) => {
    setNetworkData((data) => ({ ...data, [name]: value }));
  };

  useMemo(() => {
    if (params.id) {
      setNetworkData({ ...networkData, ...selectedNetwork });
    }
  }, [selectedNetwork]);

  useEffect(() => {
    if (params.id) {
      dispatch(getNetworkByIdAction(params.id));
    }
  }, [dispatch, params.id]);

  const validationSchema = Joi.object({
    name: Joi.string().required().label("Company name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .allow(null, "")
      .label("Email"),
    contact_person: Joi.string().allow(null, "").label("Contact person name"),
    skype: Joi.string().allow(null, "").label("Skype"),
    domain: Joi.string().required().label("Website"),
    city: Joi.string().allow(null, "").label("City"),
    state: Joi.string().allow(null, "").label("State"),
    country: Joi.string().allow(null, "").label("Country"),
    zip: Joi.string().allow(null, "").label("Zip"),
    address1: Joi.string().allow(null, "").label("Address 1"),
    address2: Joi.string().allow(null, "").label("Address 2"),
    notes: Joi.string().allow(null, "").label("Notes"),
  });

  const handleFormSubmit = () => {
    const validationResult = validationSchema.validate(
      {
        name: networkData.name,
        email: networkData.email,
        contact_person: networkData.contact_person,
        skype: networkData.skype,
        domain: networkData.domain,
        city: networkData.city,
        state: networkData.state,
        country: networkData.country,
        zip: networkData.zip,
        address1: networkData.address1,
        address2: networkData.address2,
        notes: networkData.notes,
      },
      {
        abortEarly: false,
      }
    );
    if (validationResult.error) {
      const errors = {};
      validationResult.error.details.forEach((detail) => {
        errors[detail.path[0]] = detail.message;
      });
      setErrors(errors);
    } else {
      if (params.id && location.pathname.includes("/edit")) {
        dispatch(
          updateNetworkAction({ ...networkData, id: params.id, navigate })
        );
      } else {
        dispatch(createNetworkAction({ ...networkData, navigate }));
      }
    }
  };

  const archiveNetwork = (Id) => {
    MySwal.fire({
      title: "Are you sure you want to archive this network?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(archiveNetworkByIdAction(Id));
        navigate("/networks");
      }
    });
  };

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.
    })();
  }, [loading]);

  useEffect(() => {
    if (location.pathname.includes("/view")) {
      setReadOnly(true);
    }
    if (
      location.pathname.includes("/edit") ||
      location.pathname.includes("/create")
    ) {
      setReadOnly(false);
    }
  }, [location.pathname]);

  if (
    !HasPermission("crawler_networks_edit") &&
    !HasPermission("crawler_networks_create")
  ) {
    return <NotPermitted />;
  }

  return (
    <PageWrapper>
      <PageHeader header={<HeaderWithBackButton header="Networks" />} />

      <Grid container>
        <Grid item xs={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              borderRadius: "15px",
            }}
          >
            <Grid container spacing={2} style={{ marginBottom: "15px" }}>
              <Grid item xs={6}>
                <h2>{params.id ? `Edit` : `Create`} Network</h2>
              </Grid>
            </Grid>
            <Divider style={{ marginBottom: "50px" }} />
            <Grid
              container
              alignItems="center"
              justifyContent={params.id ? "center" : "start"}
              spacing={2}
              style={{ padding: "20px" }}
            >
              <Grid item sm={params.id ? 8.1 : 10}>
                <Divider textAlign="left">Genaral Info</Divider>
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Company Name{" "}
                </Typography>
                <Typography
                  variant="caption"
                  className="field-description"
                  style={{ color: variables.subTextColor }}
                >
                  Brief and identifiable title for a specific company name
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <TextField
                  fullWidth
                  label="Company Name"
                  variant="filled"
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  placeholder="Company Name"
                  value={networkData.name}
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={(e, value) => {
                    handleInput({ name: "name", value: e.target.value });
                  }}
                />
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Website{" "}
                </Typography>
                <Typography
                  variant="caption"
                  className="field-description"
                  style={{ color: variables.subTextColor }}
                >
                  Brief and identifiable title for a specific{" "}
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <TextField
                  fullWidth
                  label="Website"
                  variant="filled"
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  placeholder="Website"
                  value={networkData.domain}
                  error={!!errors.domain}
                  helperText={errors.domain}
                  onChange={(e, value) => {
                    handleInput({ name: "domain", value: e.target.value });
                  }}
                />
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Email{" "}
                </Typography>
                <Typography
                  variant="caption"
                  className="field-description"
                  style={{ color: variables.subTextColor }}
                >
                  Brief and identifiable title for a specific{" "}
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="filled"
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  placeholder="Email"
                  value={networkData.email}
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={(e, value) => {
                    handleInput({ name: "email", value: e.target.value });
                  }}
                />
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Contact Person Name{" "}
                </Typography>
                <Typography
                  variant="caption"
                  className="field-description"
                  style={{ color: variables.subTextColor }}
                >
                  Brief and identifiable title for a specific{" "}
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <TextField
                  fullWidth
                  label="Contact Person Name"
                  variant="filled"
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  placeholder="Contact Person Name"
                  value={networkData.contact_person}
                  error={!!errors.contact_person}
                  helperText={errors.contact_person}
                  onChange={(e, value) => {
                    handleInput({
                      name: "contact_person",
                      value: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Skype{" "}
                </Typography>
                <Typography
                  variant="caption"
                  className="field-description"
                  style={{ color: variables.subTextColor }}
                >
                  Brief and identifiable title for a specific{" "}
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <TextField
                  fullWidth
                  label="Skype"
                  variant="filled"
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  placeholder="Skype"
                  value={networkData.skype}
                  error={!!errors.skype}
                  helperText={errors.skype}
                  onChange={(e, value) => {
                    handleInput({ name: "skype", value: e.target.value });
                  }}
                />
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  City{" "}
                </Typography>
                <Typography
                  variant="caption"
                  className="field-description"
                  style={{ color: variables.subTextColor }}
                >
                  Brief and identifiable title for a specific{" "}
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <TextField
                  fullWidth
                  label="City"
                  variant="filled"
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  placeholder="City"
                  value={networkData.city}
                  error={!!errors.city}
                  helperText={errors.city}
                  onChange={(e, value) => {
                    handleInput({ name: "city", value: e.target.value });
                  }}
                />
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  State{" "}
                </Typography>
                <Typography
                  variant="caption"
                  className="field-description"
                  style={{ color: variables.subTextColor }}
                >
                  Brief and identifiable title for a specific{" "}
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <TextField
                  fullWidth
                  label="State"
                  variant="filled"
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  placeholder="State"
                  value={networkData.state}
                  error={!!errors.state}
                  helperText={errors.state}
                  onChange={(e, value) => {
                    handleInput({ name: "state", value: e.target.value });
                  }}
                />
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Country{" "}
                </Typography>
                <Typography
                  variant="caption"
                  className="field-description"
                  style={{ color: variables.subTextColor }}
                >
                  Brief and identifiable title for a specific{" "}
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <TextField
                  fullWidth
                  label="Country"
                  variant="filled"
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  placeholder="Country"
                  value={networkData.country}
                  error={!!errors.country}
                  helperText={errors.country}
                  onChange={(e, value) => {
                    handleInput({ name: "country", value: e.target.value });
                  }}
                />
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Zip{" "}
                </Typography>
                <Typography
                  variant="caption"
                  className="field-description"
                  style={{ color: variables.subTextColor }}
                >
                  Brief and identifiable title for a specific{" "}
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <TextField
                  fullWidth
                  label="Zip"
                  variant="filled"
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  placeholder="Zip"
                  value={networkData.zip}
                  error={!!errors.zip}
                  helperText={errors.zip}
                  onChange={(e, value) => {
                    handleInput({ name: "zip", value: e.target.value });
                  }}
                />
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Address 1{" "}
                </Typography>
                <Typography
                  variant="caption"
                  className="field-description"
                  style={{ color: variables.subTextColor }}
                >
                  Brief and identifiable title for a specific{" "}
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <TextField
                  fullWidth
                  label="Address 1"
                  variant="filled"
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  placeholder="Address 1"
                  value={networkData.address1}
                  error={!!errors.address1}
                  helperText={errors.address1}
                  onChange={(e, value) => {
                    handleInput({ name: "address1", value: e.target.value });
                  }}
                />
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Address 2{" "}
                </Typography>
                <Typography
                  variant="caption"
                  className="field-description"
                  style={{ color: variables.subTextColor }}
                >
                  Brief and identifiable title for a specific{" "}
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <TextField
                  fullWidth
                  label="Address 2"
                  variant="filled"
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  placeholder="Address 2"
                  value={networkData.address2}
                  error={!!errors.address2}
                  helperText={errors.address2}
                  onChange={(e, value) => {
                    handleInput({ name: "address2", value: e.target.value });
                  }}
                />
              </Grid>

              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Notes{" "}
                </Typography>
                <Typography
                  variant="caption"
                  className="field-description"
                  style={{ color: variables.subTextColor }}
                >
                  Some notes for the network
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <TextField
                  fullWidth
                  label="Notes"
                  variant="filled"
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  placeholder="Notes"
                  value={networkData.notes}
                  error={!!errors.notes}
                  helperText={errors.notes}
                  onChange={(e, value) => {
                    handleInput({ name: "notes", value: e.target.value });
                  }}
                />
              </Grid>

              <Grid item sm={params.id ? 8 : 10}>
                <Divider textAlign="left">Actions</Divider>
              </Grid>
              <Grid item sm={params.id ? 8 : 10}>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    widht: "100%",
                    alignItems: "center",
                    justifyContent: "end",
                    gap: "20px",
                  }}
                >
                  {params.id && !networkData.is_archived ? (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => archiveNetwork(params.id)}
                    >
                      Archive the Network
                    </Button>
                  ) : null}

                  <SecondaryButton
                    variant="outlined"
                    onClick={() => navigate(`/networks`)}
                  >
                    Cancel
                  </SecondaryButton>
                  {HasPermission("crawler_networks_create") && !params.id && (
                    <PrimaryButton className="" onClick={handleFormSubmit}>
                      {!params.id ? "Create A Network" : "Update the Network"}
                    </PrimaryButton>
                  )}

                  {HasPermission("crawler_networks_edit") && params.id && (
                    <PrimaryButton className="" onClick={handleFormSubmit}>
                      {!params.id ? "Create A Network" : "Update the Network"}
                    </PrimaryButton>
                  )}
                </div>
              </Grid>
            </Grid>

            <Divider style={{ marginTop: "50px" }} />
            <Grid
              container
              spacing={2}
              style={{ marginBottom: "15px", height: "50px" }}
            ></Grid>
          </Paper>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}

export default CreateNetwork;
