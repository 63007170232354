import * as React from "react";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import PageHeader from "../../components/UI/PageWrapper/PageHeader";
import { Grid, Paper, Typography } from "@mui/material";
import StyledTreeView from "./StyledTreeView";
import HeaderWithBackButton from "../../components/UI/HeaderWithBackButton";

export default function PolicyReportTree() {
  return (
    <PageWrapper>
      <PageHeader
        header={<HeaderWithBackButton header="Policy Report Tree View" />}
      />
      <Paper elevation={3} style={{ padding: "30px", borderRadius: "20px" }}>
        <Grid container>
          <Grid item sm={4}>
            {" "}
            <Typography>Policy Title : Adidas IN</Typography>{" "}
          </Grid>
          <Grid item sm={4}>
            {" "}
            <Typography>Search Keywords : shoes </Typography>{" "}
          </Grid>
          <Grid item sm={4}>
            {" "}
            <Typography>Location : Gujarat</Typography>{" "}
          </Grid>
          <Grid item sm={4}>
            {" "}
            <Typography>Run Date : 20 May 2023, 10:00 am</Typography>{" "}
          </Grid>
          <Grid item sm={4}>
            {" "}
            <Typography>Device :Desktop</Typography>{" "}
          </Grid>
        </Grid>
      </Paper>
      <StyledTreeView />
    </PageWrapper>
  );
}
