import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PageWrapper from "../../../components/UI/PageWrapper/PageWrapper";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import variables from "../../../Assets/Styles/variables";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Joi from "joi";
import {
  createPolicyAction,
  deletePolicyIdAction,
  getPolicyByIdAction,
  updatePolicyAction,
} from "../../../store/Policy/actions";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../components/UI/Buttons/Buttons";
import Cron from "react-js-cron";
import "react-js-cron/dist/styles.css";
import RightModal from "./RightModal";
import PageHeader from "../../../components/UI/PageWrapper/PageHeader";
import HeaderWithBackButton from "../../../components/UI/HeaderWithBackButton";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { HasPermission } from "../../../utils/HasPermission";
import NotPermitted from "../../../components/UI/NotPermitted";

const MySwal = withReactContent(Swal);

const INITIALSTATE = {
  title: "",
  description: "Description",
  search_keyword: [],
  location: [],
  language: "English",
  device_type: [],
  schedule_time: "30 5 * * 1,6",
  start_date_time: new Date(),
};

function CreatePolicies({ permissions }) {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [errors, setErrors] = useState({});
  const [action, setAction] = useState(null);
  const [openRightTab, setOpenRightTab] = useState(false);
  const [title, setTitle] = useState("");

  const openSelectLocationModal = () => {
    setTitle("Select Location");
    setOpenRightTab(true);
  };

  const openRandomLocationModal = () => {
    setTitle("Random Location");
    setOpenRightTab(true);
  };

  const customSetValue = useCallback((newValue) => {
    setPolicyData((data) => ({ ...data, schedule_time: newValue }));
  }, []);

  const validationSchema = Joi.object({
    title: Joi.string().required().label("Title"),
    description: Joi.string().required().label("Description"),
    device_type: Joi.array()
      .items(Joi.string().min(1))
      .min(1)
      .required()
      .label("Device"),
    location: Joi.array()
      .items(Joi.object().min(1))
      .min(1)
      .required()
      .label("Locations"),
    search_keyword: Joi.array()
      .items(Joi.string().min(1))
      .min(1)
      .required()
      .label("Search Keyword"),
    schedule_time: Joi.required().label("Schedule Time"),
    start_date_time: Joi.required().label("Schedule Time"),
  });

  const { loading } = useSelector((state) => state.Utilities);
  const { selectedPolicy } = useSelector((state) => state.Policy);
  const [PolicyData, setPolicyData] = useState({ ...INITIALSTATE });
  const [readOnly, setReadOnly] = useState(false);

  const handleInput = ({ name, value }) => {
    if (name === "search_keyword") {
      value.map((val) => {
        if (val.indexOf(",") > -1) {
          value.splice(value.indexOf(val), 1);
          const eachValArr = val.split(",");
          eachValArr.map((eachVal) => value.push(eachVal.trim()));
        }
      });
    }
    setPolicyData((data) => ({ ...data, [name]: value }));
  };

  const handleFormSubmit = () => {
    const validationResult = validationSchema.validate(
      {
        title: PolicyData.title,
        description: PolicyData.description,
        search_keyword: PolicyData.search_keyword,
        location: PolicyData.location,
        device_type: PolicyData.device_type,
        schedule_time: PolicyData.schedule_time,
        start_date_time: PolicyData.start_date_time,
      },
      {
        abortEarly: false,
      }
    );

    if (validationResult.error) {
      const errors = {};
      validationResult.error.details.forEach((detail) => {
        errors[detail.path[0]] = detail.message;
      });
      setErrors(errors);
    } else {
      if (params.id && location.pathname.includes("/edit")) {
        dispatch(
          updatePolicyAction({ ...PolicyData, id: params.id, navigate })
        );
      } else if (params.id && location.pathname.includes("/view")) {
        dispatch(
          updatePolicyAction({ ...PolicyData, id: params.id, navigate })
        );
      } else {
        dispatch(createPolicyAction({ ...PolicyData, navigate }));
      }
    }
  };

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this Role!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePolicyIdAction({ Id: id, navigate }));
      }
    });
  };

  useEffect(() => {
    if (location.pathname.includes("/view")) {
      setReadOnly(true);
    }
    if (
      location.pathname.includes("/edit") ||
      location.pathname.includes("/create")
    ) {
      setReadOnly(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (params.id) {
      dispatch(getPolicyByIdAction(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (location.pathname.includes("/create")) {
      setAction("Create");
    }
    if (location.pathname.includes("/edit")) {
      setAction("Edit");
    }
    if (location.pathname.includes("/view")) {
      setAction("View");
    }
    if (location.pathname.includes("/clone")) {
      setAction("Clone");
    }

    return () => {
      setAction(null);
    };
  }, [location]);

  useEffect(() => {
    if (action === "Create") {
      setPolicyData({ ...INITIALSTATE });
    } else if (selectedPolicy._id) {
      setPolicyData({
        title:
          action === "Clone"
            ? selectedPolicy.title.concat(" Clone")
            : selectedPolicy.title,
        description: "Description",
        search_keyword: selectedPolicy.search_keyword,
        location: selectedPolicy.location,
        language: "English",
        device_type: selectedPolicy.device_type,
        schedule_time: selectedPolicy.schedule_time,
        start_date_time: selectedPolicy.start_date_time,
      });
    }
  }, [action, selectedPolicy]);

  if (
    !HasPermission("crawler_policies_create") &&
    !HasPermission("crawler_policies_edit")
  ) {
    return <NotPermitted />;
  }

  return (
    <PageWrapper>
      <PageHeader header={<HeaderWithBackButton header="Policies" />} />
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              borderRadius: "15px",
            }}
          >
            <Grid container spacing={2} style={{ marginBottom: "15px" }}>
              <Grid item xs={6}>
                <h2>{action} Policy</h2>
              </Grid>
            </Grid>
            <Divider style={{ marginBottom: "50px" }} />
            <Grid
              container
              alignItems="center"
              justifyContent={params.id ? "center" : "start"}
              spacing={2}
              style={{ padding: "20px" }}
            >
              <Grid item sm={params.id ? 8.1 : 10}>
                <Divider textAlign="left">Genaral Info</Divider>
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Policy Name{" "}
                </Typography>
                <Typography
                  variant="caption"
                  className="field-description"
                  style={{ color: variables.subTextColor }}
                >
                  Brief and identifiable title for a specific policy
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <TextField
                  fullWidth
                  label="Policy Name"
                  variant="filled"
                  inputProps={{
                    readOnly: readOnly,
                  }}
                  placeholder="Policy Name"
                  value={PolicyData.title}
                  onChange={(e, value) => {
                    handleInput({ name: "title", value: e.target.value });
                  }}
                  error={!!errors.title}
                  helperText={errors.title}
                />
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Keyword{" "}
                </Typography>
                <Typography variant="caption" className="field-description">
                  Enter the search tearms you want to monitor
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  freeSolo
                  fullWidth
                  value={PolicyData.search_keyword}
                  onChange={(e, value) => {
                    handleInput({ name: "search_keyword", value: value });
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="filled"
                        style={{
                          backgroundColor: variables.primaryColor,
                          color: variables.whiteText,
                        }}
                        label={option}
                        {...getTagProps({ index })}
                        on
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Keyword"
                      variant="filled"
                      placeholder="Keyword"
                      error={!!errors.search_keyword}
                      helperText={errors.search_keyword}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Device
                </Typography>
                <Typography variant="caption" className="field-description">
                  Select Which Device Types you'd like to monitor.
                </Typography>
              </Grid>

              <Grid item sm={params.id ? 8 : 6}>
                <FormControl fullWidth variant="filled" sx={{ minWidth: 300 }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Device
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    selected={PolicyData.device_type}
                    value={PolicyData.device_type}
                    onChange={(e) => {
                      if (!readOnly) {
                        handleInput({
                          name: "device_type",
                          value: e.target.value,
                        });
                      }
                    }}
                    input={
                      <FilledInput id="select-multiple-chip" label="Chip" />
                    }
                    error={!!errors.device_type}
                    // helperText={errors.device_type}
                    variant="filled"
                    fullWidth
                    inputProps={{
                      readOnly: readOnly,
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            variant="filled"
                            style={{
                              backgroundColor: variables.primaryColor,
                              color: variables.whiteText,
                            }}
                            key={value}
                            label={value}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    <MenuItem value="Desktop">Desktop</MenuItem>
                    <MenuItem value="Tablet">Tablet</MenuItem>
                    <MenuItem value="Mobile ">Mobile</MenuItem>
                  </Select>
                  <FormHelperText error={!!errors.device_type}>
                    {errors.device_type}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Location{" "}
                </Typography>
                <Typography variant="caption" className="field-description">
                  Select One or more locations you'd like to monitor
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                {/* <Filters handleUpdateSelectedOptions={handleUpdateSelectedOptions} /> */}
                <Button
                  onClick={openSelectLocationModal}
                  sx={{ display: readOnly ? "none" : "inline-block" }}
                >
                  Select Location
                </Button>
                <Button
                  onClick={openRandomLocationModal}
                  sx={{ display: readOnly ? "none" : "inline-block" }}
                >
                  Random Location
                </Button>
                <Autocomplete
                  multiple
                  fullWidth
                  id=""
                  options={[]}
                  disabled={readOnly}
                  value={PolicyData.location}
                  onChange={(e, value) => {
                    handleInput({ name: "location", value: value });
                  }}
                  renderTags={(value, getTagProps) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        paddingTop: "10px",
                        gap: 0.5,
                      }}
                    >
                      {value.map((option, index) => {
                        const canonicalName = option.hasOwnProperty(
                          "canonical_name"
                        )
                          ? option.canonical_name
                          : option.canonicalName;
                        return (
                          <Chip
                            variant="filled"
                            style={{
                              backgroundColor: variables.primaryColor,
                              color: variables.whiteText,
                            }}
                            label={canonicalName}
                            {...getTagProps({ index })}
                          />
                        );
                      })}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Locations"
                      variant="filled"
                      error={!!errors.location}
                      helperText={errors.location}
                      inputProps={{
                        ...params.inputProps,
                        endadornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.inputProps.endAdornment}
                          </React.Fragment>
                        ),
                        readOnly: readOnly,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item sm={params.id ? 8 : 10}>
                <Divider textAlign="left">Policy Schedule</Divider>
              </Grid>
              <Grid
                item
                sm={4}
                style={{ display: params.id ? "none" : "block" }}
              >
                <Typography
                  variant="body1"
                  className="field-name"
                  style={{ fontWeight: "bold" }}
                >
                  Schedule Time{" "}
                </Typography>
                <Typography variant="caption" className="field-description">
                  How frequently this policy should run?
                </Typography>
              </Grid>
              <Grid item sm={params.id ? 8 : 6}>
                <div>
                  <Cron
                    value={PolicyData.schedule_time}
                    setValue={customSetValue}
                  />

                  <div>
                    <span style={{ fontSize: 12 }}>
                      Double click on a dropdown option to automatically select
                      / unselect a periodicity
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item sm={params.id ? 8 : 10}>
                <Divider textAlign="left">Actions</Divider>
              </Grid>
              <Grid item sm={params.id ? 8 : 10}>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    widht: "100%",
                    alignItems: "center",
                    justifyContent: "end",
                    gap: "20px",
                  }}
                >
                  {params.id && HasPermission("crawler_policies_delete") ? (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDelete(params.id)}
                    >
                      Delete the Policy
                    </Button>
                  ) : null}
                  <SecondaryButton
                    variant="outlined"
                    onClick={() => navigate(`/policies`)}
                  >
                    Cancel
                  </SecondaryButton>
                  {HasPermission("crawler_policies_create") &&
                    action == "Create" && (
                      <PrimaryButton className="" onClick={handleFormSubmit}>
                        {action} The Policy
                      </PrimaryButton>
                    )}

                  {HasPermission("crawler_policies_edit") &&
                    action == "Edit" && (
                      <PrimaryButton className="" onClick={handleFormSubmit}>
                        {action} The Policy
                      </PrimaryButton>
                    )}
                </div>
              </Grid>
            </Grid>

            <Divider style={{ marginTop: "50px" }} />
            <Grid
              container
              spacing={2}
              style={{ marginBottom: "15px", height: "50px" }}
            ></Grid>
          </Paper>
        </Grid>
      </Grid>
      <RightModal
        title={title}
        open={openRightTab}
        setOpen={setOpenRightTab}
        handleInput={handleInput}
        locationValue={PolicyData.location}
      />
    </PageWrapper>
  );
}

export default CreatePolicies;
