/* eslint-disable no-empty-pattern */
import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import variables from "../../../Assets/Styles/variables";

const CustomButton = styled(Button)(({}) => ({
  backgroundColor: "#4CAF50",
  color: variables.whiteText,
  "&:hover": {
    backgroundColor: "#3e8e41",
  },
}));

const CustomPrimaryButton = styled(Button)(({}) => ({
  backgroundColor: variables.primaryButtonColor,
  color: variables.whiteText,
  "&:hover": {
    backgroundColor: variables.primaryButtonColor,
  },
}));
const CustomSecondaryButton = styled(Button)(({}) => ({
  backgroundColor: variables.secondaryButtonColor,
  color: variables.whiteText,
  "&:hover": {
    backgroundColor: variables.secondaryButtonColor,
  },
}));
const CustomDangerButton = styled(Button)(({}) => ({
  backgroundColor: variables.primaryColor,
  color: variables.whiteText,
  "&:hover": {
    backgroundColor: variables.primaryColor,
  },
}));

const CustomRoundedButton = styled(Button)(({}) => ({
  backgroundColor: variables.primaryButtonColor,
  color: variables.whiteText,
  borderRadius: "25px",
  textTransform: "capitalize",
  "&:hover": {
    boxShadow: "0px 0px 20px 0px #3366ff3d ",
  },
}));

function MyButton() {
  return <CustomButton variant="contained">Click Me</CustomButton>;
}
export function RoundedButton({ children, ...rest }) {
  return (
    <CustomRoundedButton variant="contained" {...rest}>
      {children}
    </CustomRoundedButton>
  );
}
export function PrimaryButton({ children, ...rest }) {
  return (
    <CustomPrimaryButton variant="contained" {...rest}>
      {children}
    </CustomPrimaryButton>
  );
}
export function SecondaryButton({ children, ...rest }) {
  return (
    <CustomSecondaryButton variant="contained" {...rest}>
      {children}
    </CustomSecondaryButton>
  );
}
export function DangerButton({ children, ...rest }) {
  return (
    <CustomDangerButton variant="contained" {...rest}>
      {children}
    </CustomDangerButton>
  );
}

export default MyButton;
