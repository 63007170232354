import {
  Avatar,
  Box,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import variables from "../../Assets/Styles/variables";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import Google from "../../Assets/Google__G__Logo.svg.png";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/UI/Buttons/Buttons";
import { updateNetworkAction } from "../../store/Networks/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  exportAdsAction,
  getAllAdsAction,
  getPolicyByIdAction,
  getPolicyReportFiltersAction,
} from "../../store/PolicyReport/actions";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import PageHeader from "../../components/UI/PageWrapper/PageHeader";
import Collapse from "@mui/material/Collapse";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import AdViewPagination from "./AdViewPagination";
import { formatTimeWithSec } from "../../utils/timeUtils";
import KeywordIcon from "@mui/icons-material/Tag";
import LocationIcon from "@mui/icons-material/MyLocation";
import DeviceIcon from "@mui/icons-material/Devices";
import AdViewFilterCard from "./AdViewFilterCard";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { HasPermission } from "../../utils/HasPermission";
import NotPermitted from "../../components/UI/NotPermitted";

const IconLinkDetail = ({ icon, text }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <SvgIcon component={icon} sx={{ marginRight: "5px", color: "#fff" }} />
      <span
        style={{
          fontWeight: 700,
          fontSize: "0.9rem",
          textAlign: "right",
          wordBreak: "break-word",
          color: "#fff",
        }}
      >
        {text}
      </span>
    </Box>
  );
};

const GoogleResults = ({ ad }) => {
  return (
    <div style={{ padding: "20px" }}>
      <Grid container spacing={1}>
        <Grid item sm={12} sx={{ display: "flex" }}>
          <Avatar
            style={{ width: "27px", height: "27px", margin: "5px 10px 0 0" }}
            src={Google}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              style={{
                color: variables.primaryTextColor,
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              {ad.title}
            </Typography>
            <Typography
              style={{ fontSize: "16px", color: variables.subTextColor }}
            >
              {ad.displayed_link}
            </Typography>
            <Typography
              style={{ fontSize: "14px", color: variables.subTextColor }}
            >
              {ad.description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

function AdView({ lastLocationBeforeUnmount }) {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  const { allAds, exportLoading, exportLink, adViewFilters, loading } =
    useSelector((state) => state.PolicyReport);
  const filters = adViewFilters;

  const { data, ...rest } = allAds;
  const pagination = {
    currentPage: parseInt(rest.currentPage),
    perPage: parseInt(rest.perPage),
    totalCount: parseInt(rest.totalCount),
    totalPages: parseInt(rest.totalPages),
  };

  const [openModal, setOpenModal] = useState(false);
  const [modalName, setModalName] = useState("");
  const [modalLink, setModalLink] = useState("");
  const [modalId, setModalId] = useState("");

  function getTodayAndBefore7Days() {
    const currentDate = new Date();

    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 7);

    const todayDateString = currentDate.toISOString().split("T")[0];
    const sevenDaysAgoDateString = sevenDaysAgo.toISOString().split("T")[0];

    return {
      today: todayDateString,
      sevenDaysAgo: sevenDaysAgoDateString,
    };
  }

  const dates = getTodayAndBefore7Days();

  const filtersInitialState = {
    keywords: [],
    locations: [],
    device: [],
    networks: [],
    status: "",
    startDate: dates.sevenDaysAgo,
    endDate: dates.today,
  };

  let [loaded, setLoaded] = useState(false);
  let [exportClicked, setExportClicked] = useState(false);
  let [paginate, setPaginate] = useState({
    currentPage: 1,
    perPage: 500,
  });

  const handleExport = () => {
    dispatch(
      exportAdsAction({
        startDate: filters.startDate,
        endDate: filters.endDate,
      })
    );
    setExportClicked(true);
  };

  useEffect(() => {
    dispatch(getPolicyReportFiltersAction());
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded) {
      const reqParams = {
        ...paginate,
        startDate: filters.startDate,
        endDate: filters.endDate,
        keyword: Array.isArray(filters?.keywords)
          ? filters?.keywords.map((obj) => obj.title).join(",")
          : filters?.keywords,
        location: Array.isArray(filters.locations)
          ? filters?.locations?.map((obj) => obj.title).join("|")
          : filters?.locations,
        device: Array.isArray(filters.device)
          ? filters.device.map((obj) => obj.title).join(",")
          : filters?.device,
        network: Array.isArray(filters.networks)
          ? filters.networks.map((obj) => obj.id).join(",")
          : filters.networks,
      };
      dispatch(getAllAdsAction(reqParams));
    }
  }, [paginate]);

  useEffect(() => {
    if (exportClicked && exportLink !== "") {
      const startDownload = () => {
        const link = document.createElement("a");
        link.href = exportLink;
        link.download = "ads-data";
        link.click();
      };
      startDownload();
      setExportClicked(false);
    }
  }, [exportLink]);

  useEffect(() => {
    setSearchParams((params) => {
      params.set("keywords", filters?.keywords);
      params.set("startDate", filters?.startDate);
      params.set("endDate", filters?.endDate);
      params.set("locations", filters?.locations);
      params.set("device", filters?.device);
      params.set("networks", filters?.networks);
      return params;
    });
  }, [filters]);

  if (!HasPermission("crawler_ad_view_view")) {
    return <NotPermitted />;
  }

  return (
    <PageWrapper>
      <div>
        <PageHeader
          header="Ad View"
          headerButton={
            <PrimaryButton
              startIcon={!exportLoading ? <SystemUpdateAltIcon /> : null}
              variant="contained"
              onClick={handleExport}
            >
              {exportLoading ? (
                <span>
                  <CircularProgress sx={{ color: "gray" }} size={25} />
                </span>
              ) : (
                "Export"
              )}
            </PrimaryButton>
          }
        />
        <AdViewFilterCard
          filters={filters}
          filtersInitialState={filtersInitialState}
          paginate={paginate}
          setPaginate={setPaginate}
          lastLocationBeforeUnmount={lastLocationBeforeUnmount}
        >
          {!data?.length && !loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "50px",
              }}
            >
              No data found!
            </div>
          )}
          {data?.length > 0 && (
            <div style={{ marginBottom: "1rem" }}>
              <AdViewPagination
                ads={data}
                pagination={pagination}
                filters={filters}
                paginate={paginate}
                setPaginate={setPaginate}
              />
            </div>
          )}
          {allAds &&
            data?.map((ad, adIndex) => {
              return (
                <Card
                  style={{
                    marginBottom: "20px",
                    borderRadius: "15px 15px 0px 0px",
                  }}
                  sx={{ maxWidth: "75vw" }}
                  key={adIndex}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "10px",
                      backgroundColor: variables.primaryColor,
                      borderRadius: "15px 15px 0px 0px",
                    }}
                  >
                    {ad.keyword && (
                      <IconLinkDetail icon={KeywordIcon} text={ad.keyword} />
                    )}
                    {ad.device && (
                      <IconLinkDetail icon={DeviceIcon} text={ad.device} />
                    )}
                    {ad.createdAt && (
                      <IconLinkDetail
                        icon={AccessTimeIcon}
                        text={formatTimeWithSec(ad.createdAt)}
                      />
                    )}
                    {ad.location && (
                      <IconLinkDetail icon={LocationIcon} text={ad.location} />
                    )}
                  </Box>
                  <Divider />

                  <GoogleResults ad={ad} />
                  <Divider />
                  <Grid
                    style={{
                      padding: "10px",
                      margin: "0px 20px",
                      width: "98%",
                    }}
                  >
                    {ad.chain.map((obj, index2) => (
                      <CustomLink
                        modalName={modalName}
                        setModalName={setModalName}
                        modalLink={modalLink}
                        setModalLink={setModalLink}
                        modalId={modalId}
                        setModalId={setModalId}
                        linkDetails={obj}
                        index={index2 + 1}
                        report={ad.policy_report}
                      />
                    ))}
                  </Grid>
                </Card>
              );
            })}

          {data?.length > 0 && (
            <AdViewPagination
              ads={data}
              pagination={pagination}
              filters={filters}
              paginate={paginate}
              setPaginate={setPaginate}
            />
          )}

          <InputModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            modalName={modalName}
            modalLink={modalLink}
            modalId={modalId}
          />
        </AdViewFilterCard>
      </div>
    </PageWrapper>
  );
}

function CustomLink({
  modalName,
  setModalName,
  modalLink,
  setModalLink,
  modalId,
  setModalId,
  linkDetails,
  index,
  report,
}) {
  const navigate = useNavigate();
  const [reduceLink, setReduceLink] = useState(false);
  const [copied, setCopied] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  // const [modalName, setModalName] = useState("");
  // const [modalLink, setModalLink] = useState("");
  // const [modalId, setModalId] = useState("");

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  function NetworkChip({ network }) {
    return (
      <div
        style={{
          textAlign: "end",
        }}
        onClick={(e) => {
          if (e.target.nodeName === "DIV" || e.target.nodeName === "SPAN") {
            navigate("/networks/edit/" + network?._id);
          } else {
            setOpenModal(true);
            setModalName(network?.name);
            setModalLink(network?.domain);
            setModalId(network?._id);
          }
        }}
      >
        <span
          style={{
            color: network?.is_archived
              ? variables.disabledTextColor
              : variables.primaryButtonColor,
            verticalAlign: "super",
            fontWeight: network?.is_archived ? "400" : "700",
          }}
        >
          {network?.name?.replace("www.", "")}
        </span>
        <EditNoteIcon
          sx={{
            color: variables.disabledTextColor,
            marginLeft: "3px",
            cursor: "pointer",
          }}
        />
        <InputModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          modalName={modalName}
          modalLink={modalLink}
          modalId={modalId}
          Individual_Policy_Report={report}
        />
      </div>
    );
  }

  return (
    <Grid container fullWidth>
      <Grid item sm={0.5}>
        {!copied ? (
          <div>
            <ContentCopyIcon
              sx={{
                fontSize: "0.9rem",
                color: variables.primaryButtonColor,
                cursor: "pointer",
              }}
              onClick={() => copyText(linkDetails.url)}
            />{" "}
            {index}.
          </div>
        ) : (
          <div>
            <DoneIcon
              sx={{ fontSize: "0.9rem", color: variables.successColor }}
            />{" "}
            {index}.
          </div>
        )}
      </Grid>
      <Grid item sm={11.5}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Collapse
            in={reduceLink}
            collapsedSize={20}
            onClick={() => setReduceLink((prev) => !prev)}
            sx={{ display: "inline-block" }}
          >
            <div
              style={{
                height: "auto",
                wordWrap: "wrap",
                wordBreak: "break-all",
                color: variables.subTextColor,
                display: "flex",
              }}
            >
              {linkDetails.url}
            </div>
          </Collapse>
          <NetworkChip network={linkDetails.network} />
        </div>
      </Grid>
    </Grid>
  );
}

function InputModal({
  openModal,
  setOpenModal,
  modalName,
  modalLink,
  modalId,
}) {
  const dispatch = useDispatch();
  const params = useParams();

  const [name, setName] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");

  const handleUpdate = () => {
    dispatch(
      updateNetworkAction({ id: modalId, domain: websiteLink, name: name })
    );
    dispatch(
      getPolicyByIdAction({
        Id: params?.id,
      })
    );
    handleClose();
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setName(modalName);
    setWebsiteLink(modalLink);
  }, [modalName, modalLink, openModal]);

  return (
    <div style={{ minWidth: "500px" }}>
      <Dialog open={openModal} onClose={handleClose}>
        <DialogTitle>Edit Network</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            sx={{ minWidth: "500px" }}
          />

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Link"
            type="text"
            fullWidth
            variant="standard"
            value={websiteLink}
            onChange={(e) => {
              setWebsiteLink(e.target.value);
            }}
            sx={{ minWidth: "400px" }}
          />
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          <PrimaryButton
            onClick={() => {
              handleUpdate();
            }}
          >
            Update
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdView;
