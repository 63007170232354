import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../utils/API";

function* GetDashboardCardSaga() {
  try {
    yield put({ type: actionTypes.SET_DASHBOARD_LOADING });
    yield put({ type: actionTypes.GET_DASHBOARD_CHART_DATA });
    yield put({ type: actionTypes.GET_DASHBOARD_PIE_CHART_DATA });
    yield put({ type: actionTypes.GET_DASHBOARD_NETWORKS_CHART_DATA });

    const { data } = yield API.get(`/api/dashboard/`);

    if (data.success) {
      yield put({
        type: actionTypes.GET_DASHBOARD_CARD_DATA_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error("Unable To Get Dashboard Details");
      yield put({
        type: actionTypes.GET_DASHBOARD_CARD_DATA_FAILURE,
        errorMessage: "Unable To Get Dashboard Details",
      });
    }
  } catch (error) {
    toast.error("Unable To Get Dashboard Details");
    yield put({
      type: actionTypes.GET_DASHBOARD_CARD_DATA_FAILURE,
      errorMessage: "Unable To Get Dashboard Details",
    });
  }
}

function* GetDashboardChartSaga() {
  try {
    yield put({ type: actionTypes.SET_DASHBOARD_LOADING });
    const { data } = yield API.get(`/api/dashboard/recent-ads-networks`);

    if (data.success) {
      yield put({
        type: actionTypes.GET_DASHBOARD_CHART_DATA_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error("Unable To Get Dashboard Details");
      yield put({
        type: actionTypes.GET_DASHBOARD_CHART_DATA_FAILURE,
        errorMessage: "Unable To Get Dashboard Details",
      });
    }
  } catch (error) {
    toast.error("Unable To Get Dashboard Details");
    yield put({
      type: actionTypes.GET_DASHBOARD_CHART_DATA_FAILURE,
      errorMessage: "Unable To Get Dashboard Details",
    });
  }
}

function* GetDashboardPieChartSaga() {
  try {
    yield put({ type: actionTypes.SET_DASHBOARD_LOADING });
    const { data } = yield API.get(`/api/dashboard/recent-device-ads`);

    if (data.success) {
      yield put({
        type: actionTypes.GET_DASHBOARD_PIE_CHART_DATA_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error("Unable To Get Dashboard Details");
      yield put({
        type: actionTypes.GET_DASHBOARD_PIE_CHART_DATA_FAILURE,
        errorMessage: "Unable To Get Dashboard Details",
      });
    }
  } catch (error) {
    toast.error("Unable To Get Dashboard Details");
    yield put({
      type: actionTypes.GET_DASHBOARD_PIE_CHART_DATA_FAILURE,
      errorMessage: "Unable To Get Dashboard Details",
    });
  }
}

function* GetDashboardNetworksChartSaga() {
  try {
    yield put({ type: actionTypes.SET_DASHBOARD_LOADING });
    const { data } = yield API.get(`/api/dashboard/recent-networks`);

    if (data.success) {
      yield put({
        type: actionTypes.GET_DASHBOARD_NETWORKS_CHART_DATA_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error("Unable To Get Dashboard Details");
      yield put({
        type: actionTypes.GET_DASHBOARD_NETWORKS_CHART_DATA_FAILURE,
        errorMessage: "Unable To Get Dashboard Details",
      });
    }
  } catch (error) {
    toast.error("Unable To Get Dashboard Details");
    yield put({
      type: actionTypes.GET_DASHBOARD_NETWORKS_CHART_DATA_FAILURE,
      errorMessage: "Unable To Get Dashboard Details",
    });
  }
}

function* GetDashboardPoliciesChartSaga() {
  try {
    yield put({ type: actionTypes.SET_DASHBOARD_LOADING });
    const { data } = yield API.get(`/api/dashboard/recent-policy-reports`);

    if (data.success) {
      yield put({
        type: actionTypes.GET_DASHBOARD_POLICIES_CHART_DATA_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error("Unable To Get Dashboard Details");
      yield put({
        type: actionTypes.GET_DASHBOARD_POLICIES_CHART_DATA_FAILURE,
        errorMessage: "Unable To Get Dashboard Details",
      });
    }
  } catch (error) {
    toast.error("Unable To Get Dashboard Details");
    yield put({
      type: actionTypes.GET_DASHBOARD_POLICIES_CHART_DATA_FAILURE,
      errorMessage: "Unable To Get Dashboard Details",
    });
  }
}

function* setWebsiteLoadingSaga({payload}) {
      yield put({
        type: actionTypes.SET_WEBSITE_LOADING_SUCCESS,
        payload: payload,
      });
}
function* DashboardSaga() {
  yield all([
    takeLatest(actionTypes.GET_DASHBOARD_CARD_DATA, GetDashboardCardSaga),
    takeLatest(actionTypes.GET_DASHBOARD_CHART_DATA, GetDashboardChartSaga),
    takeLatest(actionTypes.SET_WEBSITE_LOADING, setWebsiteLoadingSaga),
    takeLatest(
      actionTypes.GET_DASHBOARD_PIE_CHART_DATA,
      GetDashboardPieChartSaga
    ),
    takeLatest(
      actionTypes.GET_DASHBOARD_NETWORKS_CHART_DATA,
      GetDashboardNetworksChartSaga
    ),
    takeLatest(
      actionTypes.GET_DASHBOARD_POLICIES_CHART_DATA,
      GetDashboardPoliciesChartSaga
    ),
  ]);
}

export default DashboardSaga;
