import React, { useState } from "react";
import {
  CircularProgress,
  Divider,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import variable from '../../Assets/Styles/variables'
import './Login.scss'
import { PrimaryButton } from "../../components/UI/Buttons/Buttons";
import { resetPassword, } from "../../store/Account/actions";
import { useDispatch, useSelector } from "react-redux";
import UnauthPageWrapper from "../../components/UI/PageWrapper/UnauthPageWrapper";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

let theme = createTheme();

const useStyles = makeStyles(() => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

function ResetPassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const { loading } = useSelector((state) => state.Account);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    if(password !== confirmPassword) setConfirmPasswordError("Passwords do not match")
    else {
      const data = {
        password: password,
        confirm_password: confirmPassword,
        resetToken: params.token,
        navigate
      }
      setConfirmPasswordError("");
      dispatch(resetPassword(data));
    }
  };


  return (
    <UnauthPageWrapper>


      <div className="login_container">
        <h2>Reset Password!</h2>
        <p>Enter your new password...</p>
        <form onSubmit={handleSubmit}>
          <TextField
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={password}
            autoComplete="off"
            style={{ margin: "15px 0px" }}
            onChange={(event) => setPassword(event.target.value)}
            InputProps={{
                endAdornment: (
                  showPassword ?
                    <VisibilityIcon style={{ color: variable.primaryButtonColor }} onClick={() => setShowPassword(false)} />
                    : <VisibilityOffIcon style={{ color: variable.primaryButtonColor }} onClick={() => setShowPassword(true)} />
                ),
            }}
          />
          <TextField
            type={showConfirmPassword ? 'text' : 'password'}
            label="Confirm Password"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={confirmPassword}
            autoComplete="off"
            style={{ margin: "15px 0px" }}
            onChange={(event) => setConfirmPassword(event.target.value)}
            InputProps={{
                endAdornment: (
                    showConfirmPassword ?
                    <VisibilityIcon style={{ color: variable.primaryButtonColor }} onClick={() => setShowConfirmPassword(false)} />
                    : <VisibilityOffIcon style={{ color: variable.primaryButtonColor }} onClick={() => setShowConfirmPassword(true)} />
                ),
            }}
            error={!!confirmPasswordError}
            helperText={confirmPasswordError}
          />

          <PrimaryButton
        
          fullWidth
          type="submit"
          style={{ margin: "15px 0px" }}
          >
            {loading ? <CircularProgress size={25} color="action" /> : 'Reset Password'}
          </PrimaryButton>
        </form>

        <Divider style={{marginTop:"30px"}} />
      </div>



    </UnauthPageWrapper>
        
  );
}

export default ResetPassword;
