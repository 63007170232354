import React, { useState } from "react";
import { Divider, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import "./Login.scss";
import { PrimaryButton } from "../../components/UI/Buttons/Buttons";
import { doLoginAction } from "../../store/Account/actions";
import { useDispatch } from "react-redux";
import UnauthPageWrapper from "../../components/UI/PageWrapper/UnauthPageWrapper";

let theme = createTheme();

const useStyles = makeStyles(() => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

function Login({ lastRouteInHistory }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(doLoginAction({ email, password, navigate, lastRouteInHistory }));
  };

  return (
    <UnauthPageWrapper>
      <div className="login_container">
        <h2>Welcome Back!</h2>
        <p>Start Managing and Track your Keywords....</p>
        <form onSubmit={handleSubmit}>
          <TextField
            type="email"
            label="Email"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={email}
            style={{ margin: "15px 0px" }}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            type="password"
            label="Password"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={password}
            style={{ margin: "15px 0px" }}
            onChange={(event) => setPassword(event.target.value)}
          />

          <PrimaryButton
            disabled
            fullWidth
            type="submit"
            style={{ margin: "15px 0px" }}
          >
            Get Access
          </PrimaryButton>
          <div style={{ textAlign: "right" }}>
            <Link
              style={{ textDecoration: "none", color: "#3945BA" }}
              // onClick={() => {
              //   window.location.href = `${window.location.origin}/forgot-password`;
              // }}
            >
              {" "}
              Forgot Password?{" "}
            </Link>
          </div>
        </form>

        <Divider style={{ marginTop: "30px" }} />
      </div>
    </UnauthPageWrapper>
  );
}

export default Login;
