import * as actionTypes from "./actionTypes";

 
function getTodayAndBefore7Days() {
  const currentDate = new Date();

  const sevenDaysAgo = new Date(currentDate);
  sevenDaysAgo.setDate(currentDate.getDate() - 7);

  const todayDateString = currentDate.toISOString().split('T')[0];
  const sevenDaysAgoDateString = sevenDaysAgo.toISOString().split('T')[0];

  return {
    today: todayDateString,
    sevenDaysAgo: sevenDaysAgoDateString,
  };
}

const dates = getTodayAndBefore7Days();

const initialState = {
  error: false,
  errorMessage: "",
  message:"",
  loading: false,
  PolicyReportsList: {
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
    data:[],
  },
  selectedPolicy:{},
  filterOptions: {},
  allAds: {},
  exportLink: "",
  filters: {
    policiesName: [],
    locations: [],
    device: [],
    networks: [],
    status: "",
    startDate: dates.sevenDaysAgo,
    endDate: dates.today
  },
  adViewFilters: {
    keywords: [],
    locations: [],
    device: [],
    networks: [],
    status: "",
    startDate: dates.sevenDaysAgo,
    endDate: dates.today,
  }
};

const PolicyReport = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_POLICY_REPORT_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
      case actionTypes.SET_EXPORT_REPORT_LOADING:
        return {
          ...state,
          exportLoading: true,
          error: false,
          errorMessage: "",
        };
    case actionTypes.CREATE_POLICY_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      
      };
    case "EXPORT_ADS_SUCCESS":
      return {
        ...state,
        exportLoading:false,
        exportLink: action.payload
      };
    case "EXPORT_ADS_FAILURE":
      return {
        ...state,
        exportLoading:false,
      }
    case actionTypes.CREATE_POLICY_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_POLICY_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        PolicyReportsList:action.payload,
      };
    case actionTypes.GET_POLICY_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
      
    case actionTypes.GET_POLICY_REPORT_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        filterOptions:action.payload,
      };
    case actionTypes.GET_POLICY_REPORT_FILTER_OPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.GET_POLICY_REPORT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPolicy:action.payload,
      };
    case actionTypes.CLEAR_POLICY_REPORT_BY_ID:
      return {
        ...state,
        selectedPolicy: {},
      };
      
    case actionTypes.GET_ALL_ADS_SUCCESS:
      return {
        ...state,
        loading: false,
        allAds:action.payload,
      };
    case actionTypes.GET_ALL_ADS_FAILURE:
      return {
        ...state,
        allAds: {},
      };
    case actionTypes.GET_POLICY_REPORT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.UPDATE_POLICY_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_POLICY_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.DELETE_POLICY_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_POLICY_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.FILTERS_ACTION_SUCCESS:
      return{
        ...state,
        filters:{
          ...state.filters,
          ...action.payload
        }
      }
      case actionTypes.AD_VIEW_FILTERS_ACTION_SUCCESS:
        return{
          ...state,
          adViewFilters:{
            ...state.adViewFilters,
            ...action.payload
          }
        }
    default:
      return state;
  }
};

export default PolicyReport;
