import {
  Button,
  Card,
  FilledInput,
  Grid,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import variables from "../../Assets/Styles/variables";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/UI/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  BulkExportNetworks,
  getNetworksAction,
  groupArchiveNetworks,
  networksFiltersAction,
} from "../../store/Networks/actions";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import IosShareIcon from "@mui/icons-material/IosShare";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useLocation } from "react-router-dom";

const MySwal = withReactContent(Swal);

function NetworkFilterCard({
  permissions,
  filterData,
  setFilters,
  filters,
  filtersInitialState,
  lastLocationBeforeUnmount,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [exportClicked, setExportClicked] = useState(false);
  const [disableGroupActions, setDisableGroupActions] = useState(true);
  const { selectedNetworks, exportLink } = useSelector(
    (state) => state.Networks
  );

  const handleInput = ({ name, value }) => {
    setFilters((data) => ({ ...data, [name]: value }));
  };

  const handleFilterApply = () => {
    console.log("is this handleFilterApply");
    let params = { ...filterData };
    if (params.archived === "all") delete params.archived;
    else params.archived = JSON.parse(params.archived);
    dispatch(
      getNetworksAction({
        ...params,
        currentPage: 1,
        perPage: 10,
      })
    );
  };

  const handleClear = () => {
    dispatch(networksFiltersAction(filtersInitialState));
    setFilters({ ...filtersInitialState });
    console.log("testing in handleClear");
    dispatch(
      getNetworksAction({
        archived: false,
        currentPage: 1,
        perPage: 10,
      })
    );
  };

  const archiveUnarchiveSelected = (action) => {
    if (
      (action === "archive" || action === "unarchive") &&
      selectedNetworks.length > 0
    ) {
      MySwal.fire({
        title: `Are you sure you want to ${
          action === "unarchive" ? "activate" : action
        } ${
          selectedNetworks.length > 1
            ? `these ${selectedNetworks.length} networks`
            : "this network"
        }?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, ${
          action === "unarchive" ? "activate" : action
        } ${selectedNetworks.length > 1 ? "them" : "it"}!`,
      }).then((result) => {
        if (result.isConfirmed) {
          const params = { ids: selectedNetworks, action: action };
          dispatch(groupArchiveNetworks(params));
        }
      });
    }
  };

  const exportNetworks = () => {
    if (!selectedNetworks.length) {
      dispatch(
        BulkExportNetworks({
          type:
            filters.archived === "all"
              ? "all"
              : filters.archived === "true"
              ? "archived"
              : "unarchived",
        })
      );
    } else {
      dispatch(
        BulkExportNetworks({
          type: "selected",
          ids: selectedNetworks.join(","),
        })
      );
    }
    setExportClicked(true);
  };

  const StyledButtonPrimary = styled(Button)(({ theme }) => ({
    marginRight: "10px",
    backgroundColor: `${variables.primaryColor} !important`,
    color: variables.whiteText,
    "&:disabled": {
      backgroundColor: `${variables.primaryLightColor} !important`,
      color: variables.whiteText,
    },
  }));

  const StyledButtonSecondary = styled(Button)(({ theme }) => ({
    marginRight: "10px",
    backgroundColor: `${variables.secondaryButtonColor} !important`,
    color: variables.whiteText,
    "&:disabled": {
      backgroundColor: `${variables.secondaryLightColor} !important`,
      color: variables.whiteText,
    },
  }));

  // useEffect(() => {
  //   dispatch(
  //     getNetworksAction({
  //       archived: false,
  //       currentPage: 1,
  //       perPage: 50,
  //       ...filters,
  //     })
  //   );
  // }, [dispatch, filters]);

  // useEffect(() => {
  //   if (!lastLocationBeforeUnmount.includes("/networks/edit")) {
  //     handleClear();
  //   }
  // }, []);

  useEffect(() => {
    if (exportClicked && exportLink !== "") {
      window.open(exportLink);
      setExportClicked(false);
    }
  }, [exportLink]);

  useEffect(() => {
    if (selectedNetworks.length > 0) setDisableGroupActions(false);
    else setDisableGroupActions(true);
  }, [selectedNetworks]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let searchQuery = {};

    const addToSearchQuery = (key) => {
      const values = searchParams.getAll(key);
      if (values.length > 0) {
        searchQuery[key] = values[0];
      }
    };
    addToSearchQuery("search");
    addToSearchQuery("archived");
    dispatch(
      networksFiltersAction({
        ...searchQuery,
      })
    );
  }, []);

  return (
    <Card style={{ padding: "20px", borderRadius: "0px" }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <p style={{ fontWeight: "bold", marginBottom: "10px" }}>Filters </p>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <TextField
                fullWidth
                label="Search Domain"
                variant="filled"
                autoComplete="off"
                InputProps={{ readOnly: false, disableUnderline: true }}
                placeholder="Search"
                value={filterData.search}
                onChange={(e) =>
                  handleInput({ name: "search", value: e.target.value })
                }
              />
            </Grid>
            <Grid item sm={4}>
              <Select
                fullWidth
                id="demo-network-filter"
                labelId="demo-network-filter-label"
                variant="filled"
                value={filterData.archived}
                selected={filterData.archived}
                onChange={(e) =>
                  handleInput({ name: "archived", value: e.target.value })
                }
                input={<FilledInput disableUnderline />}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="true">Archived</MenuItem>
                <MenuItem value="false">Active</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "2px" }}>
            <Grid item spacing={2} sm={12}>
              <Button
                size="small"
                style={{
                  marginRight: "10px",
                  backgroundColor: variables.primaryColor,
                  color: variables.whiteText,
                }}
                onClick={handleClear}
              >
                clear
              </Button>
              <SecondaryButton size="small" onClick={handleFilterApply}>
                Apply
              </SecondaryButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={6}
          md={6}
          lg={6}
          style={{
            justifyContent: "end",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Grid
            item
            spacing={2}
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            <StyledButtonPrimary
              size="small"
              disabled={disableGroupActions}
              onClick={(e) => archiveUnarchiveSelected("archive")}
            >
              <ArchiveIcon />
              &nbsp;Archive
            </StyledButtonPrimary>
            <StyledButtonSecondary
              size="small"
              disabled={disableGroupActions}
              onClick={(e) => archiveUnarchiveSelected("unarchive")}
            >
              <UnarchiveIcon />
              &nbsp;Activate
            </StyledButtonSecondary>
            <PrimaryButton size="small" onClick={(e) => exportNetworks()}>
              <IosShareIcon />
              &nbsp;Export
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default NetworkFilterCard;
