import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  alpha,
  Box,
  Checkbox,
  Chip,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Link, useNavigate } from "react-router-dom";
import variables from "../../../Assets/Styles/variables";
import { useDispatch } from "react-redux";
import { getPolicyReportAction } from "../../../store/PolicyReport/actions";
import { formatTimeWithSec } from "../../../utils/timeUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import NoDataMessage from "../../../components/NoDataMessage/NoDataMessage";

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "name",
    disablePadding: false,
    label: "Policy Name",
  },
  {
    id: "date-time",
    disablePadding: false,
    label: "Date/Time",
  },

  {
    id: "networks",
    disablePadding: false,
    label: "Networks Found",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
];
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: variables.backgroundDark }}>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: "bold" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Policies
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton></IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function ThemeTable({ rows, pagination, filters, loading }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [paginate, setPaginate] = useState({
    currentPage: pagination.currentPage - 1 || 0,
    perPage: pagination.perPage || 10,
    totalCount: pagination.totalCount || 0,
    totalPages: Math.ceil(pagination.totalCount / pagination.perPage) || 0,
  });

  useEffect(() => {
    let temp = paginate;
    if (pagination.hasOwnProperty("currentPage")) {
      temp.currentPage = pagination.currentPage - 1;
    }
    if (pagination.hasOwnProperty("perPage")) {
      temp.perPage = pagination.perPage;
    }
    if (pagination.hasOwnProperty("totalCount")) {
      temp.totalCount = pagination.totalCount;
    }
    if (pagination.hasOwnProperty("totalPages")) {
      temp.totalPages = pagination.totalPages;
    }
    setPaginate({ ...temp });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (property === "date-time") {
      const params = getFilters();
      dispatch(
        getPolicyReportAction({
          ...params,
          sortBy: "createdAt",
          sortOrder: isAsc ? "desc" : "asc",
        })
      );
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const getFilters = () => ({
    startDate: filters.startDate,
    endDate: filters.endDate,
    policyName: Array.isArray(filters.policiesName)
      ? filters.policiesName.join(",")
      : filters.policiesName,
    location: Array.isArray(filters.locations)
      ? filters.locations.join("|")
      : filters.locations,
    device: Array.isArray(filters.device)
      ? filters.device.join(",")
      : filters.device,
    network: Array.isArray(filters.networks)
      ? filters.networks.map((obj) => obj.id).join(",")
      : filters.networks,
    reportStatus: filters.status,
  });
  const handleChangePage = (event, newPage) => {
    const params = getFilters();
    const updatedPaginate = {
      ...paginate,
      currentPage: newPage,
    };
    setPaginate(updatedPaginate);
    dispatch(
      getPolicyReportAction({
        ...params,
        currentPage: newPage + 1,
        perPage: paginate.perPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    const params = getFilters();
    const perPage = parseInt(event.target.value, 10);
    const updatedPaginate = {
      ...paginate,
      perPage: perPage,
      currentPage: 0,
      totalPages: Math.ceil(paginate.totalCount / perPage),
    };
    setPaginate(updatedPaginate);
    dispatch(
      getPolicyReportAction({
        ...params,
        perPage: perPage,
        currentPage: 1,
      })
    );
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = 0;
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  if (!rows.length && loading) {
    rows = [{}, {}, {}, {}, {}];
  }
  useEffect(() => {
    const orderByMapping = {
      name: "Policy name",
      "date-time": "start_time",
      networks: "networks",
      status: "status",
    };

    const keyForSorting = orderByMapping[orderBy];

    if (orderBy !== "date-time") {
      rows.sort((a, b) => {
        const valueA = a[keyForSorting];
        const valueB = b[keyForSorting];
        if (order === "asc") {
          if (valueA < valueB) {
            return -1;
          }
          if (valueA > valueB) {
            return 1;
          }
          return 0;
        } else if (order === "desc") {
          if (valueA > valueB) {
            return -1;
          }
          if (valueA < valueB) {
            return 1;
          }
          return 0;
        }
      });
    }
  }, [orderBy, order, rows]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.slice().sort(getComparator(order, orderBy)) */}

              {rows.length > 0 &&
                rows?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        style={{ verticalAlign: "top" }}
                      >
                        {/* <Checkbox
                        onClick={(event) =>
                          handleClick(event, row["Policy name"])
                        }
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      /> */}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        onClick={(event) => {
                          if (event.target.nodeName !== "A")
                            window.open(`/reports/${row.id}`, "_blank");
                        }}
                        style={{ cursor: "pointer", padding: "10px" }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {row["Policy name"] ? (
                            row["Policy name"]
                          ) : (
                            <Skeleton />
                          )}
                        </span>
                        <div style={{ display: "flex" }}>
                          <div>
                            <span
                              style={{
                                color: variables.primaryButtonColor,
                                fontWeight: "bold",
                              }}
                            >
                              Keywords:&nbsp;
                            </span>
                          </div>

                          {row.keyword ? (
                            <div>{row.keyword}</div>
                          ) : (
                            <Skeleton width={300} />
                          )}
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <span
                              style={{
                                color: variables.primaryButtonColor,
                                fontWeight: "bold",
                              }}
                            >
                              Locations:&nbsp;
                            </span>
                          </div>
                          {row.location ? (
                            <div>{row.location}</div>
                          ) : (
                            <Skeleton width={300} />
                          )}
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <span
                              style={{
                                color: variables.primaryButtonColor,
                                fontWeight: "bold",
                              }}
                            >
                              Device:&nbsp;
                            </span>
                          </div>
                          {row.device ? (
                            <div>{row.device}</div>
                          ) : (
                            <Skeleton width={300} />
                          )}
                        </div>
                      </TableCell>

                      <TableCell
                        onClick={(event) => {
                          if (event.target.nodeName !== "A")
                            window.open(`/reports/${row.id}`, "_blank");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div style={{ display: "flex" }}>
                          <div>
                            <span
                              style={{
                                color: variables.primaryButtonColor,
                                fontWeight: "bold",
                                marginRight: "5px",
                              }}
                            >
                              Start Time:
                            </span>
                          </div>
                          <div>
                            {row.start_time ? (
                              formatTimeWithSec(row.start_time)
                            ) : (
                              <Skeleton />
                            )}
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <span
                              style={{
                                color: variables.primaryButtonColor,
                                fontWeight: "bold",
                                marginRight: "5px",
                              }}
                            >
                              End Time:
                            </span>
                          </div>
                          <div>
                            {row.end_time ? (
                              formatTimeWithSec(row.end_time)
                            ) : (
                              <Skeleton />
                            )}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        <ul style={{ listStyleType: "none" }}>
                          {!row.networks && <Skeleton count={4} />}
                          {!row?.networks?.length && <p>No Networks Found</p>}
                          {row?.networks?.map((net) => {
                            return net.isArchived ? (
                              <span></span>
                            ) : (
                              <li
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "blue",
                                  textDecoration: "underline blue",
                                }}
                                onClick={() =>
                                  navigate("/networks/edit/" + net._id)
                                }
                              >
                                {net.name.replace("www.", "")}
                                &nbsp;
                                <OpenInNewRoundedIcon fontSize="3px" />
                              </li>
                            );
                          })}
                        </ul>
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {!row.status && <Skeleton />}
                        {row.status === "completed" && (
                          <Chip
                            label="Completed"
                            style={{
                              backgroundColor: variables.successColor,
                              color: variables.whiteText,
                            }}
                          />
                        )}
                        {row.status === "pending" && (
                          <Chip
                            label="Pending"
                            style={{
                              backgroundColor: variables.warningColor,
                              color: variables.blackText,
                            }}
                          />
                        )}
                        {row.status === "skipped" && (
                          <Chip
                            label="Skipped"
                            style={{
                              backgroundColor: variables.errorColor,
                              color: variables.whiteText,
                            }}
                          />
                        )}
                        {row.status === "running" && <LinearProgress />}
                      </TableCell>
                      {/* <TableCell >
                          <Grid>
                            <IconButton color="primary" component="label" onClick={() => navigate(`/policies/edit/${row.id}`)}><EditIcon style={{ color: variables.secondaryButtonColor }} /></IconButton>
                            <IconButton color="primary" component="label" onClick={() => navigate(`/policies/edit/${row.id}`)}><FileCopyIcon style={{ color: variables.secondaryButtonColor }} /></IconButton>
                            <IconButton color="primary" component="label" ><DeleteIcon style={{ color: 'red' }} /></IconButton>
                            <PrimaryButton size='small'>Run now</PrimaryButton>
                          </Grid>
                        </TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {rows.length < 1 && (
            <NoDataMessage message="Data not available for selected options." />
          )}

          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 300, 500]}
            component="div"
            count={paginate.totalCount}
            rowsPerPage={paginate.perPage}
            page={paginate.currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default ThemeTable;
