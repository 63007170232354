import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  SvgIcon,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

function createData(name, id) {
  return { name, id };
}

let rows = [];

const sx = { height: "100%" };

export default function BasicTable({ data, loading }) {
  const navigate = useNavigate();

  rows = data.map((obj) => createData(obj.name, obj._id));

  if (!rows.length && loading) {
    rows = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  }

  return (
    <Card sx={sx}>
      <CardHeader title="Recent Networks" />
      <CardContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {rows.length ? (
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`networks/edit/${row.id}`)}
                    >
                      {row.name ? row.name : <Skeleton />}
                    </TableCell>
                    <TableCell align="right">
                      {row.calories ? row.calories : <Skeleton />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: ".8rem",
                  padding: "30px 0",
                  width: "100%",
                }}
              >
                No recent networks found
              </div>
            )}
          </Table>
        </TableContainer>
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          endIcon={
            <SvgIcon fontSize="small">
              <ArrowForwardIcon />
            </SvgIcon>
          }
          size="small"
          onClick={() => navigate("/networks")}
        >
          View all
        </Button>
      </CardActions>
    </Card>
  );
}
