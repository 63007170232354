import { Box, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { PrimaryButton } from "../UI/Buttons/Buttons";
import variables from "../../Assets/Styles/variables";
import Joi from "joi";
import "./Onboarding.scss";

function OnboardingPolicyName({ handleInput, handleNext, OnboardingData }) {
  const [errors, setErrors] = useState({});

  const validationSchema = Joi.object({
    title: Joi.string().required().label("Policy Name"),
  });

  const handleFormSubmit = () => {
    const validationResult = validationSchema.validate(
      { title: OnboardingData.title },
      {
        abortEarly: false,
      }
    );

    if (validationResult.error) {
      const errors = {};
      validationResult.error.details.forEach((detail) => {
        errors[detail.path[0]] = detail.message;
      });
      setErrors(errors);
    } else {
      handleNext();
    }
  };

  return (
    <div>
      <Grid
        container
        style={{ marginTop: "10px", textAlign: "center", minWidth: "100%" }}
        flexDirection="column"
        alignItems="center"
        gap={3}
      >
        <Grid item sm={10} className="Question">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: variables.primaryTextColor,
            }}
          >
            <h1>What would you like to call your policy?</h1>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginTop: "30px",
              textAlign: "center",
              color: variables.subTextColor,
            }}
          >
            <h2>Lorem ipsum dolor sit amet consectetur.</h2>
          </Box>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "50px",
            }}
            spacing={2}
          >
            <TextField
              id="filled-basic"
              label="Policy Name"
              variant="filled"
              fullWidth
              value={OnboardingData.title}
              error={!!errors.title}
              helpertext={errors.title}
              onChange={(e) =>
                handleInput({ name: "title", value: e.target.value })
              }
            />
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "20px",
            }}
            spacing={2}
          >
            <PrimaryButton
              style={{ height: "60px", minWidth: "240px" }}
              onClick={handleFormSubmit}
            >
              Next
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default OnboardingPolicyName;
