import * as actionTypes from "./actionTypes";

export const getPolicyReportAction = (params) => ({
  type: actionTypes.GET_POLICY_REPORT,
  params,
});

export const getPolicyReportFiltersAction = (params) => ({
  type: actionTypes.GET_POLICY_REPORT_FILTER_OPTIONS,
  params,
});

export const getAllAdsAction = (params) => ({
  type: actionTypes.GET_ALL_ADS,
  params,
})

export const exportAdsAction = (params) => (
  {
    type: "EXPORT_ADS",
    params: params,
  }
)

export const getPolicyByIdAction = (Id) => ({
  type: actionTypes.GET_POLICY_REPORT_BY_ID,
  Id,
});

export const filtersAction = (payload) => ({
  type: actionTypes.FILTERS_ACTION,
  payload,
});

export const AdViewFiltersAction = (payload) => ({
  type: actionTypes.AD_VIEW_FILTERS_ACTION,
  payload,
});

