import * as actionTypes from "./actionTypes";

export const createUsersAction = (params) => ({
  type: actionTypes.CREATE_USERS,
  params,
});
export const updateUsersAction = (params) => ({
  type: actionTypes.UPDATE_USERS,
  params,
});

export const getUsersAction = (params) => ({
  type: actionTypes.GET_USERS,
  params,
});

export const verifyUsersAction = (token) => ({
  type: actionTypes.VERIFY_USERS,
  token,
});

export const verifyTempTokenAction = (token, navigate) => ({
  type: actionTypes.VERIFY_TEMP_TOKEN,
  token,
  navigate,
});

export const getUsersByIdAction = (Id) => ({
  type: actionTypes.GET_USERS_BY_ID,
  Id,
});
export const deleteUsersByIdAction = (Id) => ({
  type: actionTypes.DELETE_USERS,
  Id,
});
