import React from "react";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import PageHeader from "../../components/UI/PageWrapper/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { getUserProfile } from "../../store/Account/actions";
import HeaderWithBackButton from "../../components/UI/HeaderWithBackButton";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  gridContainer: {
    padding: "20px",
    "& .MuiGrid-root": {
      borderBottom: "1px solid rgba(0,0,0,0.2)",
    },
  },
  boxContainer: {
    padding: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function Profile({ permissions }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Account);

  dispatch(getUserProfile());
  return (
    <PageWrapper>
      <PageHeader header={<HeaderWithBackButton header="Organization" />} />
      <Card>
        <CardHeader title="View Details" />
        <Divider />
        <Box className={classes.boxContainer}>
          <Grid container>
            <Grid item sm={2}>
              <Typography variant="h6" component="h6">
                Name:
              </Typography>
            </Grid>
            <Grid item sm={8}>
              {user && (
                <Typography variant="h6" component="h6">
                  {user.first_name} {user.last_name}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Card>
    </PageWrapper>
  );
}

export default Profile;
