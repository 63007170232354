import React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { Chart } from "./ApexChart";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const useChartOptions = (data) => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: [theme.palette.warning.main, alpha(theme.palette.error.main, 0.25)],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "19px",
      },
    },
    stroke: {
      colors: ["transparent"],
      show: true,
      width: 2,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: data?.map((obj) => obj?.date) || [],
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => (value > 0 ? `${value}` : `${value}`),
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };
};

export const OverviewSales = ({ data, loading }) => {
  const chartSeries = [
    {
      name: "Total Ads",
      data: data.map((obj) => obj.totalAds),
    },
    {
      name: "Total Networks",
      data: data.map((obj) => obj.uniqueNetworks),
    },
  ];

  const sx = { height: "100%" };

  const chartOptions = useChartOptions(data);

  return (
    <Card sx={sx}>
      <CardHeader title="Ads & Networks" />
      <CardContent>
        {loading ? (
          <Skeleton count={18} />
        ) : (
          <Chart
            height={350}
            options={chartOptions}
            series={chartSeries}
            type="bar"
            width="100%"
          />
        )}
      </CardContent>
    </Card>
  );
};

export default OverviewSales;
