export function* CheckPermission(requiredPermission) {
  const permissions = JSON.parse(localStorage.getItem("endPoints"));

  const { url, method } = requiredPermission;

  // Check if any permission object in the permissions array matches the required permission
  const hasPermission = permissions.some(
    (permission) =>
      permission.url.toLowerCase() === method.toLowerCase() &&
      permission.method.toLowerCase() === url.toLowerCase()
  );
  if (!hasPermission) {
    return false;
  }

  return true;
}

export const HasPermission = (id) => {
  const frontendIds = JSON.parse(localStorage?.getItem("frontendIds"));
  if (frontendIds && frontendIds.length > 0 && frontendIds?.includes(id)) {
    return true;
  } else {
    return false;
  }
};
