export const GET_POLICY_REPORT = "GET_POLICY_REPORT";
export const GET_POLICY_REPORT_SUCCESS = "GET_POLICY_REPORT_SUCCESS";
export const GET_POLICY_REPORT_FAILURE = "GET_POLICY_REPORT_FAILURE";

export const GET_POLICY_REPORT_BY_ID = "GET_POLICY_REPORT_BY_ID";
export const GET_POLICY_REPORT_BY_ID_SUCCESS = "GET_POLICY_REPORT_BY_ID_SUCCESS";
export const GET_POLICY_REPORT_BY_ID_FAILURE = "GET_POLICY_REPORT_BY_ID_FAILURE";

export const CREATE_POLICY_REPORT = "CREATE_POLICY_REPORT";
export const CREATE_POLICY_REPORT_SUCCESS = "CREATE_POLICY_REPORT_SUCCESS";
export const CREATE_POLICY_REPORT_FAILURE = "CREATE_POLICY_REPORT_FAILURE";

export const GET_ALL_ADS = "GET_ALL_ADS";
export const GET_ALL_ADS_SUCCESS = "GET_ALL_ADS_SUCCESS";
export const GET_ALL_ADS_FAILURE = "GET_ALL_ADS_FAILURE";

export const GET_POLICY_REPORT_FILTER_OPTIONS = "GET_POLICY_REPORT_FILTER_OPTIONS";
export const GET_POLICY_REPORT_FILTER_OPTIONS_SUCCESS = "GET_POLICY_REPORT_FILTER_OPTIONS_SUCCESS";
export const GET_POLICY_REPORT_FILTER_OPTIONS_FAILURE = "GET_POLICY_REPORT_FILTER_OPTIONS_FAILURE";

export const UPDATE_POLICY_REPORT = "UPDATE_POLICY_REPORT";
export const UPDATE_POLICY_REPORT_SUCCESS = "UPDATE_POLICY_REPORT_SUCCESS";
export const UPDATE_POLICY_REPORT_FAILURE = "UPDATE_POLICY_REPORT_FAILURE";

export const DELETE_POLICY_REPORT = "DELETE_POLICY_REPORT";
export const DELETE_POLICY_REPORT_SUCCESS = "DELETE_POLICY_REPORT_SUCCESS";
export const DELETE_POLICY_REPORT_FAILURE = "DELETE_POLICY_REPORT_FAILURE";

export const FILTERS_ACTION  = "FILTERS_ACTION";
export const FILTERS_ACTION_SUCCESS = "FILTERS_ACTION_SUCCESS";

export const AD_VIEW_FILTERS_ACTION  = "AD_VIEW_FILTERS_ACTION";
export const AD_VIEW_FILTERS_ACTION_SUCCESS = "AD_VIEW_FILTERS_ACTION_SUCCESS";

export const SET_POLICY_REPORT_LOADING = "SET_POLICY_REPORT_LOADING";
export const SET_EXPORT_REPORT_LOADING = "SET_EXPORT_REPORT_LOADING";
export const CLEAR_POLICY_REPORT_BY_ID = "CLEAR_POLICY_REPORT_BY_ID";


