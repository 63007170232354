import React, { useEffect } from "react";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import PageHeader from "../../components/UI/PageWrapper/PageHeader";
import AuditDetailsRowHead from "../../components/Audits/AuditDetailsRowHead";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuditByIdAction } from "../../store/Audits/actions";
import AuditDetailsRowTab from "../../components/Audits/AuditDetailsRowTab";
import HeaderWithBackButton from "../../components/UI/HeaderWithBackButton";

function AuditDetailView() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { selectedAudit } = useSelector((state) => state.Audits);

  useEffect(() => {
    dispatch(getAuditByIdAction(id));
  }, [dispatch, id]);

  return (
    <PageWrapper>
      <PageHeader header={<HeaderWithBackButton header="Audit Details" />} />
      {selectedAudit && <AuditDetailsRowHead audit={selectedAudit} />}
      <div style={{ marginTop: "50px" }}>
        {selectedAudit && <AuditDetailsRowTab audit={selectedAudit} />}
      </div>
    </PageWrapper>
  );
}

export default AuditDetailView;
