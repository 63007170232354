import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import variables from "../../../Assets/Styles/variables";
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../components/UI/Buttons/Buttons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Filters from "./Filters";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountriesAction,
  getLocationAction,
  setLocationEmptyAction,
} from "../../../store/Utilities/actions";

const useStyles = makeStyles((theme) => ({
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  justifyEnd: {
    display: "flex",
    justifyContent: "end",
  },
  justifySpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  mt10: {
    marginTop: "10px",
  },
  mb10: {
    marginBottom: "10px",
  },
  textCenter: {
    textAlign: "center",
  },
  tableBox: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  tableScroll: {
    position: "relative",
    flex: 1,
    border: `1px solid #bababa`,
    borderRadius: `0.25rem`,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.01)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "rgba(0,0,0,.3)",
    },
  },
  chooseArea: {
    width: "100%",
    height: "auto",
    maxHeight: "300px",
    position: "absolute",
    display: "block",
    overflowY: "scroll",
    background: variables.backgroundColor,
    transition: "all 0.2s ease-in-out",
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.01)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "rgba(0,0,0,.3)",
    },
  },
}));

const ModalFooter = ({ tempLocationValue, setLocationVal, handleClose }) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.justifyEnd} ${classes.mt10}`}>
      <SecondaryButton style={{ marginRight: "10px" }} onClick={handleClose}>
        Cancel
      </SecondaryButton>
      <PrimaryButton onClick={setLocationVal}>Done</PrimaryButton>
    </Box>
  );
};

const ModalBody = ({
  title,
  tempLocationValue,
  setTempLocationValue,
  readOnly,
}) => {
  let dispatch = useDispatch();
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState("");
  const [openOptions, setOpenOptions] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [allSelected, setAllSelected] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [countryOptions, setCountryOptions] = useState("");
  const [countryOptionsLoading, setCountryOptionsLoading] = useState(true);
  const [randomNum, setRandomNum] = useState("");
  const [selectedLocationNames, setSelectedLocationNames] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [locationOptionsLoading, setLocationOptionsLoading] = useState(false);
  const [locationFilter, setLocationFilter] = useState(
    "Region,City,Postal+Code"
  );
  const { loading, locations, randomLocations, countries } = useSelector(
    (state) => state.Utilities
  );

  const fetchSelectLocation = (value, locationFilter) => {
    dispatch(
      getLocationAction({
        type: "select",
        search: value,
        filter: locationFilter,
      })
    );
  };

  const fetchRandomLocation = (value, number) => {
    dispatch(
      getLocationAction({ type: "random", code: value, number: number })
    );
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const performLocationSearch = useCallback(
    debounce((keyword, locationFilter) => {
      if (keyword.length > 2) {
        fetchSelectLocation(keyword, locationFilter);
        setOpenOptions(true);
      }
    }),
    []
  );

  useEffect(() => {
    dispatch(getCountriesAction());
  }, []);

  useEffect(() => {
    const selectAllOption = {
      _id: "0",
      name: "select_all",
      canonical_name: "Select All",
    };
    if (locations.length > 0) {
      setLocationOptions([selectAllOption].concat([...locations]));
      allSelectedCheck();
    }
  }, [locations]);

  useEffect(() => {
    setTempLocationValue([...randomLocations]);
  }, [randomLocations]);

  useEffect(() => {
    setCountryOptions(countries);
    setCountryOptionsLoading(false);
  }, [countries]);

  useEffect(() => {
    setCountryOptionsLoading(true);
    const temp = countries.filter((country) =>
      country.toLowerCase().includes(countryCode.toLowerCase())
    );
    setCountryOptions(temp);
    setCountryOptionsLoading(false);
  }, [countryCode]);

  useEffect(() => {
    performLocationSearch(keyword, locationFilter);
  }, [keyword]);

  useEffect(() => {
    setSelectedLocationNames(
      tempLocationValue.map((location) => location.canonical_name)
    );
  }, [tempLocationValue]);

  useEffect(() => {
    if (selectedLocationNames.length > 0) allSelectedCheck();
  }, [selectedLocationNames]);

  const handleUpdateSelectedOptions = (selectedText) => {
    setLocationFilter(selectedText);
    performLocationSearch(keyword, locationFilter);
  };

  const isSelected = (id) => {
    return id === "0"
      ? allSelected
      : tempLocationValue.some((location) => location._id === id);
  };

  const allSelectedCheck = () => {
    const locationsNotSelected = locations.filter((location) => {
      return !selectedLocationNames.includes(location.canonical_name);
    });
    locationsNotSelected.length === 0 && selectedLocationNames.length > 0
      ? setAllSelected(true)
      : setAllSelected(false);
  };

  const handleCheckboxChange = (location) => {
    let temp = [...tempLocationValue];
    if (location.name === "select_all") {
      setLocationOptionsLoading(true);
      if (!allSelected) {
        setAllSelected(true);
        const pushLocations = locations.filter(
          (loc) => !selectedLocationNames.includes(loc.canonical_name)
        );
        temp = temp.concat(pushLocations);
      } else {
        setAllSelected(false);
        locations.map((location) => temp.splice(temp.indexOf(location), 1));
      }
      setLocationOptionsLoading(false);
    } else if (!selectedLocationNames.includes(location.canonical_name)) {
      temp.push(location);
      allSelectedCheck();
    } else {
      const index = selectedLocationNames.indexOf(location.canonical_name);
      if (index > -1) temp.splice(index, 1);
      allSelectedCheck();
    }
    setTempLocationValue(temp);
  };

  const chooseRandom = () => {
    if (countryCode === "" || randomNum === "") {
      setErrorMessage("Please enter keyword and number of choices.");
    } else {
      setErrorMessage("");
      fetchRandomLocation(countryCode.split(" ")[0], randomNum);
    }
  };

  if (title === "Select Location") {
    return (
      <Box
        sx={{ height: "100%", px: 4, py: 0 }}
        className={classes.flexColumn}
        onClick={(e) => {
          if (e.target.nodeName !== "INPUT") setOpenOptions(false);
        }}
      >
        <Grid container sx={{ flexShrink: 0 }}>
          <Grid item sm={12}>
            <Filters
              handleUpdateSelectedOptions={handleUpdateSelectedOptions}
            />
          </Grid>
          <Grid item sm={12} sx={{ position: "relative" }}>
            <TextField
              fullWidth
              value={keyword}
              label="Select Location"
              onInput={(e) => setKeyword(e.target.value)}
              onClick={() => setOpenOptions((prev) => !prev)}
              style={{ transition: "all 0.2s ease-in-out" }}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <KeyboardArrowDownIcon
                    sx={{
                      transition: "all 0.2s ease",
                      transform: openOptions ? "scaleY(1)" : "scaleY(-1)",
                      color: openOptions
                        ? `${variables.primaryButtonColor}`
                        : `${variables.disabledTextColor}`,
                    }}
                  />
                ),
              }}
            />
            <Box
              id="choose-area"
              className={classes.chooseArea}
              sx={{
                transform: openOptions ? "translateY(0)" : "translateY(-10%)",
                zIndex: openOptions ? 9 : -1,
                opacity: openOptions ? 1 : 0,
              }}
            >
              <Table
                sx={{
                  height: `${
                    !loading && locationOptions?.length > 0 ? "auto" : "100%"
                  }`,
                }}
              >
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell className={classes.textCenter}>
                        {" "}
                        <CircularProgress />{" "}
                      </TableCell>
                    </TableRow>
                  ) : locationOptions?.length > 0 ? (
                    locationOptions.map((location) => (
                      <TableRow>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isSelected(location._id)}
                                onChange={() => handleCheckboxChange(location)}
                              />
                            }
                            label={location.canonical_name}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell className={classes.textCenter}>
                        <Typography
                          variant="p"
                          component="p"
                          sx={{ color: variables.disabledTextColor }}
                        >
                          No options
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>

        <Box id="selected-area" className={classes.mt10} sx={{ flex: 1 }}>
          <Box sx={{ height: "100%" }} className={classes.flexColumn}>
            <Box sx={{ flexShrink: 0 }}>
              <Box className={classes.justifySpaceBetween}>
                <Typography
                  variant="p"
                  component="p"
                  className={`${classes.mt10} ${classes.mb10}`}
                >
                  Selected Locations
                </Typography>
                {tempLocationValue.length > 0 ? (
                  <Chip
                    variant="filled"
                    style={{
                      backgroundColor: variables.primaryColor,
                      color: variables.whiteText,
                    }}
                    label={`${tempLocationValue.length} selected`}
                  ></Chip>
                ) : (
                  ""
                )}
              </Box>
            </Box>
            <Box className={classes.tableScroll}>
              <Box className={classes.tableBox}>
                <Table
                  sx={{
                    height: `${
                      tempLocationValue?.length > 0 ? "auto" : "100%"
                    }`,
                  }}
                >
                  <TableBody>
                    {locationOptionsLoading ? (
                      <TableRow>
                        <TableCell className={classes.textCenter}>
                          {" "}
                          <CircularProgress />{" "}
                        </TableCell>
                      </TableRow>
                    ) : tempLocationValue?.length > 0 ? (
                      tempLocationValue.map((location) => (
                        <TableRow>
                          <TableCell className={classes.justifySpaceBetween}>
                            <p>
                              {location.canonical_name.length > 50
                                ? `${location.canonical_name.substring(
                                    0,
                                    47
                                  )}...`
                                : location.canonical_name}
                            </p>
                            <CancelIcon
                              sx={{
                                sursor: "pointer",
                                color: variables.primaryColor,
                              }}
                              onClick={() => handleCheckboxChange(location)}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      ""
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  } else {
    // RANDOM LOCATION
    return (
      <Box
        sx={{ height: "100%", px: 4, py: 0 }}
        className={classes.flexColumn}
        onClick={(e) => {
          if (e.target.nodeName !== "INPUT") setOpenOptions(false);
        }}
      >
        <Grid container sx={{ flexShrink: 0 }}>
          <Grid item sm={7} sx={{ position: "relative", paddingRight: "10px" }}>
            <TextField
              fullWidth
              value={countryCode}
              label="Country Code"
              autoComplete="off"
              onInput={(e) => setCountryCode(e.target.value)}
              onClick={() => setOpenOptions((prev) => !prev)}
              style={{ transition: "all 0.3s ease-in-out" }}
              error={!!errorMessage}
              InputProps={{
                endAdornment: (
                  <KeyboardArrowDownIcon
                    sx={{
                      transition: "all 0.2s ease",
                      transform: openOptions ? "scaleY(1)" : "scaleY(-1)",
                      color: openOptions
                        ? `${variables.primaryButtonColor}`
                        : `${variables.disabledTextColor}`,
                    }}
                  />
                ),
              }}
            />
            <Box
              id="choose-area"
              className={classes.chooseArea}
              sx={{
                width: "97% !important",
                transform: openOptions ? "translateY(0)" : "translateY(-10%)",
                zIndex: openOptions ? 9 : -1,
                opacity: openOptions ? 1 : 0,
              }}
            >
              <Table
                sx={{
                  height: `${
                    !countryOptionsLoading && countryOptions?.length > 0
                      ? "auto"
                      : "100%"
                  }`,
                }}
              >
                <TableBody>
                  {countryOptionsLoading ? (
                    <TableRow>
                      <TableCell className={classes.textCenter}>
                        {" "}
                        <CircularProgress />{" "}
                      </TableCell>
                    </TableRow>
                  ) : countryOptions?.length > 0 ? (
                    countryOptions.map((countryOption) => (
                      <TableRow>
                        <TableCell
                          onClick={() => setCountryCode(countryOption)}
                        >
                          {countryOption}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell className={classes.textCenter}>
                        <Typography
                          variant="p"
                          component="p"
                          sx={{ color: variables.disabledTextColor }}
                        >
                          No options
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Grid>
          <Grid item sm={3}>
            <TextField
              fullWidth
              label="Number"
              sx={{ paddingRight: "10px" }}
              value={randomNum}
              onInput={(e) => setRandomNum(e.target.value)}
              error={!!errorMessage}
            />
          </Grid>
          <Grid item sm={2}>
            <PrimaryButton sx={{ fontSize: "12px" }} onClick={chooseRandom}>
              Choose Random
            </PrimaryButton>
          </Grid>
          <Grid item sm={12}>
            <FormHelperText error={!!errorMessage}>
              {errorMessage}
            </FormHelperText>
          </Grid>
        </Grid>

        <Box id="selected-area" className={classes.mt10} sx={{ flex: 1 }}>
          <Box sx={{ height: "100%" }} className={classes.flexColumn}>
            <Box sx={{ flexShrink: 0 }}>
              <Box className={classes.justifySpaceBetween}>
                <Typography
                  variant="p"
                  component="p"
                  className={`${classes.mt10} ${classes.mb10}`}
                >
                  Selected Locations
                </Typography>
                {tempLocationValue.length > 0 ? (
                  <Chip
                    variant="filled"
                    style={{
                      backgroundColor: variables.primaryColor,
                      color: variables.whiteText,
                    }}
                    label={`${tempLocationValue.length} selected`}
                  ></Chip>
                ) : (
                  ""
                )}
              </Box>
            </Box>
            <Box className={classes.tableScroll}>
              <Box className={classes.tableBox}>
                <Table
                  sx={{
                    height: `${
                      tempLocationValue?.length > 0 ? "auto" : "100%"
                    }`,
                  }}
                >
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell className={classes.textCenter}>
                          {" "}
                          <CircularProgress />{" "}
                        </TableCell>
                      </TableRow>
                    ) : tempLocationValue?.length > 0 ? (
                      tempLocationValue.map((location) => (
                        <TableRow>
                          <TableCell className={classes.justifySpaceBetween}>
                            <p>
                              {location.canonical_name.length > 50
                                ? `${location.canonical_name.substring(
                                    0,
                                    47
                                  )}...`
                                : location.canonical_name}
                            </p>
                            <CancelIcon
                              sx={{
                                sursor: "pointer",
                                color: variables.primaryColor,
                              }}
                              onClick={() => handleCheckboxChange(location)}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          className={classes.textCenter}
                          style={{ color: variables.disabledTextColor }}
                        >
                          {" "}
                          No locations found{" "}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default function RightModal({
  title,
  open,
  setOpen,
  handleInput,
  readOnly,
  locationValue,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const style = {
    position: "fixed",
    right: "0",
    margin: "auto",
    width: "500px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    bgcolor: "background.paper",
    boxShadow: 24,
    transition: "right 0.3s ease-out",
  };

  const [tempLocationValue, setTempLocationValue] = useState(locationValue);

  const handleClose = () => {
    setOpen(false);
    setTempLocationValue([]);
    dispatch(setLocationEmptyAction({ locations: [] }));
  };

  const setLocationVal = () => {
    handleInput({
      name: "location",
      value: [...locationValue, ...tempLocationValue],
    });
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid sx={{ flex: 1 }}>
          <Box className={classes.flexColumn} sx={{ height: "100%" }}>
            <Grid
              id="modal-modal-title"
              className={classes.justifySpaceBetween}
              sx={{ flexShrink: 0, p: 4, paddingBottom: 0 }}
            >
              <Typography
                variant="h6"
                component="h2"
                sx={{ marginBottom: "10px" }}
              >
                {title}
              </Typography>
              <CloseIcon
                color="disabled"
                sx={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Grid>
            <Grid sx={{ flex: 1 }}>
              <ModalBody
                title={title}
                tempLocationValue={tempLocationValue}
                setTempLocationValue={setTempLocationValue}
                readOnly={readOnly}
              />
            </Grid>
          </Box>
        </Grid>
        <Grid sx={{ flexShrink: 0, p: 4, paddingTop: 0 }}>
          <Divider />
          <ModalFooter
            tempLocationValue={tempLocationValue}
            setLocationVal={setLocationVal}
            handleClose={handleClose}
          />
        </Grid>
      </Box>
    </Modal>
  );
}
