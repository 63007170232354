import React from 'react'
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper'
import variables from '../../Assets/Styles/variables'
import { PrimaryButton, SecondaryButton } from '../../components/UI/Buttons/Buttons'
import { Alert, Button } from '@mui/material'
import { Check } from '@mui/icons-material'

function Test() {
    return (
        <PageWrapper>

            <div>
                <h1 style={{ color: variables.primaryTextColor }}> Primary Text Color </h1>
                <h1 style={{ color: variables.primaryTextColor, backgroundColor: "#FFFFFF" }}> Primary Text Color </h1>
                
                <h3 style={{ color: variables.subTextColor }}>Sub text Color </h3>
                <h3 style={{ color: variables.subTextColor, backgroundColor: "#FFFFFF" }}>Sub text Color </h3>
                <PrimaryButton >Primary Button</PrimaryButton>
                <SecondaryButton>Secondary Button</SecondaryButton>

                <Alert severity="error" sx={{ color: '#D00000', backgroundColor: '#D0000030',marginTop:"20px" }} onClose={() => { }} >
                    This is a Error alert — check it out!
                </Alert>
                <Alert  icon={<Check fontSize="inherit" />} sx={{ color: '#0CCE6B', backgroundColor: '#0CCE6B30',marginTop:"20px" }} action={
                    <Button color="inherit" size="small">
                        UNDO
                    </Button>
                }>
                    This is a success alert — check it out!
                </Alert>
                <Alert severity="warning" sx={{ color: '#fde047', backgroundColor: '#fde04730',marginTop:"20px" }}>
                    This is a warning alert — check it out!
                </Alert>

            </div>
        </PageWrapper>
    )
}

export default Test