import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { Chart } from "./ApexChart";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import TabletAndroidIcon from "@mui/icons-material/TabletAndroid";
import PhoneIcon from "@mui/icons-material/Phone";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const useChartOptions = (labels) => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.success.main,
      theme.palette.warning.main,
    ],
    dataLabels: {
      enabled: false,
    },
    labels,
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      fillSeriesColor: false,
    },
  };
};

const iconMap = {
  Desktop: (
    <SvgIcon>
      <PersonalVideoIcon />
    </SvgIcon>
  ),
  Tablet: (
    <SvgIcon>
      <TabletAndroidIcon />
    </SvgIcon>
  ),
  Phone: (
    <SvgIcon>
      <PhoneIcon />
    </SvgIcon>
  ),
};

const precentageFunction = (numbers) => {
  const total = numbers.reduce((sum, num) => sum + num, 0);
  const percentages = numbers.map((num) => ((num / total) * 100).toFixed(2));
  return percentages.map((str) => parseInt(str));
};

const OverviewTraffic = ({ data, loading }) => {
  const chartSeries = precentageFunction([
    data?.Desktop || 0,
    data?.Tablet || 0,
    data?.Mobile || 0,
  ]);

  const labels = ["Desktop", "Tablet", "Phone"];
  const sx = { height: "100%" };

  const chartOptions = useChartOptions(labels);

  return (
    <Card sx={sx}>
      <CardHeader title="Ads Found by Devices" />
      <CardContent>
        {loading ? (
          <Skeleton circle={true} height={310} />
        ) : (
          <Chart
            height={300}
            options={chartOptions}
            series={chartSeries}
            type="donut"
            width="100%"
          />
        )}
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ mt: 2 }}
        >
          {chartSeries.map((item, index) => {
            const label = labels[index];

            return (
              <Box
                key={label}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {iconMap[label]}
                <Typography sx={{ my: 1 }} variant="h6">
                  {label}
                </Typography>
                <Typography color="text.secondary" variant="subtitle2">
                  {item}%
                </Typography>
              </Box>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};
export default OverviewTraffic;
