import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";

function Filters({ handleUpdateSelectedOptions }) {
  const [selectAll, setSelectAll] = useState(true);
  const [region, setRegion] = useState(true);
  const [city, setCity] = useState(true);
  const [country, setCountry] = useState(true);
  const [postalCode, setPostalCode] = useState(true);

  useEffect(() => {
    const selectedOptions = [];
    if (region) selectedOptions.push("Region");
    if (city) selectedOptions.push("City");
    if (country) selectedOptions.push("Country");
    if (postalCode) selectedOptions.push("Postal+Code");

    handleUpdateSelectedOptions(selectedOptions.join(","));
  }, [selectAll, region, city, country, postalCode]);

  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setRegion(checked);
    setCity(checked);
    setCountry(checked);
    setPostalCode(checked);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "region") {
      setRegion(checked);
      setSelectAll(false);
    } else if (name === "city") {
      setCity(checked);
      setSelectAll(false);
    } else if (name === "country") {
      setCountry(checked);
      setSelectAll(false);
    } else if (name === "postal_code") {
      setPostalCode(checked);
      setSelectAll(false);
    }
  };

  return (
    <Grid container sx={{ marginBottom: "10px" }}>
      <FormControlLabel
        sx={{ height: 30 }}
        control={
          <Checkbox
            size="small"
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
        }
        label={
          <Typography component="p" style={{ fontSize: "14px" }}>
            All
          </Typography>
        }
      />
      <FormControlLabel
        sx={{ height: 30 }}
        control={
          <Checkbox
            size="small"
            checked={region}
            onChange={handleCheckboxChange}
            name="region"
          />
        }
        label={
          <Typography component="p" style={{ fontSize: "14px" }}>
            Region
          </Typography>
        }
      />
      <FormControlLabel
        sx={{ height: 30 }}
        control={
          <Checkbox
            size="small"
            checked={city}
            onChange={handleCheckboxChange}
            name="city"
          />
        }
        label={
          <Typography component="p" style={{ fontSize: "14px" }}>
            City
          </Typography>
        }
      />
      <FormControlLabel
        sx={{ height: 30 }}
        control={
          <Checkbox
            size="small"
            checked={country}
            onChange={handleCheckboxChange}
            name="country"
          />
        }
        label={
          <Typography component="p" style={{ fontSize: "14px" }}>
            Country
          </Typography>
        }
      />
      <FormControlLabel
        sx={{ height: 30 }}
        control={
          <Checkbox
            size="small"
            checked={postalCode}
            onChange={handleCheckboxChange}
            name="postal_code"
          />
        }
        label={
          <Typography component="p" style={{ fontSize: "14px" }}>
            Postal Code
          </Typography>
        }
      />
    </Grid>
  );
}

export default Filters;
