import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Divider,
  styled,
  FormControlLabel,
  Switch,
} from "@mui/material";
import PageHeader from "../../components/UI/PageWrapper/PageHeader";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/UI/Buttons/Buttons";
import variables from "../../Assets/Styles/variables";

import HeaderWithBackButton from "../../components/UI/HeaderWithBackButton";
import { useDispatch, useSelector } from "react-redux";
import {
  createBlockedDomainAction,
  deleteBlockedDomainAction,
  getBlockedDomainAction,
  updateBlockedDomainAction,
} from "../../store/Settings/actions";

import DeleteIcon from "@mui/icons-material/Delete";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { HasPermission } from "../../utils/HasPermission";
import NotPermitted from "../../components/UI/NotPermitted";

const CustomTableHead = styled(TableHead)({
  backgroundColor: "#f5f5f5",
  height: "50px",
  color: variables.mainTextColor,
});

const defaultValue = {
  domainUrl: "",
  description: "",
};

function Settings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [domainInfo, setDomainInfo] = useState(defaultValue);
  const [error, setError] = useState("");

  const { BlockedDomainList } = useSelector((state) => state.BlockedDomain);

  const handleDomain = (e) => {
    const { name, value } = e.target;
    setDomainInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));

    if (name === "domainUrl") {
      // Regular expression for URL validation
      const urlRegex = /^(?:(ftp|http|https):\/\/)?(?:www\.)?[^\s"']+$/;

      if (!urlRegex.test(value)) {
        setError("Invalid URL");
      } else {
        setError("");
      }
    }
  };

  const handleSubmit = () => {
    if (domainInfo.domainUrl !== "" && domainInfo.description !== "") {
      const data = {
        ...domainInfo,
        navigate,
      };

      dispatch(createBlockedDomainAction(data));
      setDomainInfo(defaultValue);
      // window.location.reload();
    }
  };
  const MySwal = withReactContent(Swal);
  const handleDelete = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this Domain Url",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          id,
        };
        dispatch(deleteBlockedDomainAction(data));
      }
    });
  };

  const handleSelect = (checked, id) => {
    const data = {
      isBlockedStatus: checked,
      id,
      navigate,
    };
    dispatch(updateBlockedDomainAction(data));
  };

  useEffect(() => {
    if (!!BlockedDomainList) {
      dispatch(getBlockedDomainAction());
    }
  }, [dispatch]);

  if (!HasPermission("crawler_blocked_domain_view")) {
    return <NotPermitted />;
  }

  return (
    <PageWrapper>
      <PageHeader header={<HeaderWithBackButton header="Blocked Domain" />} />
      <Paper>
        <Grid container alignItems="center" style={{ padding: "10px" }}>
          <Grid item>
            <h3>Blocked Domain</h3>
          </Grid>
        </Grid>
        <Divider style={{ marginBottom: "20px" }} />

        <div>
          <Grid
            container
            style={{
              marginBottom: "10px",
              marginLeft: "10px",
            }}
            spacing={2}
          >
            <Grid item sm={4}>
              <TextField
                label="Domain Url"
                // InputProps={{
                //   readOnly: readOnly,
                // }}
                name="domainUrl"
                variant="filled"
                fullWidth
                value={domainInfo.domain}
                onChange={handleDomain}
                error={error !== ""}
                helperText={error}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Description"
                // InputProps={{
                //   readOnly: readOnly,
                // }}
                variant="filled"
                name="description"
                fullWidth
                value={domainInfo.description}
                onChange={handleDomain}
              />
            </Grid>

            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "15px",
                marginLeft: "15px",
              }}
            >
              <div>
                <SecondaryButton
                  style={{ marginRight: "10px" }}
                  onClick={() => setDomainInfo(defaultValue)}
                >
                  Clear
                </SecondaryButton>
                {HasPermission("crawler_blocked_domain_create") && (
                  <PrimaryButton onClick={handleSubmit}>Submit</PrimaryButton>
                )}
              </div>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <CustomTableHead>
                <TableRow style={{ fontSize: "24px" }}>
                  <TableCell style={{ fontSize: "18px", fontWeight: "bold" }}>
                    S.No
                  </TableCell>

                  <TableCell style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Domain Name
                  </TableCell>
                  <TableCell style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Description
                  </TableCell>
                  <TableCell style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Created By
                  </TableCell>
                  <TableCell style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Updated At
                  </TableCell>
                  <TableCell style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Block Status
                  </TableCell>

                  {HasPermission("crawler_blocked_domain_delete") && (
                    <TableCell style={{ fontSize: "18px", fontWeight: "bold" }}>
                      Delete
                    </TableCell>
                  )}
                </TableRow>
              </CustomTableHead>
              <TableBody>
                {BlockedDomainList.length > 0 &&
                  BlockedDomainList?.map((domainList, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {domainList.domainUrl}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {domainList.description}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {domainList.createdBy}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {new Date(domainList.updatedAt).toLocaleString()}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {HasPermission("crawler_blocked_domain_edit") && (
                          <FormControlLabel
                            control={
                              <Switch
                                id="custom-switch"
                                checked={domainList.isBlockedStatus}
                                onChange={(e) =>
                                  handleSelect(e.target.checked, domainList._id)
                                }
                                name={`full`}
                              />
                            }
                          />
                        )}
                      </TableCell>{" "}
                      <TableCell component="th" scope="row">
                        {HasPermission("crawler_blocked_domain_delete") && (
                          <DeleteIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDelete(domainList._id)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Paper>
    </PageWrapper>
  );
}

export default Settings;
