import * as actionTypes from "./actionTypes";

export const getAuditsAction = (params) => ({
    type: actionTypes.GET_AUDITS,
    params,
});

export const getAuditByIdAction = (Id) => ({
  type: actionTypes.GET_AUDIT_BY_ID,
  Id,
});

export const restoreDataByIdAction = (id) => ({
  type: actionTypes.RESTORE_DATA_BY_ID,
  id,
});
