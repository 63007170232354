import React from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { IconButton, Tooltip } from "@mui/material";

function ResetFilters({ resetFilters, addStyle }) {
  return (
    <Tooltip title="Reset Filters" placement="right-start">
      <IconButton size="small" onClick={resetFilters}>
        <RestartAltIcon style={{ fontSize: 20 }} />
      </IconButton>
    </Tooltip>
  );
}

export default ResetFilters;
