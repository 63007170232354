import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  message:"",
  loading: false,
  RolesList: [],
  selectedRole:{}
  
};

const Roles = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ROLES_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.CREATE_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
      
      };
    case actionTypes.CREATE_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.UPDATE_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
      
      };
    case actionTypes.UPDATE_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        RolesList:action.payload,
      };
    case actionTypes.GET_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    
    case actionTypes.GET_ROLES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRole:action.payload,
      };
    case actionTypes.GET_ROLES_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.DELETE_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    
    
    default:
      return state;
  }
};

export default Roles;
