import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  message:"",
  loading: false,
  token: "",
  isAuthenticated: false,
  user:{},
};

const Account = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACCOUNT_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.SET_TOKEN_FROM_LOCALSTORAGE:
      return {
        ...state,
        loading: false,
        error: false,
        token:action.payload,
        isAuthenticated:true,
      };
    case actionTypes.DO_LOGOUT:
      return {
        ...state,
        token:"",
        isAuthenticated:false,
      };
    case actionTypes.DO_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token:action.payload,
        isAuthenticated:true,
      };
    case actionTypes.DO_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.DO_ONBOARDING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DO_ONBOARDING_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.SEND_RESET_PASSWORD_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SEND_RESET_PASSWORD_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload
      };
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
      };
    
    default:
      return state;
  }
};

export default Account;
