import { Box, Grid } from "@mui/material";
import React from "react";
import { PrimaryButton } from "../UI/Buttons/Buttons";
import variables from "../../Assets/Styles/variables";
import "./Onboarding.scss";

function OnboardingFirstPolicy({ handleInput }) {
  return (
    <div>
      <Grid
        container
        style={{ marginTop: "10px", textAlign: "center", minWidth: "100%" }}
        flexDirection="column"
        alignItems="center"
        gap={3}
      >
        <Grid item sm={10} className="Question">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: variables.primaryTextColor,
            }}
          >
            <h1>Do you want to create your first policy?</h1>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginTop: "30px",
              textAlign: "center",
              color: variables.subTextColor,
            }}
          >
            <h2>Lorem ipsum dolor sit amet consectetur.</h2>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "50px",
            }}
            spacing={2}
          >
            <PrimaryButton
              style={{ height: "60px", minWidth: "240px" }}
              onClick={() =>
                handleInput({ name: "firstPolicy", value: true, next: true })
              }
            >
              Yes
            </PrimaryButton>
            <PrimaryButton
              style={{ height: "60px", minWidth: "240px" }}
              onClick={() =>
                handleInput({ name: "firstPolicy", value: false, next: true })
              }
            >
              No
            </PrimaryButton>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default OnboardingFirstPolicy;
