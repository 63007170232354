export const GET_BLOCKED_DOMAIN = "GET_BLOCKED_DOMAIN";
export const GET_BLOCKED_DOMAIN_SUCCESS = "GET_BLOCKED_DOMAIN_SUCCESS";
export const GET_BLOCKED_DOMAIN_FAILURE = "GET_BLOCKED_DOMAIN_FAILURE";

export const GET_BLOCKED_DOMAIN_BY_ID = "GET_BLOCKED_DOMAIN_BY_ID";
export const GET_BLOCKED_DOMAIN_BY_ID_SUCCESS =
  "GET_BLOCKED_DOMAIN_BY_ID_SUCCESS";
export const GET_BLOCKED_DOMAIN_BY_ID_FAILURE =
  "GET_BLOCKED_DOMAIN_BY_ID_FAILURE";

export const CREATE_BLOCKED_DOMAIN = "CREATE_BLOCKED_DOMAIN";
export const CREATE_BLOCKED_DOMAIN_SUCCESS = "CREATE_BLOCKED_DOMAIN_SUCCESS";
export const CREATE_BLOCKED_DOMAIN_FAILURE = "CREATE_BLOCKED_DOMAIN_FAILURE";

export const UPDATE_BLOCKED_DOMAIN = "UPDATE_BLOCKED_DOMAIN";
export const UPDATE_BLOCKED_DOMAIN_SUCCESS = "UPDATE_BLOCKED_DOMAIN_SUCCESS";
export const UPDATE_BLOCKED_DOMAIN_FAILURE = "UPDATE_BLOCKED_DOMAIN_FAILURE";

export const DELETE_BLOCKED_DOMAIN = "DELETE_BLOCKED_DOMAIN";
export const DELETE_BLOCKED_DOMAIN_SUCCESS = "DELETE_BLOCKED_DOMAIN_SUCCESS";
export const DELETE_BLOCKED_DOMAIN_FAILURE = "DELETE_BLOCKED_DOMAIN_FAILURE";

export const SET_BLOCKED_DOMAIN_LOADING = "SET_ROLES_LOADING";
