import React from "react";
import StyledTreeView from "../../pages/PolicieReports/StyledTreeView";

function TreeView() {
  return (
    <div>
      <StyledTreeView />
    </div>
  );
}

export default TreeView;
