import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  message:"",
  loading: false,
  progress: 0,
  NetworksList: {
    totalCount: 10,
    totalPages: 0,
    currentPage: 1,
    perPage: 10,
    data:[],
  },
  pagination: {},
  networkFilter: [],
  emailFilter: [],
  countryFilter: [],
  selectedNetwork: {},
  selectedNetworks: [],
  exportLink: "",
  filters:{
    search: '',
    archived: 'false'
  }
};

const getFilters = (list) => {
    const networkFilterArray = [];
    const emailFilterArray = [];
    const countryFilterArray = [];
    list.map(
        (item, key) => {
          var {_id, name, email, country} = item
          name = name !== null ? name : ""
          email = email !== null ? email : ""
          country = country !== null ? country : ""
          networkFilterArray.push(name)
          emailFilterArray.push(email)
          countryFilterArray.push(country) 
        }
    )
    return [ networkFilterArray, emailFilterArray, countryFilterArray ];
}

const Networks = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NETWORKS_LOADING:
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: "",
            };
        case actionTypes.SET_UPLOAD_PROGRESS:
            return {
                ...state,
                progress: action.payload,
                error: false,
                errorMessage: "",
            };
        case actionTypes.CREATE_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.CREATE_NETWORK_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.errorMessage,
            };
        case actionTypes.UPDATE_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.UPDATE_NETWORK_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.errorMessage,
            };
        case actionTypes.GET_NETWORKS_SUCCESS:
            const { data, success, message, ...rest } = action.payload
            // const [networkFilterArray, emailFilterArray, countryFilterArray] = getFilters(data);
            return {
                ...state,
                loading: false,
                NetworksList: action.payload,
                pagination: rest,
            };
        case actionTypes.GET_NETWORKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.errorMessage,
            };
            
        case actionTypes.GET_NETWORK_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedNetwork:action.payload,
            };
        case actionTypes.GET_NETWORK_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.errorMessage,
            };
        case actionTypes.ARCHIVE_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.ARCHIVE_NETWORK_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.errorMessage,
            };
        case actionTypes.UNARCHIVE_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.UNARCHIVE_NETWORK_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.errorMessage,
            };
        case actionTypes.CHANGE_SELECTED_NETWORKS:
            return {
                ...state,
                loading: false,
                selectedNetworks: action.payload,
            };
        case actionTypes.GROUP_ARCHIVE_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedNetworks: action.payload,
            };
        case actionTypes.GROUP_ARCHIVE_NETWORK_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.errorMessage,
            };
        case actionTypes.BULK_IMPORT_NETWORKS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.BULK_IMPORT_NETWORKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.errorMessage,
            };
        case actionTypes.BULK_EXPORT_NETWORKS_SUCCESS:
            return {
                ...state,
                loading: false,
                exportLink: action?.payload?.link || ''
            };
        case actionTypes.BULK_EXPORT_NETWORKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.errorMessage,
            };
            
    case actionTypes.NETWORKS_FILTERS_ACTION_SUCCESS:
        return{
          ...state,
          filters:{
            ...state.filters,
            ...action.payload
          }
        }
        default:
            return state;
    }
};

export default Networks;