import { Autocomplete, Box, Chip, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { PrimaryButton } from "../UI/Buttons/Buttons";
import variables from "../../Assets/Styles/variables";
import Joi from "joi";

import "./Onboarding.scss";

function OnboardingPolicyKeyword({ OnboardingData, handleInput, handleNext }) {
  const [errors, setErrors] = useState({});

  const validationSchema = Joi.object({
    search_keyword: Joi.array()
      .items(Joi.string().min(1))
      .min(1)
      .required()
      .label("Keyword"),
  });

  const handleFormSubmit = () => {
    const validationResult = validationSchema.validate(
      { search_keyword: OnboardingData.search_keyword },
      {
        abortEarly: false,
      }
    );

    if (validationResult.error) {
      const errors = {};
      validationResult.error.details.forEach((detail) => {
        errors[detail.path[0]] = detail.message;
      });
      setErrors(errors);
    } else {
      handleNext();
    }
  };

  return (
    <div>
      <Grid
        container
        style={{ marginTop: "10px", textAlign: "center" }}
        flexDirection="column"
        alignItems="center"
        gap={3}
      >
        <Grid item sm={10} className="Question">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: variables.primaryTextColor,
            }}
          >
            <h1>Enter keyword that you want to target?</h1>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginTop: "30px",
              textAlign: "center",
              color: variables.subTextColor,
            }}
          >
            <h2>Lorem ipsum dolor sit amet consectetur.</h2>
          </Box>

          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "50px",
            }}
            spacing={2}
          >
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              freeSolo
              fullWidth
              value={OnboardingData.search_keyword}
              onChange={(e, value) => {
                handleInput({ name: "search_keyword", value: value });
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="filled"
                    style={{
                      backgroundColor: variables.primaryColor,
                      color: variables.whiteText,
                    }}
                    label={option}
                    {...getTagProps({ index })}
                    on
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Keyword"
                  variant="filled"
                  placeholder="Keyword"
                  error={!!errors.search_keyword}
                  helpertext={errors.search_keyword}
                />
              )}
            />
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "20px",
            }}
            spacing={2}
          >
            <PrimaryButton
              style={{ height: "60px", minWidth: "240px" }}
              onClick={handleFormSubmit}
            >
              Next
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default OnboardingPolicyKeyword;
