/* eslint-disable array-callback-return */
import {
  Avatar,
  Box,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
  SvgIcon,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import variables from "../../Assets/Styles/variables";
import EditNoteIcon from "@mui/icons-material/EditNote";
import KeywordIcon from "@mui/icons-material/Tag";
import LocationIcon from "@mui/icons-material/MyLocation";
import DeviceIcon from "@mui/icons-material/Devices";

import Google from "../../Assets/Google__G__Logo.svg.png";
import { PrimaryButton, SecondaryButton } from "../UI/Buttons/Buttons";
import { updateNetworkAction } from "../../store/Networks/actions";
import { useDispatch } from "react-redux";
import { getPolicyByIdAction } from "../../store/PolicyReport/actions";
import { useParams } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";

const IconLinkDetail = ({ icon, text }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "6px" }}>
      <SvgIcon component={icon} sx={{ marginRight: "5px", color: "#fff" }} />
      <span
        style={{
          fontWeight: 700,
          fontSize: "0.9rem",
          textAlign: "right",
          wordBreak: "break-word",
          color: "#fff",
        }}
      >
        {text}
      </span>
    </Box>
  );
};

const GoogleResults = ({ ad, createdAt }) => {
  const detailsExists =
    ad.keyword || ad.device || createdAt || ad.location ? true : false;

  return (
    <div style={{ padding: "20px" }}>
      <Grid container spacing={1}>
        <Grid item sm={detailsExists ? 8 : 12} sx={{ display: "flex" }}>
          <Avatar
            style={{ width: "27px", height: "27px", margin: "5px 10px 0 0" }}
            src={Google}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              style={{
                color: variables.primaryTextColor,
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              {ad.title}
            </Typography>
            <Typography
              style={{ fontSize: "16px", color: variables.subTextColor }}
            >
              {ad.displayed_link}
            </Typography>
            <Typography
              style={{ fontSize: "14px", color: variables.subTextColor }}
            >
              {ad.description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

function checkNetworkExists(chain, networks) {
  const result = networks.filter((network) => network?._id === chain.network);
  if (result.length > 0) return [true, result[0]];
  else return [false, {}];
}

function adsSort(reports, uniqueOverallNetwork) {
  let ads = [];
  reports.map((report, reportIndex) => {
    if (report.ads.length > 0) {
      report.ads.map((ad, adIndex) => {
        ad["networks"] = [];
        ad.chain.map((chain) => {
          //if this chain exists in networks array and is not archived
          const checkNetworkExistsResult = checkNetworkExists(
            chain,
            uniqueOverallNetwork
          );
          if (checkNetworkExistsResult[0]) {
            ad["networks"].push(checkNetworkExistsResult[1]);
          }
        });
        ads.push({
          ...ad,
          keyword: report.keyword,
          device: report.device,
          location: report.location,
          reportIndex: reportIndex,
        });
      });
    }
  });

  const adsSort = ads.sort(compare);
  return adsSort;
}

function compare(a, b) {
  const aLength = a.networks.length;
  const bLength = b.networks.length;

  if (aLength > bLength) {
    return -1;
  }
  if (aLength < bLength) {
    return 1;
  }
  return 0;
}

function AdView({ Individual_Policy_Report, collapseController }) {
  const ads = adsSort(
    Individual_Policy_Report.report,
    Individual_Policy_Report.uniqueOverallNetwork
  );

  return (
    <div>
      {Individual_Policy_Report.report &&
        ads.map((ad, adIndex) => (
          <React.Fragment key={ad.reportIndex}>
            <Paper elevation={3} sx={{ borderRadius: "15px" }}>
              <Box
                sx={{
                  marginTop: "20px",
                  borderRadius: "15px",
                  border: `1px solid rgba(0,0,0,0.1)`,
                }}
                key={adIndex}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "10px",
                    backgroundColor: variables.primaryColor,
                    borderRadius: "15px 15px 0px 0px",
                  }}
                >
                  {ad.keyword && (
                    <IconLinkDetail icon={KeywordIcon} text={ad.keyword} />
                  )}
                  {ad.device && (
                    <IconLinkDetail icon={DeviceIcon} text={ad.device} />
                  )}
                  {ad.location && (
                    <IconLinkDetail icon={LocationIcon} text={ad.location} />
                  )}
                </Box>

                <Divider />

                <GoogleResults
                  ad={ad}
                  createdAt={Individual_Policy_Report.policy.createdAt}
                />
                <Divider />
                <Grid
                  style={{ padding: "10px", margin: "0px 20px", width: "98%" }}
                >
                  {ad.chain.map((obj, index2) => (
                    <CustomLink
                      linkDetails={obj}
                      index={index2 + 1}
                      report={Individual_Policy_Report}
                      collapseController={collapseController}
                    />
                  ))}
                </Grid>
              </Box>
            </Paper>
          </React.Fragment>
        ))}
    </div>
  );
}

function CustomLink({ linkDetails, index, report, collapseController }) {
  const [reduceLink, setReduceLink] = useState(false);
  const [copied, setCopied] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [modalName, setModalName] = useState("");
  const [modalLink, setModalLink] = useState("");
  const [modalId, setModalId] = useState("");

  const networks = report.uniqueOverallNetwork;

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const getNetwork = (url) => {
    const urlObject = new URL(url);
    const domain = urlObject.hostname;

    return networks.find((net) => net.domain == domain);
  };

  function NetworkChip({ url }) {
    const network = getNetwork(url);
    return (
      <div
        style={{
          textAlign: "end",
        }}
        onClick={(e) => {
          if (e.target.nodeName === "DIV" || e.target.nodeName === "SPAN") {
            window.open("https://" + network?.domain, "_blank");
          } else {
            setOpenModal(true);
            setModalName(network?.name);
            setModalLink(network?.domain);
            setModalId(network?._id);
          }
        }}
      >
        <span
          style={{
            color: network?.is_archived
              ? variables.disabledTextColor
              : variables.primaryButtonColor,
            verticalAlign: "super",
            fontWeight: network?.is_archived ? "400" : "700",
          }}
        >
          {network?.name?.replace("www.", "")}
        </span>
        <EditNoteIcon
          sx={{
            color: variables.disabledTextColor,
            marginLeft: "3px",
            cursor: "pointer",
          }}
        />
        <InputModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          modalName={modalName}
          modalLink={modalLink}
          modalId={modalId}
          Individual_Policy_Report={report}
        />
      </div>
    );
  }

  return (
    <Grid container fullWidth>
      <Grid item sm={0.5}>
        {!copied ? (
          <div>
            <ContentCopyIcon
              sx={{
                fontSize: "0.9rem",
                color: variables.primaryButtonColor,
                cursor: "pointer",
              }}
              onClick={() => copyText(linkDetails.url)}
            />{" "}
            {index}.
          </div>
        ) : (
          <div>
            <DoneIcon
              sx={{ fontSize: "0.9rem", color: variables.successColor }}
            />{" "}
            {index}.
          </div>
        )}
      </Grid>
      <Grid item sm={11.5}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Collapse
            in={reduceLink || collapseController}
            collapsedSize={20}
            onClick={() => setReduceLink((prev) => !prev)}
            sx={{ display: "inline-block" }}
          >
            <div
              style={{
                height: "auto",
                wordWrap: "wrap",
                wordBreak: "break-all",
                color: variables.subTextColor,
                display: "flex",
              }}
            >
              {linkDetails.url}
            </div>
          </Collapse>

          <NetworkChip url={linkDetails.url} />
        </div>
      </Grid>
    </Grid>
  );
}

function InputModal({
  openModal,
  setOpenModal,
  modalName,
  modalLink,
  modalId,
  Individual_Policy_Report,
}) {
  const dispatch = useDispatch();
  const params = useParams();

  const [name, setName] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");

  const handleUpdate = () => {
    dispatch(
      updateNetworkAction({ id: modalId, domain: websiteLink, name: name })
    );
    dispatch(
      getPolicyByIdAction({
        Id: params?.id,
      })
    );
    handleClose();
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setName(modalName);
    setWebsiteLink(modalLink);
  }, [modalName, modalLink, openModal]);

  return (
    <div style={{ minWidth: "500px" }}>
      <Dialog open={openModal} onClose={handleClose}>
        <DialogTitle>Edit Network</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            sx={{ minWidth: "500px" }}
          />

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Link"
            type="text"
            fullWidth
            variant="standard"
            value={websiteLink}
            sx={{ minWidth: "400px" }}
            onChange={(e) => {
              setWebsiteLink(e.target.value);
            }}
            inputProps={{ readOnly: true }}
          />
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          <PrimaryButton
            onClick={() => {
              handleUpdate();
            }}
          >
            Update
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdView;
