import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../utils/API";

function* GetPolicyReportSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_POLICY_REPORT_LOADING });
    const { data } = yield API.get("/api/policy-report", { params: params });

    if (data.success) {
      yield put({
        type: actionTypes.GET_POLICY_REPORT_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error("Unable Get The Policy Reports");
      yield put({
        type: actionTypes.GET_POLICY_REPORT_FAILURE,
        errorMessage: "Unable Get The Policy Reports",
      });
    }
  } catch (error) {
    toast.error("Unable Get The Policy Reports");
    yield put({
      type: actionTypes.GET_POLICY_REPORT_FAILURE,
      errorMessage: "Unable Get The Policy Reports",
    });
  }
}

function* exportAdsSaga({ params }) {
  const showError = () => {
    toast.error("Something went wrong, please try again.");
  };
  try {
    yield put({ type: "SET_EXPORT_REPORT_LOADING" });
    const { data } = yield API.get("/api/ads/export", { params: params });

    if (data.success) {
      yield put({
        type: "EXPORT_ADS_SUCCESS",
        payload: `${API.defaults.baseURL.slice(0, -1)}${data.data}`,
      });
    } else {
      showError();
      yield put({ type: "EXPORT_ADS_FAILURE" });
    }
  } catch (error) {
    showError();
    yield put({ type: "EXPORT_ADS_FAILURE" });
  }
}

function* filtersSaga({ payload }) {
  try {
    yield put({
      type: actionTypes.FILTERS_ACTION_SUCCESS,
      payload: payload,
    });
  } catch (err) {
    console.log("err", err);
  }
}

function* AdViewFiltersSaga({ payload }) {
  try {
    yield put({
      type: actionTypes.AD_VIEW_FILTERS_ACTION_SUCCESS,
      payload: payload,
    });
  } catch (err) {
    console.log("err", err);
  }
}

function* GetPolicyReportFiltersSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_POLICY_REPORT_LOADING });
    const { data } = yield API.get("/api/policy-report/filters", {
      params: params,
    });

    if (data.success) {
      yield put({
        type: actionTypes.GET_POLICY_REPORT_FILTER_OPTIONS_SUCCESS,
        payload: data.message,
      });
    } else {
      toast.error("Unable Get The Policy Report Filters");
      yield put({
        type: actionTypes.GET_POLICY_REPORT_FILTER_OPTIONS_FAILURE,
        errorMessage: "Unable Get The Policy Report Filters",
      });
    }
  } catch (error) {
    toast.error("Unable Get The Policy Report Filters");
    yield put({
      type: actionTypes.GET_POLICY_REPORT_FILTER_OPTIONS_FAILURE,
      errorMessage: "Unable Get The Policy Report Filters",
    });
  }
}

function* GetPolicyReportByIdSaga({ Id }) {
  try {
    yield put({ type: actionTypes.CLEAR_POLICY_REPORT_BY_ID });
    yield put({ type: actionTypes.SET_POLICY_REPORT_LOADING });
    const { data } = yield API.get(`api/policy-report/${Id.Id}`);

    if (data.success) {
      yield put({
        type: actionTypes.GET_POLICY_REPORT_BY_ID_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error("Unable Get The Policy Reports");
      yield put({
        type: actionTypes.GET_POLICY_REPORT_BY_ID_FAILURE,
        errorMessage: "Unable Get The Policy Reports",
      });
    }
  } catch (error) {
    toast.error("Unable Get The Policy Reports");
    yield put({
      type: actionTypes.GET_POLICY_REPORT_BY_ID_FAILURE,
      errorMessage: "Unable Get The Policy Reports",
    });
  }
}

function* getAllAdsSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_POLICY_REPORT_LOADING });
    const { data } = yield API.get(`api/ads`, { params: params });

    if (data.success) {
      yield put({
        type: actionTypes.GET_ALL_ADS_SUCCESS,
        payload: data,
      });
    } else {
      toast.error("Unable Get The Ads");
      yield put({
        type: actionTypes.GET_ALL_ADS_FAILURE,
        errorMessage: "Unable Get The Ads",
      });
    }
  } catch (error) {
    toast.error("Unable Get The Ads");
    yield put({
      type: actionTypes.GET_ALL_ADS_FAILURE,
      errorMessage: "Unable Get The Ads",
    });
  }
}

function* PolicyReportSaga() {
  yield all([
    takeLatest(actionTypes.GET_POLICY_REPORT, GetPolicyReportSaga),
    takeLatest(actionTypes.GET_POLICY_REPORT_BY_ID, GetPolicyReportByIdSaga),
    takeLatest(
      actionTypes.GET_POLICY_REPORT_FILTER_OPTIONS,
      GetPolicyReportFiltersSaga
    ),
    takeLatest(actionTypes.GET_ALL_ADS, getAllAdsSaga),
    takeLatest("EXPORT_ADS", exportAdsSaga),
    takeLatest(actionTypes.FILTERS_ACTION, filtersSaga),
    takeLatest(actionTypes.AD_VIEW_FILTERS_ACTION, AdViewFiltersSaga),
  ]);
}

export default PolicyReportSaga;
