/* eslint-disable no-unused-vars */
import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import RestoreIcon from "@mui/icons-material/Restore";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { Grid } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import variables from "../../Assets/Styles/variables";
import { Link, useNavigate } from "react-router-dom";
import {
  getAuditsAction,
  restoreDataByIdAction,
} from "../../store/Audits/actions";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import NoDataMessage from "../NoDataMessage/NoDataMessage";
import { HasPermission } from "../../utils/HasPermission";

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    permissions,
  } = props;

  const headCells = [
    {
      id: "name",
      disablePadding: true,
      label: "Module Name",
    },
    {
      id: "policyName",
      disablePadding: true,
      label: "Policy Name",
    },
    {
      id: "action_type",
      disablePadding: false,
      label: "Action Type",
    },
    {
      id: "action_by",
      disablePadding: false,
      label: "Action By",
    },
    {
      id: "date",
      disablePadding: false,
      label: "Date",
    },
  ];

  if (
    HasPermission("crawler_audit_view") ||
    HasPermission("crawler_audit_create") ||
    HasPermission("crawler_audit_edit") ||
    HasPermission("crawler_audit_delete")
  ) {
    headCells.push({
      id: "actions",
      disablePadding: false,
      label: "Actions",
    });
  }
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: variables.backgroundDark }}>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: "bold" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton></IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function AuditsListing({
  rows,
  pagination,
  permissions,
  query,
  setQuery,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [paginate, setPaginate] = useState({
    currentPage: pagination.currentPage - 1 || 0,
    perPage: pagination.perPage || 50,
    totalCount: pagination.totalCount || 0,
    totalPages: Math.ceil(pagination.totalCount / pagination.perPage) || 0,
  });

  const handleRestore = (id) => {
    dispatch(restoreDataByIdAction(id));
  };

  useEffect(() => {
    let temp = paginate;
    if (pagination.hasOwnProperty("currentPage")) {
      temp.currentPage = pagination.currentPage - 1;
    }
    if (pagination.hasOwnProperty("perPage")) {
      temp.perPage = pagination.perPage;
    }
    if (pagination.hasOwnProperty("totalCount")) {
      temp.totalCount = pagination.totalCount;
    }
    if (pagination.hasOwnProperty("totalPages")) {
      temp.totalPages = pagination.totalPages;
    }
    setPaginate({ ...temp });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setQuery({ ...query, currentPage: newPage + 1 });
    dispatch(
      getAuditsAction({
        ...query,
        currentPage: newPage + 1,
        perPage: paginate.perPage,
      })
    );
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setQuery({ ...query, perPage: Number(event.target.value) || 50 });
    dispatch(
      getAuditsAction({
        ...query,
        currentPage: 1,
        perPage: Number(event?.target?.value) || 50,
      })
    );
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TablePagination
          rowsPerPageOptions={[50, 100, 500]}
          component="div"
          count={paginate.totalCount}
          rowsPerPage={paginate.perPage}
          page={paginate.currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              permissions={permissions}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.network}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      style={{ verticalAlign: "top", paddingTop: "15px" }}
                    ></TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      style={{ padding: "10px" }}
                    >
                      <span style={{ fontWeight: "bold" }}>{row.module}</span>
                    </TableCell>
                    <TableCell>{row.policyName}</TableCell>
                    <TableCell>{row.action_type}</TableCell>
                    <TableCell>
                      {row?.userInfo?.user_name || row.action_by.name}
                    </TableCell>
                    <TableCell>{row.date}</TableCell>
                    {(HasPermission("crawler_audit_view") ||
                      HasPermission("crawler_audit_create") ||
                      HasPermission("crawler_audit_edit") ||
                      HasPermission("crawler_audit_delete")) && (
                      <TableCell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Grid>
                          {HasPermission("crawler_audit_view") ? (
                            <Link
                              to={`/audit/view/${row._id}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              <Tooltip title="View">
                                <IconButton color="primary" component="label">
                                  <VisibilityIcon
                                    style={{
                                      color: variables.primaryButtonColor,
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          ) : (
                            <span></span>
                          )}
                        </Grid>
                        <Grid>
                          {row.action_type === "Delete" &&
                            row.policyName !== "N/A" && (
                              <>
                                {HasPermission("crawler_audit_create") ? (
                                  <Tooltip title="Restore Button">
                                    <IconButton
                                      color="primary"
                                      component="label"
                                      onClick={() => handleRestore(row._id)}
                                    >
                                      <RestoreIcon
                                        style={{
                                          color: variables.primaryButtonColor,
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <span></span>
                                )}
                              </>
                            )}
                        </Grid>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {!(rows.length > 0) && (
                <TableRow style={{ height: 53 }}>
                  <TableCell
                    colSpan={6}
                    scope="row"
                    padding="none"
                    sx={{ padding: "15px", color: variables.disabledTextColor }}
                    align="center"
                  >
                    No results found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {rows.length < 1 && (
            <NoDataMessage message="Data not available for selected options." />
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 500]}
          component="div"
          count={paginate.totalCount}
          rowsPerPage={paginate.perPage}
          page={paginate.currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
