import * as actionTypes from "./actionTypes";

export const createNetworkAction = (params) => ({
    type: actionTypes.CREATE_NETWORK,
    params,
});
export const updateNetworkAction = (params) => ({
  type: actionTypes.UPDATE_NETWORK,
  params,
});

export const getNetworksAction = (params) => ({
  type: actionTypes.GET_NETWORKS,
  params,
});

export const getNetworkByIdAction = (Id) => ({
  type: actionTypes.GET_NETWORK_BY_ID,
  Id,
});

export const archiveNetworkByIdAction = (Id) => ({
  type: actionTypes.ARCHIVE_NETWORK,
  Id,
});

export const unarchiveNetworkByIdAction = (Id) => ({
  type: actionTypes.UNARCHIVE_NETWORK,
  Id,
});

export const changeSelectedNetworks = (list) => ({
  type: actionTypes.CHANGE_SELECTED_NETWORKS,
  payload: list,
});

export const groupArchiveNetworks = (params) => ({
  type: actionTypes.GROUP_ARCHIVE_NETWORK,
  params,
});

export const updateUploadProgress = (progress) => ({
  type: actionTypes.SET_UPLOAD_PROGRESS,
  progress
});

export const BulkImportNetworks = (params) => ({
  type: actionTypes.BULK_IMPORT_NETWORKS,
  params,
});

export const BulkExportNetworks = (payload) => ({
  type: actionTypes.BULK_EXPORT_NETWORKS,
  payload
});

export const networksFiltersAction = (payload) => ({
  type: actionTypes.NETWORKS_FILTERS_ACTION,
  payload,
});
