import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import BarChartIcon from "@mui/icons-material/BarChart";
import Chart from "../../components/Dashboard/Chart";
import TrafficChart from "../../components/Dashboard/TrafficChart";
import TableB from "../../components/Dashboard/TableB";
import TableA from "../../components/Dashboard/TableA";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardCardDataAction } from "../../store/Dashboard/actions";
import PolicyIcon from "@mui/icons-material/Policy";
import CellTowerIcon from "@mui/icons-material/CellTower";
import { getPolicyReportAction } from "../../store/PolicyReport/actions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import NotPermitted from "../../components/UI/NotPermitted";
import { HasPermission } from "../../utils/HasPermission";

const Dashboard = () => {
  const dispatch = useDispatch();

  const {
    dashboardData,
    loading,
    dashboardChart,
    dashboardPieChart,
    dashboardNetworks,
    pieChartLoading,
    barChartLoading,
  } = useSelector((state) => state.Dashboard);
  const { PolicyReportsList } = useSelector((state) => state.PolicyReport);

  useEffect(() => {
    dispatch(getDashboardCardDataAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getPolicyReportAction({
        perPage: 10,
        currentPage: 1,
      })
    );
  }, [dispatch]);

  if (!HasPermission("crawler_dashboard_view")) {
    return <NotPermitted />;
  }

  return (
    <PageWrapper>
      <Grid
        style={{ marginTop: "20px" }}
        container
        columnSpacing={{ xs: 2, sm: 2, md: 2 }}
      >
        <Grid item xs={12} md={6} lg={3}>
          <CardDesign
            label={"ACTIVE POLICIES"}
            count={dashboardData.activePolicyCount}
            loading={loading}
            icon={<PolicyIcon />}
            iconColor={"#06b66c"}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <CardDesign
            label={"Paused POLICIES"}
            count={dashboardData.pausedPolicyCount}
            loading={loading}
            icon={<PolicyIcon />}
            iconColor={"rgb(240, 68, 56)"}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <CardDesign
            label={"RUNNING POLICIES"}
            count={dashboardData.runningPolicyCount}
            loading={loading}
            icon={<PolicyIcon />}
            iconColor={"rgb(247, 144, 9)"}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <CardDesign
            label={"TOTAL NETWORKS"}
            count={dashboardData.totalNetworkCount}
            loading={loading}
            icon={<CellTowerIcon />}
            iconColor={"#2b3cbb"}
          />
        </Grid>
      </Grid>

      <Grid
        style={{ marginTop: "20px" }}
        columnSpacing={{ xs: 2, sm: 2, md: 2 }}
        container
      >
        <Grid item xs={12} md={8} lg={8}>
          <Chart loading={barChartLoading} data={dashboardChart} />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <TrafficChart loading={pieChartLoading} data={dashboardPieChart} />
        </Grid>
      </Grid>

      <Grid
        style={{ marginTop: "20px", background: "transparent" }}
        container
        columnSpacing={{ xs: 2, sm: 2, md: 2 }}
      >
        <Grid item xs={12} md={4} lg={4}>
          <TableA data={dashboardNetworks} loading={loading} />
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <TableB data={PolicyReportsList?.report || []} loading={loading} />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

const CardDesign = ({ count, loading, icon, label, iconColor }) => {
  return (
    <Card sx={{ paddingTop: "6px", position: "relative", height: "119px" }}>
      <span
        style={{
          position: "absolute",
          right: "15px",
          top: "30px",
          display: "flex",
          WebkitBoxAlign: "center",
          alignItems: "center",
          WebkitBoxPack: "center",
          justifyContent: "center",
          flexShrink: 0,
          fontFamily:
            'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
          lineHeight: 1,
          borderRadius: "50%",
          overflow: "hidden",
          userSelect: "none",
          color: "rgb(255, 255, 255)",
          fontSize: "14px",
          fontWeight: 600,
          letterSpacing: "0px",
          backgroundColor: iconColor,
          height: "56px",
          width: "56px",
        }}
      >
        {icon}
      </span>
      <CardContent>
        <Typography
          style={{
            margin: "0px",
            fontSize: "0.75rem",
            fontWeight: 600,
            letterSpacing: "0.5px",
            lineHeight: 2.5,
            textTransform: "uppercase",
            fontFamily:
              'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            color: "rgb(108, 115, 127)",
          }}
        >
          {label}
        </Typography>
        <Typography
          style={{
            fontFamily: '"Plus Jakarta Sans", sans-serif',
            fontWeight: 700,
            fontSize: "2rem",
            lineHeight: 1.2,
          }}
        >
          {count}
          {loading && !(typeof count === "number") && (
            <Skeleton width={95} />
          )}{" "}
        </Typography>
      </CardContent>
    </Card>
  );
};

const CardDesign2 = ({ data }) => {
  return (
    <Card sx={{ paddingTop: "6px", height: "150px", position: "relative" }}>
      <span
        style={{
          position: "absolute",
          right: "15px",
          top: "35px",
          display: "flex",
          WebkitBoxAlign: "center",
          alignItems: "center",
          WebkitBoxPack: "center",
          justifyContent: "center",
          flexShrink: 0,
          fontFamily:
            'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
          lineHeight: 1,
          borderRadius: "50%",
          overflow: "hidden",
          userSelect: "none",
          color: "rgb(255, 255, 255)",
          fontSize: "14px",
          fontWeight: 600,
          letterSpacing: "0px",
          backgroundColor: "rgb(240, 68, 56)",
          height: "56px",
          width: "56px",
        }}
      >
        <BarChartIcon />
      </span>
      <CardContent>
        <Typography
          style={{
            margin: "0px",
            fontSize: "0.75rem",
            fontWeight: 600,
            letterSpacing: "0.5px",
            lineHeight: 2.5,
            textTransform: "uppercase",
            fontFamily:
              'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            color: "rgb(108, 115, 127)",
          }}
        >
          BUDGET
        </Typography>
        <Typography
          style={{
            fontFamily: '"Plus Jakarta Sans", sans-serif',
            fontWeight: 700,
            fontSize: "2rem",
            lineHeight: 1.2,
          }}
        >
          $24k
        </Typography>
      </CardContent>
      <CardActions>
        <span
          size="small"
          style={{
            fontSize: "0.75rem",
            fontWeight: 500,
            lineHeight: 1.66,
            fontFamily:
              'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            color: "rgb(108, 115, 127)",
          }}
        >
          <span
            style={{
              fontSize: "0.875rem",
              fontWeight: 400,
              lineHeight: 1.57,
              fontFamily:
                'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
              color: "rgb(16, 185, 129)",
            }}
          >
            +55%{" "}
          </span>{" "}
          Than lask week
        </span>
      </CardActions>
    </Card>
  );
};

export default Dashboard;
