import { useEffect, useState } from "react";
import {
  Box,
  Card,
  MenuItem,
  Pagination,
  Select,
  Typography,
  createTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

let theme = createTheme();

const useStyles = makeStyles(() => ({
  paginationCard: {
    padding: "20px",
    display: "flex",
    justifyContent: "end",
    verticalAlign: "center",
    alignItems: "end",
    "& .MuiBox-root": {
      display: "flex",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      borderRadius: 0,
    },
    "& .Mui-focused": {
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    "& .MuiOutlinedInput-root": {
      margin: `0 ${theme.spacing(3)} 0 ${theme.spacing(1)}`,
      boxShadow: "none",
      "& .MuiOutlinedInput-notchedOutline": {
        border: 0,
      },
    },
    "& .MuiSelect-select.MuiSelect-outlined": {
      padding: `0px ${theme.spacing(3)} 0px ${theme.spacing(1)}`,
    },
    "& .MuiSvgIcon-root": {
      right: "2px",
    },
  },
  paginationElem: {
    "& $li": {
      display: "none",
    },
    "& $li:has(.MuiSvgIcon-root)": {
      display: "inline-block",
    },
  },
}));

export default function AdViewPagination({
  ads,
  pagination,
  filters,
  paginate,
  setPaginate,
}) {
  const classes = useStyles();
  const [startKey, setStartKey] = useState(0);
  const [endKey, setEndKey] = useState(0);
  const rowsPerPageOptions = [500, 600, 700, 800];

  useEffect(() => {
    if (pagination.totalCount > 0) {
      setStartKey(
        pagination.currentPage * pagination.perPage - (pagination.perPage - 1)
      );
      setEndKey(
        pagination.currentPage * pagination.perPage > pagination.totalCount
          ? pagination.totalCount
          : pagination.currentPage * pagination.perPage
      );
    } else {
      setStartKey(0);
      setEndKey(0);
    }
  }, [pagination]);

  const handleChangePage = (event, newPage) => {
    setPaginate({
      currentPage: newPage,
      perPage: pagination.perPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginate({
      currentPage: 1,
      perPage: pagination.perPage,
    });
  };

  return (
    <Card className={classes.paginationCard}>
      <Box>
        <Typography>Rows per page:</Typography>
        <Select
          disableUnderline
          className={classes.perPageOptions}
          value={pagination.perPage}
          onChange={handleChangeRowsPerPage}
        >
          {pagination &&
            rowsPerPageOptions.map((optionValue) => (
              <MenuItem value={optionValue}>{optionValue}</MenuItem>
            ))}
        </Select>
      </Box>
      <Typography>
        {startKey} - {endKey} of {pagination.totalCount}
      </Typography>
      <Pagination
        className={classes.paginationElem}
        count={pagination.totalPages}
        page={pagination.currentPage}
        onChange={handleChangePage}
      />
    </Card>
  );
}
