import { Box, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { PrimaryButton } from "../UI/Buttons/Buttons";
import variables from "../../Assets/Styles/variables";
import "./Onboarding.scss";
import Joi from "joi";

function OnboardingGeneralInfo({ handleInput, handleNext, OnboardingData }) {
  const [errors, setErrors] = useState({});

  const validationSchema = Joi.object({
    first_name: Joi.string().required().label("First Name"),
    last_name: Joi.string().required().label("Last Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.number().min(7).required().label("Phone Number"),
    password: Joi.string().required().label("Password"),
    business_name: Joi.string().required().label("Business Name"),
    address: Joi.string().required().label("Address"),
  });

  const handleFormSubmit = () => {
    const validationResult = validationSchema.validate(
      {
        first_name: OnboardingData.first_name,
        last_name: OnboardingData.last_name,
        email: OnboardingData.email,
        phone: OnboardingData.phone,
        password: OnboardingData.password,
        business_name: OnboardingData.business_name,
        address: OnboardingData.address,
      },
      {
        abortEarly: false,
      }
    );

    if (validationResult.error) {
      const errors = {};
      validationResult.error.details.forEach((detail) => {
        errors[detail.path[0]] = detail.message;
      });
      setErrors(errors);
    } else {
      handleNext();
    }
  };
  return (
    <div>
      <Grid
        container
        style={{ marginTop: "10px", textAlign: "center" }}
        flexDirection="column"
        alignItems="center"
        gap={3}
      >
        <Grid item sm={10} className="Question">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: variables.primaryTextColor,
            }}
          >
            <h1>Let's get you started</h1>
          </Box>
          <Grid container sx={{ width: "100%", marginTop: "50px" }}>
            <Grid sm={6} style={{ padding: "5px" }}>
              <TextField
                id="name"
                label="First Name"
                variant="filled"
                fullWidth
                value={OnboardingData.first_name}
                error={!!errors.first_name}
                helpertext={errors.first_name}
                onChange={(e) =>
                  handleInput({ name: "first_name", value: e.target.value })
                }
              />
            </Grid>
            <Grid sm={6} style={{ padding: "5px" }}>
              <TextField
                id="last_name"
                label="Last Name"
                variant="filled"
                fullWidth
                value={OnboardingData.last_name}
                error={!!errors.last_name}
                helpertext={errors.last_name}
                onChange={(e) =>
                  handleInput({ name: "last_name", value: e.target.value })
                }
              />
            </Grid>
            <Grid sm={6} style={{ padding: "5px" }}>
              <TextField
                id="email"
                variant="filled"
                fullWidth
                label="Email"
                value={OnboardingData.email}
                error={!!errors.email}
                helpertext={errors.email}
                onChange={(e) =>
                  handleInput({ name: "email", value: e.target.value })
                }
              />
            </Grid>
            <Grid sm={6} style={{ padding: "5px" }}>
              <TextField
                id="password"
                label="Password"
                variant="filled"
                type="password"
                fullWidth
                value={OnboardingData.password}
                error={!!errors.password}
                helpertext={errors.password}
                onChange={(e) =>
                  handleInput({ name: "password", value: e.target.value })
                }
              />
            </Grid>
            <Grid sm={6} style={{ padding: "5px" }}>
              <TextField
                id="phone"
                label="Phone Number"
                variant="filled"
                fullWidth
                value={OnboardingData.phone}
                error={!!errors.phone}
                helpertext={errors.phone}
                onChange={(e) =>
                  handleInput({ name: "phone", value: e.target.value })
                }
              />
            </Grid>
            <Grid sm={6} style={{ padding: "5px" }}>
              <TextField
                id="companyName"
                label="Company Name"
                variant="filled"
                fullWidth
                value={OnboardingData.business_name}
                error={!!errors.business_name}
                helpertext={errors.business_name}
                onChange={(e) =>
                  handleInput({ name: "business_name", value: e.target.value })
                }
              />
            </Grid>
            <Grid sm={12} style={{ padding: "5px" }}>
              <TextField
                id="address"
                label="Address"
                variant="filled"
                fullWidth
                value={OnboardingData.address}
                error={!!errors.address}
                helpertext={errors.address}
                onChange={(e) =>
                  handleInput({ name: "address", value: e.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "20px",
            }}
            spacing={2}
          >
            <PrimaryButton
              style={{ height: "60px", minWidth: "240px" }}
              onClick={handleFormSubmit}
            >
              Next
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default OnboardingGeneralInfo;
