import React, { useState, useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";

import { Autocomplete, Checkbox, TextField, Chip } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import variables from "../../../Assets/Styles/variables";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { makeStyles } from "@mui/styles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles({
  chipLabel: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  chip: {
    backgroundColor: variables.secondaryColor,
    color: variables.whiteText,
    display: "flex",
    flexDirection: "row-reverse",
    padding: "12px",
  },
});

const CustomAutoCheckbox = ({
  label,
  items,
  selectAllLabel,
  onChange,
  value,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const transformStringToArrayOfObjects = (str) => {
    if (str === "" || str.length === 0) {
      return [];
    } else if (str[0].title) {
      return str;
    } else {
      if (label === "Locations") {
        return str.split("|").map((item) => ({ title: item.trim() }));
      } else {
        return str.split(",").map((item) => ({ title: item.trim() }));
      }
    }
  };
  const [selectedOptions, setSelectedOptions] = useState(() => {
    if (value.length === 0) {
      return [];
    } else {
      transformStringToArrayOfObjects(value);
    }
  });
  const [filteredOptions, setFilteredOptions] = useState(null);
  const multiSelectRef = useRef(null);

  const handleToggleOption = (selectedOptions) =>
    setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const getOptionLabel = (option) => `${option.title}`;

  const allItemsSelected = () => {
    if (filteredOptions?.length !== items?.length) {
      const excludedFilteredOptions = filteredOptions?.filter(
        (opt) => !selectedOptions.find((selOpt) => selOpt?.title === opt?.title)
      );
      if (excludedFilteredOptions?.length > 0) {
        return false;
      }
      return true;
    }
    const allSelected =
      items?.length > 0 && items?.length === selectedOptions?.length;
    return allSelected;
  };

  const clearSelected = (selOptions) => {
    // filter out the selOptions
    if (selOptions?.length > 0) {
      setSelectedOptions(
        selectedOptions.filter(
          (item) =>
            !selOptions.find((selOption) => selOption?.title === item?.title)
        )
      );
    } else {
      setSelectedOptions([]);
    }
  };

  const handleSelectAll = (isSelected) => {
    let selectedList = [];
    if (
      filteredOptions?.length > 0 &&
      filteredOptions?.length !== items?.length
    ) {
      selectedList = items?.filter((item) =>
        filteredOptions.find(
          (filteredOption) => filteredOption?.title === item?.title
        )
      );
    }
    if (isSelected) {
      if (selectedList?.length > 0) {
        setSelectedOptions([...selectedOptions, ...selectedList]);
      } else {
        setSelectedOptions(items);
      }
    } else {
      clearSelected(selectedList);
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll(!allItemsSelected());
  };

  const handleChange = (event, selectedOptions, reason) => {
    let result = null;
    if (reason === "clear") {
      handleClearOptions();
    } else if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find((option) => option.title === "Select All")) {
        handleToggleSelectAll();
        result = items.filter((el) => el.title !== "Select All");
      } else {
        handleToggleOption(selectedOptions);
        result = selectedOptions;
      }
    }
  };

  const filter = createFilterOptions();

  const debouncedStateValue = debounce((newVal) => {
    if (newVal && !isEqual(newVal, filteredOptions)) {
      setFilteredOptions(newVal);
    }
  }, 1000);

  const updateFilteredOptions = (filtered) => {
    debouncedStateValue(filtered);
  };

  const filterOptions = (options, params) => {
    if (inputValue.length < 0) {
      params.inputValue = { inputValue }.toString() || "";
    }
    const filtered = filter(options, params);
    updateFilteredOptions(filtered);
    const selectAllOption = { title: selectAllLabel, value: "select-all" };
    return [selectAllOption, ...filtered];
  };

  const inputRenderer = (params) => (
    <TextField
      label={label}
      variant="filled"
      {...params}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      InputProps={{ ...params.InputProps, disableUnderline: true }}
    />
  );

  useEffect(() => {
    onChange(selectedOptions, label);
  }, [selectedOptions]);

  return (
    <Autocomplete
      ref={multiSelectRef}
      multiple
      id="tags-filled"
      size="small"
      options={items || []}
      getOptionLabel={getOptionLabel}
      value={value}
      inputValue={inputValue}
      disableCloseOnSelect
      isOptionEqualToValue={(option, val) => option.title === val.title}
      filterOptions={filterOptions}
      renderTags={(value, getTagProps) =>
        Array.isArray(value)
          ? value?.map((option, index) => (
              <Chip
                variant="filled"
                classes={{ root: classes.chip, label: classes.chipLabel }}
                label={option.title}
                {...getTagProps({ index })}
                on
              />
            ))
          : null
      }
      onChange={handleChange}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={
              selectedOptions?.length === items?.length ? true : selected
            }
          />
          {option.title}
        </li>
      )}
      renderInput={inputRenderer}
    />
  );
};

export default CustomAutoCheckbox;
