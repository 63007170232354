import * as actionTypes from "./actionTypes";

export const createPolicyAction = (params) => ({
  type: actionTypes.CREATE_POLICY,
  params,
});

export const runNowPolicy = (params) => ({
  type: actionTypes.RUN_NOW_POLICY,
  params,
});


export const runNowPolicyMultiple = (parameters) => ({
  type: actionTypes.RUN_NOW_POLICY_MULTIPLE,
  parameters,
});

export const pauseResumePolicies = (parameters) => ({
  type: actionTypes.PAUSE_RESUME_POLICIES,
  parameters,
});


export const policyFiltersAction = (payload) => ({
  type: actionTypes.POLICY_FILTERS_ACTION,
  payload,
});

export const skipNextPolicyRun = (parameters) => ({
  type: actionTypes.SKIP_NEXT_POLICY_MULTIPLE,
  parameters,
});

export const skipNextPolicyRunMultiple = (params) => ({
  type: actionTypes.SKIP_NEXT_POLICY_MULTIPLE,
  params,
});

export const updatePolicyAction = (params) => ({
  type: actionTypes.UPDATE_POLICY,
  params,
});

export const getPolicyAction = (params) => ({
  type: actionTypes.GET_POLICY,
  params,
});

export const getPolicyByIdAction = (Id) => ({
  type: actionTypes.GET_POLICY_BY_ID,
  Id,
});

export const deletePolicyIdAction = (params) => ({
  type: actionTypes.DELETE_POLICY,
  params,
});

export const changeSelectedPolicies = (list) => ({
  type: actionTypes.CHANGE_SELECTED_POLICIES,
  payload: list,
});

export const bulkDeletePoliciesAction = (payload) => ({
  type: actionTypes.BULK_DELETE_POLICIES,
  payload,
});

export const getAllPoliciesTitles = () => ({
  type: actionTypes.GET_ALL_POLICIES_TITLES
})

