import {
  Box,
  Chip,
  FilledInput,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { PrimaryButton } from "../UI/Buttons/Buttons";
import variables from "../../Assets/Styles/variables";
import Joi from "joi";
import "./Onboarding.scss";

function OnboardingPolicyDeviceAndLanguage({
  handleInput,
  handleNext,
  OnboardingData,
}) {
  const [errors, setErrors] = useState({});

  const validationSchema = Joi.object({
    device_type: Joi.array()
      .items(Joi.string().min(1))
      .min(1)
      .required()
      .label("device"),
  });

  const handleFormSubmit = () => {
    const validationResult = validationSchema.validate(
      { device_type: OnboardingData.device_type },
      {
        abortEarly: false,
      }
    );

    if (validationResult.error) {
      const errors = {};
      validationResult.error.details.forEach((detail) => {
        errors[detail.path[0]] = detail.message;
      });
      setErrors(errors);
    } else {
      handleNext();
    }
  };
  return (
    <div>
      <Grid
        container
        style={{ marginTop: "10px", textAlign: "center" }}
        flexDirection="column"
        alignItems="center"
        gap={3}
      >
        <Grid item sm={10} className="Question">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: variables.primaryTextColor,
            }}
          >
            <h1>Select device </h1>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginTop: "30px",
              textAlign: "center",
              color: variables.subTextColor,
            }}
          >
            <h2>Lorem ipsum dolor sit amet consectetur.</h2>
          </Box>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "50px",
            }}
            gap={2}
          >
            <FormControl
              fullWidth
              variant="filled"
              sx={{ m: 1, minWidth: 300 }}
            >
              <InputLabel id="demo-simple-select-filled-label">
                Device
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                selected={OnboardingData.device_type}
                value={OnboardingData.device_type}
                onChange={(e, value) => {
                  handleInput({ name: "device_type", value: e.target.value });
                }}
                input={<FilledInput id="select-multiple-chip" label="Chip" />}
                error={!!errors.device_type}
                helpertext={errors.device_type}
                variant="filled"
                fullWidth
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        variant="filled"
                        style={{
                          backgroundColor: variables.primaryColor,
                          color: variables.whiteText,
                        }}
                        key={value}
                        label={value}
                      />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value="Desktop">Desktop</MenuItem>
                <MenuItem value="Tablet">Tablet</MenuItem>
                <MenuItem value="Mobile ">Mobile</MenuItem>
              </Select>
              <FormHelperText error={!!errors.device_type}>
                {errors.device_type}
              </FormHelperText>{" "}
              {/* Display the error message */}
            </FormControl>
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "20px",
            }}
            spacing={2}
          >
            <PrimaryButton
              style={{ height: "60px", minWidth: "240px" }}
              onClick={handleFormSubmit}
            >
              Next
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default OnboardingPolicyDeviceAndLanguage;
