import {
    put,
    all,
    takeLatest
} from "redux-saga/effects";
import {
    toast
} from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../utils/API";

function* createRoleSaga({params}) {
    const {navigate,...rest} = params
    try {
        yield put({type: actionTypes.SET_ROLES_LOADING});
        const {data} = yield API.post("/api/roles/", rest)

        if (data.success) {
            yield put({
                type: actionTypes.CREATE_ROLES_SUCCESS,
                payload: data,
            });
            toast.success("Successfully Create The Roles ");
            navigate('/roles')
        } else {
            toast.error("Unable Create The Roles");
            yield put({
                type: actionTypes.CREATE_ROLES_FAILURE,
                errorMessage: "Unable Create The Roles",
            });
        }
    } catch (error) {
        toast.error("Unable Create The Roles");
        yield put({
            type: actionTypes.CREATE_ROLES_FAILURE,
            errorMessage: "Unable Create The Roles",
        });
    }
}

function* updateRoleSaga({params}) {
    const {id,navigate,...rest} =params
    try {
        yield put({type: actionTypes.SET_ROLES_LOADING});
        const {data} = yield API.put(`/api/roles/${id}/update`, rest)

        if (data.success) {
            yield put({
                type: actionTypes.UPDATE_ROLES_SUCCESS,
                payload: data,
            });
            toast.success("Successfully Updated The Roles ");
            navigate('/roles')
        } else {
            toast.error("Unable Update The Roles");
            yield put({
                type: actionTypes.UPDATE_ROLES_FAILURE,
                errorMessage: "Unable Update The Roles",
            });
        }
    } catch (error) {
        toast.error("Unable Update The Roles");
        yield put({
            type: actionTypes.UPDATE_ROLES_FAILURE,
            errorMessage: "Unable Update The Roles",
        });
    }
}

function* GetRolesSaga({params}) {
    try {
        yield put({type: actionTypes.SET_ROLES_LOADING});
        const {data} = yield API.get("/api/roles", {params:params})

        if (data.success) {
            yield put({
                type: actionTypes.GET_ROLES_SUCCESS,
                payload: data.data,
            });
        } else {
            toast.error("Unable Get The Roles");
            yield put({
                type: actionTypes.GET_ROLES_FAILURE,
                errorMessage: "Unable Get The Roles",
            });
        }
    } catch (error) {
        toast.error("Unable Get The Roles");
        yield put({
            type: actionTypes.GET_ROLES_FAILURE,
            errorMessage: "Unable Get The Roles",
        });
    }
}

function* GetRoleByIdSaga({Id}) {
    try {
        yield put({type: actionTypes.SET_ROLES_LOADING});
        const {data} = yield API.get(`/api/roles/${Id}`, )

        if (data.success) {
            yield put({
                type: actionTypes.GET_ROLES_BY_ID_SUCCESS,
                payload: data.data,
            });
            toast.success("Successfully Get The Roles ");
        } else {
            toast.error("Unable Get The Roles");
            yield put({
                type: actionTypes.GET_ROLES_BY_ID_FAILURE,
                errorMessage: "Unable Get The Roles",
            });
        }
    } catch (error) {
        toast.error("Unable Get The Roles");
        yield put({
            type: actionTypes.GET_ROLES_BY_ID_FAILURE,
            errorMessage: "Unable Get The Roles",
        });
    }
}
function* DeleteRoleByIdSaga({Id}) {
    try {
        yield put({type: actionTypes.SET_ROLES_LOADING});
        const {data} = yield API.delete(`/api/roles/${Id}`, )

        if (data.success) {
            yield put({
                type: actionTypes.DELETE_ROLES_SUCCESS,
                payload: data.data,
            });
            yield put({ type: actionTypes.GET_ROLES});
            toast.success("Successfully Delete The Roles ");
        } else {
            toast.error("Unable Delete The Roles");
            yield put({
                type: actionTypes.DELETE_ROLES_FAILURE,
                errorMessage: "Unable Delete The Roles",
            });
        }
    } catch (error) {
        toast.error("Unable Delete The Roles");
        yield put({
            type: actionTypes.DELETE_ROLES_FAILURE,
            errorMessage: "Unable Delete The Roles",
        });
    }
}




function* RolesSaga() {
    yield all([
        takeLatest(actionTypes.GET_ROLES,GetRolesSaga ),
        takeLatest(actionTypes.GET_ROLES_BY_ID,GetRoleByIdSaga),
        takeLatest(actionTypes.CREATE_ROLES,createRoleSaga ),
        takeLatest(actionTypes.UPDATE_ROLES,updateRoleSaga ),
        takeLatest(actionTypes.DELETE_ROLES,DeleteRoleByIdSaga ),
    ]);
}

export default RolesSaga;