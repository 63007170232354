import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  message: "",
  loading: false,
  dashboardData: {},
  dashboardChart: [],
  dashboardPieChart: {},
  dashboardPolicies: [],
  dashboardNetworks: [],
  pieChartLoading: false,
  barChartLoading: false,
  websiteLoading: 0,
};

const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DASHBOARD_LOADING:
      return {
        ...state,
        loading: true,
        pieChartLoading: true,
        barChartLoading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_DASHBOARD_CARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: action.payload,
      };
    case actionTypes.GET_DASHBOARD_CARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_DASHBOARD_CHART_DATA_SUCCESS:
      return {
        ...state,
        barChartLoading: false,
        dashboardChart: action.payload,
      };
    case actionTypes.GET_DASHBOARD_CHART_DATA_FAILURE:
      return {
        ...state,
        barChartLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_DASHBOARD_PIE_CHART_DATA_SUCCESS:
      return {
        ...state,
        pieChartLoading: false,
        dashboardPieChart: action.payload,
      };
    case actionTypes.GET_DASHBOARD_PIE_CHART_DATA_FAILURE:
      return {
        ...state,
        pieChartLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_DASHBOARD_POLICIES_CHART_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardPolicies: action.payload,
      };
    case actionTypes.GET_DASHBOARD_POLICIES_CHART_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_DASHBOARD_NETWORKS_CHART_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardNetworks: action.payload,
      };
    case actionTypes.GET_DASHBOARD_NETWORKS_CHART_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.SET_WEBSITE_LOADING_SUCCESS:
      return {
        ...state,
        websiteLoading: action.payload
          ? state.websiteLoading + 1
          : state.websiteLoading - 1,
      };
    default:
      return state;
  }
};

export default Dashboard;
