import { all } from "redux-saga/effects";

import UtilitiesSaga from "./Utilities/saga";
import AccountSaga from "./Account/saga";
import PolicySaga from "./Policy/saga";
import PolicyReportSaga from "./PolicyReport/saga";
import RolesSaga from "./Roles/saga";
import UsersSaga from "./Users/saga";
import NetworksSaga from "./Networks/saga";
import AuditsSaga from "./Audits/saga";
import DashboardSaga from "./Dashboard/saga";
import BlockedDomainSaga from "./Settings/saga";

export default function* rootSaga() {
  yield all([
    UtilitiesSaga(),
    AccountSaga(),
    PolicySaga(),
    PolicyReportSaga(),
    RolesSaga(),
    UsersSaga(),
    NetworksSaga(),
    AuditsSaga(),
    DashboardSaga(),
    BlockedDomainSaga(),
  ]);
}
