import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../utils/API";
import axios from "axios";
import detectIncognito from "detectincognitojs";

function* createUserSaga({ params }) {
  const { navigate, ...rest } = params;
  try {
    yield put({ type: actionTypes.SET_USERS_LOADING });
    const { data } = yield API.post("/api/users/add-employee", rest);

    if (data.success) {
      yield put({
        type: actionTypes.CREATE_USERS_SUCCESS,
        payload: data,
      });
      toast.success("Successfully Create The Users ");
      navigate("/users");
    } else {
      toast.error("Unable Create The Users");
      yield put({
        type: actionTypes.CREATE_USERS_FAILURE,
        errorMessage: "Unable Create The Users",
      });
    }
  } catch (error) {
    toast.error("Unable Create The Users");
    yield put({
      type: actionTypes.CREATE_USERS_FAILURE,
      errorMessage: "Unable Create The Users",
    });
  }
}

function* updateUserSaga({ params }) {
  const { _id, navigate, ...rest } = params;
  try {
    yield put({ type: actionTypes.SET_USERS_LOADING });
    const { data } = yield API.put(`/api/users/${_id}/update`, rest);

    if (data.success) {
      yield put({
        type: actionTypes.UPDATE_USERS_SUCCESS,
        payload: data,
      });
      toast.success("Successfully updated the user.");
      navigate("/users");
    } else {
      toast.error("Unable to update the user.");
      yield put({
        type: actionTypes.UPDATE_USERS_FAILURE,
        errorMessage: "Unable Update The Users",
      });
    }
  } catch (error) {
    toast.error("Unable to update the user.");
    yield put({
      type: actionTypes.UPDATE_USERS_FAILURE,
      errorMessage: "Unable to update the user.",
    });
  }
}

function* GetUsersSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_USERS_LOADING });
    const { data } = yield API.get("/api/users", { params: params });

    if (data.success) {
      yield put({
        type: actionTypes.GET_USERS_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error("Unable Get The Users");
      yield put({
        type: actionTypes.GET_USERS_FAILURE,
        errorMessage: "Unable Get The Users",
      });
    }
  } catch (error) {
    toast.error("Unable Get The Users");
    yield put({
      type: actionTypes.GET_USERS_FAILURE,
      errorMessage: "Unable Get The Users",
    });
  }
}

function* VerifyUsersSaga({ token }) {
  try {
    yield put({ type: actionTypes.SET_USERS_LOADING });
    const { data } = yield API.get(
      `/api/users/verify-permissions?cpToken=${token}`
    );

    const { permission } = data.data;

    const finalPermisson = permission?.map((perm) => {
      const moduleName = perm.module_name[0];
      const actions = perm.actions.map((action) => action.name);
      return { moduleName, actions };
    });

    const allFrontendIds = permission.flatMap((module) =>
      module.actions.flatMap((action) => action.frontend_ids)
    );

    localStorage.setItem("frontendIds", JSON.stringify(allFrontendIds));

    const endPoints = permission.flatMap((module) =>
      module.actions.flatMap((action) => action.endpoints)
    );

    localStorage.setItem("endPoints", JSON.stringify(endPoints));

    if (data.success) {
      yield put({
        type: actionTypes.VERIFY_USERS_SUCCESS,
        payload: {
          ...data.data,
          permission: finalPermisson,
          frontendIds: allFrontendIds,
        },
      });
    } else {
      // toast.error("User not permitted");
      yield put({
        type: actionTypes.VERIFY_USERS_FAILURE,
        errorMessage: "Unable verify The User",
      });
    }
  } catch (error) {
    // toast.error("User not permitted");
    yield put({
      type: actionTypes.VERIFY_USERS_FAILURE,
      errorMessage: "Unable verify The User",
    });
  }
}
function* verifyTempTokenSaga({ token, navigate }) {
  try {
    yield put({ type: actionTypes.SET_USERS_LOADING });
    const result = yield detectIncognito();

    const { data } = yield API.post("/api/cp-token/verify-temp-token", {
      temp_token: token,
      isPrivate: result?.isPrivate,
    });

    if (data.success) {
      yield put({
        type: actionTypes.VERIFY_TEMP_TOKEN_SUCCESS,
        payload: data.data,
      });
      toast.success("Login successful!");
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("cpToken", token);
      navigate("/");
    } else {
      yield put({
        type: actionTypes.VERIFY_TEMP_TOKEN_FAILURE,
      });
      toast.error("Login failed please try again.");
      navigate("/login");
    }
  } catch {
    yield put({
      type: actionTypes.VERIFY_TEMP_TOKEN_FAILURE,
    });
    toast.error("Login failed please try again.");
    navigate("/login");
  }
}

function* GetUserByIdSaga({ Id }) {
  try {
    yield put({ type: actionTypes.SET_USERS_LOADING });
    const { data } = yield API.get(`/api/users/${Id}`);

    if (data.success) {
      yield put({
        type: actionTypes.GET_USERS_BY_ID_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error("Unable Get The Users");
      yield put({
        type: actionTypes.GET_USERS_BY_ID_FAILURE,
        errorMessage: "Unable Get The Users",
      });
    }
  } catch (error) {
    toast.error("Unable Get The Users");
    yield put({
      type: actionTypes.GET_USERS_BY_ID_FAILURE,
      errorMessage: "Unable Get The Users",
    });
  }
}

function* DeleteUserByIdSaga({ Id }) {
  try {
    yield put({ type: actionTypes.SET_USERS_LOADING });
    const { data } = yield API.delete(`/api/users/${Id}`);

    if (data.success) {
      yield put({
        type: actionTypes.DELETE_USERS_SUCCESS,
        payload: data.data,
      });
      yield put({ type: actionTypes.GET_USERS });
      toast.success("Successfully Delete The Users ");
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.DELETE_USERS_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (error) {
    toast.error("Unable Delete The Users");
    yield put({
      type: actionTypes.DELETE_USERS_FAILURE,
      errorMessage: "Unable Delete The Users",
    });
  }
}

function* UsersSaga() {
  yield all([
    takeLatest(actionTypes.GET_USERS, GetUsersSaga),
    takeLatest(actionTypes.VERIFY_USERS, VerifyUsersSaga),
    takeLatest(actionTypes.GET_USERS_BY_ID, GetUserByIdSaga),
    takeLatest(actionTypes.CREATE_USERS, createUserSaga),
    takeLatest(actionTypes.UPDATE_USERS, updateUserSaga),
    takeLatest(actionTypes.DELETE_USERS, DeleteUserByIdSaga),
    takeLatest(actionTypes.VERIFY_TEMP_TOKEN, verifyTempTokenSaga),
  ]);
}

export default UsersSaga;
