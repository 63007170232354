export const GET_LOCATIONS = "GET_LOCATIONS";

export const GET_SELECT_LOCATIONS_SUCCESS = "GET_SELECT_LOCATIONS_SUCCESS";
export const GET_SELECT_LOCATIONS_FAILURE = "GET_SELECT_LOCATIONS_FAILURE";

export const GET_RANDOM_LOCATIONS_SUCCESS = "GET_RANDOM_LOCATIONS_SUCCESS";
export const GET_RANDOM_LOCATIONS_FAILURE = "GET_RANDOM_LOCATIONS_FAILURE";

export const SET_UTILITIES_LOADING = "SET_UTILITIES_LOADING";

export const SET_LOCATIONS_EMPTY = "SET_LOCATIONS_EMPTY";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

