import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Divider,
  styled,
  FormControlLabel,
  Switch,
} from "@mui/material";
import PageHeader from "../../components/UI/PageWrapper/PageHeader";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/UI/Buttons/Buttons";
import variables from "../../Assets/Styles/variables";
import { useDispatch, useSelector } from "react-redux";
import {
  createRolesAction,
  getRolesByIdAction,
  updateRolesAction,
} from "../../store/Roles/actions";
import HeaderWithBackButton from "../../components/UI/HeaderWithBackButton";
import { HasPermission } from "../../utils/HasPermission";
import NotPermitted from "../../components/UI/NotPermitted";

const CustomTableHead = styled(TableHead)({
  backgroundColor: "#f5f5f5",
  height: "50px",
  color: variables.mainTextColor,
});

const operation = ["view", "create", "update", "delete"];

const permissions = {
  dashboard: [],
  policies: [],
  "policy reports": [],
  users: [],
  audit: [],
  networks: [],
  roles: [],
  "ad view": [],
};

function Permissions() {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedRole } = useSelector((state) => state.Roles);

  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [roles, setRoles] = useState({ title: "", description: "" });
  const [readOnly, setReadOnly] = useState(false);

  const handleSelect = (key, type, value) => {
    let fullPermissions = {};

    if (permissions.hasOwnProperty(key) && permissions[key].length > 0) {
      if (type === "full") {
        permissions[key].forEach((eachKey) => {
          operation.forEach((item) => {
            const keyName = eachKey.concat(`_${item}`);
            fullPermissions[keyName] = value;
          });
        });
      } else {
        permissions[key].forEach((eachKey) => {
          const keyName = eachKey.concat(`_${type}`);
          fullPermissions[keyName] = value;
        });
      }
    } else {
      if (type === "full") {
        operation.forEach((item) => {
          const keyName = key.concat(`_${item}`);
          fullPermissions[keyName] = value;
        });
      } else {
        const keyName = key.concat(`_${type}`);
        fullPermissions[keyName] = value;
      }
    }

    setSelectedPermissions((prevPermissions) => ({
      ...prevPermissions,
      ...fullPermissions,
    }));
  };

  const checkSelectedPermissions = (key, type) => {
    if (permissions.hasOwnProperty(key) && permissions[key].length > 0) {
      let allSelected = true;
      if (type === "full") {
        permissions[key].forEach((eachKey) => {
          operation.forEach((item) => {
            const permissionKey = eachKey.concat(`_${item}`);
            if (!selectedPermissions[permissionKey]) {
              allSelected = false;
            }
          });
        });
      } else {
        permissions[key].forEach((eachKey) => {
          const permissionKey = eachKey.concat(`_${type}`);
          if (!selectedPermissions[permissionKey]) {
            allSelected = false;
          }
        });
      }
      return allSelected;
    } else {
      if (
        type === "full" &&
        selectedPermissions[key.concat("_view")] &&
        selectedPermissions[key.concat("_create")] &&
        selectedPermissions[key.concat("_update")] &&
        selectedPermissions[key.concat("_delete")]
      ) {
        return true;
      } else if (
        type !== "full" &&
        selectedPermissions[key.concat(`_${type}`)]
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleRoles = (e) => {
    setRoles((prevRoles) => ({
      ...prevRoles,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    function convertToObject(obj) {
      const convertedObj = {};

      // Define the mapping of original properties to the new format
      const mapping = {
        dashboard: "dashboard",
        policies: "policies",
        "policy reports": "policy reports",
        users: "users",
        networks: "networks",
        audit: "audit",
        roles: "roles",
        "ad view": "ad view",
      };

      // Iterate over each property in the original object
      for (const prop in obj) {
        // Extract the action and feature name from the property
        const [action, feature] = prop.split("_");

        // Check if the action and feature are valid and have a corresponding mapping
        if (obj[prop] && action && feature && mapping[action]) {
          const key = mapping[action];

          // Create the array for the feature if it doesn't exist yet
          if (!convertedObj[key]) {
            convertedObj[key] = [];
          }

          // Add the action to the corresponding feature array
          convertedObj[key].push(feature);
        }
      }
      return convertedObj;
    }

    const data = {
      ...roles,
      permissions: convertToObject(selectedPermissions),
      id,
      navigate,
    };

    if (id) {
      dispatch(updateRolesAction(data));
    } else {
      dispatch(createRolesAction(data));
    }
  };

  useEffect(() => {
    if (location.pathname.includes("view")) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    return () => {
      setReadOnly(false);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (id) {
      dispatch(getRolesByIdAction(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    let { _id, title, description, permissions } = selectedRole;
    if (_id) {
      setRoles({ title, description });
    }

    if (_id && permissions) {
      let array = Object.keys(permissions);
      let ans = [];
      for (let x of array) {
        for (let y of permissions[x]) {
          ans.push(x + "_" + y);
        }
      }
      permissions = ans;

      setSelectedPermissions(
        permissions.reduce((prevPermissions, permission) => {
          return { ...prevPermissions, [permission]: true };
        }, {})
      );
    }
    return () => {
      setRoles({});
      setSelectedPermissions([]);
    };
  }, [selectedRole]);

  if (!HasPermission("crawler_roles_view")) {
    return <NotPermitted />;
  }

  return (
    <PageWrapper>
      <PageHeader
        header={<HeaderWithBackButton header="Roles and Permissions" />}
      />
      <Paper>
        <Grid container alignItems="center" style={{ padding: "10px" }}>
          <Grid item>
            <h3>{!id ? "Create A Role" : "Edit A Role"}</h3>
          </Grid>
        </Grid>
        <Divider style={{ marginBottom: "20px" }} />

        <div>
          <Grid
            container
            style={{ marginBottom: "10px", marginLeft: "10px" }}
            spacing={2}
          >
            <Grid item sm={4}>
              <TextField
                label="Group"
                InputProps={{
                  readOnly: readOnly,
                }}
                name="title"
                variant="filled"
                fullWidth
                value={roles.title}
                onChange={handleRoles}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Description"
                InputProps={{
                  readOnly: readOnly,
                }}
                variant="filled"
                name="description"
                fullWidth
                value={roles.description}
                onChange={handleRoles}
              />
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <CustomTableHead>
                <TableRow style={{ fontSize: "24px" }}>
                  <TableCell style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Modules
                  </TableCell>
                  <TableCell style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Full
                  </TableCell>
                  <TableCell style={{ fontSize: "18px", fontWeight: "bold" }}>
                    View
                  </TableCell>
                  <TableCell style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Create
                  </TableCell>
                  <TableCell style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Update
                  </TableCell>
                  <TableCell style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Delete
                  </TableCell>
                </TableRow>
              </CustomTableHead>
              <TableBody>
                {Object.keys(permissions).map((key, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {key.split("_").join(" ")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Switch
                            id="custom-switch"
                            checked={checkSelectedPermissions(key, "full")}
                            onChange={(e) =>
                              !readOnly &&
                              handleSelect(key, "full", e.target.checked)
                            }
                            name={`${key}_full`}
                          />
                        }
                        label={`${key} full`}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Switch
                            id="custom-switch"
                            checked={checkSelectedPermissions(key, "view")}
                            onChange={(e) =>
                              !readOnly &&
                              handleSelect(key, "view", e.target.checked)
                            }
                            name={`${key}_view`}
                          />
                        }
                        label={`${key} view`}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Switch
                            id="custom-switch"
                            checked={checkSelectedPermissions(key, "create")}
                            onChange={(e) =>
                              !readOnly &&
                              handleSelect(key, "create", e.target.checked)
                            }
                            name={`${key}_create`}
                          />
                        }
                        label={`${key} create`}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Switch
                            id="custom-switch"
                            checked={checkSelectedPermissions(key, "update")}
                            onChange={(e) =>
                              !readOnly &&
                              handleSelect(key, "update", e.target.checked)
                            }
                            name={`${key}_update`}
                          />
                        }
                        label={`${key} update`}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Switch
                            id="custom-switch"
                            checked={checkSelectedPermissions(key, "delete")}
                            onChange={(e) =>
                              !readOnly &&
                              handleSelect(key, "delete", e.target.checked)
                            }
                            name={`${key}_delete`}
                          />
                        }
                        label={`${key} delete`}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: "10px",
            }}
          >
            <SecondaryButton
              style={{ marginRight: "10px" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </SecondaryButton>
            {!readOnly && (
              <PrimaryButton
                onClick={handleSubmit}
                disabled={!roles.title || !roles.description}
              >
                Save
              </PrimaryButton>
            )}
          </div>
        </div>
      </Paper>
    </PageWrapper>
  );
}

export default Permissions;
