import React, { useEffect } from "react";
import { LinearProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import UnauthPageWrapper from "../../components/UI/PageWrapper/UnauthPageWrapper";
import { verifyTempTokenAction } from "../../store/Users/actions";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      localStorage.removeItem("token");
      dispatch(verifyTempTokenAction(token, navigate));
    }
  }, [token]);

  return (
    <UnauthPageWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "300px" }}>
          <LinearProgress color="error" />
        </div>
      </div>
    </UnauthPageWrapper>
  );
}

export default Login;
