import * as actionTypes from "./actionTypes";

export const getLocationAction = (params) => ({
  type: actionTypes.GET_LOCATIONS,
  params,
});

export const setLocationEmptyAction = () => ({
  type: actionTypes.SET_LOCATIONS_EMPTY
});

export const getCountriesAction = () => ({
  type: actionTypes.GET_COUNTRIES
});
