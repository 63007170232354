import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../utils/API";

function* createBlockedDomainSaga({ params }) {
  const { navigate, ...rest } = params;
  try {
    yield put({ type: actionTypes.SET_BLOCKED_DOMAIN_LOADING });

    const { data } = yield API.post("/api/blocked-domain", rest);

    if (data.success) {
      yield put({
        type: actionTypes.CREATE_BLOCKED_DOMAIN_SUCCESS,
        payload: data,
      });
      toast.success("Successfully Create The Blocked Domain");
      window.location.reload();
      navigate("/blocked-domain");
    } else {
      toast.error("Unable Create The Blocked Domain");
      yield put({
        type: actionTypes.CREATE_BLOCKED_DOMAIN_FAILURE,
        errorMessage: "Unable Create The Blocked Domain",
      });
    }
  } catch (error) {
    toast.error("Unable Create The Blocked Domain");
    yield put({
      type: actionTypes.CREATE_BLOCKED_DOMAIN_FAILURE,
      errorMessage: "Unable Create The Blocked Domain",
    });
  }
}

function* GetBlockedDomainSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_BLOCKED_DOMAIN_LOADING });
    const { data } = yield API.get("/api/blocked-domain");

    if (data.success) {
      yield put({
        type: actionTypes.GET_BLOCKED_DOMAIN_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error("Unable Get The Blocked Domain");
      yield put({
        type: actionTypes.GET_BLOCKED_DOMAIN_FAILURE,
        errorMessage: "Unable Get The Blocked Domain",
      });
    }
  } catch (error) {
    toast.error("Unable Get The Blocked Domain");
    yield put({
      type: actionTypes.GET_BLOCKED_DOMAIN_FAILURE,
      errorMessage: "Unable Get The Blocked Domain",
    });
  }
}

function* updateBlockedDomainSaga({ params }) {
  const { id, navigate, ...rest } = params;
  try {
    yield put({ type: actionTypes.SET_BLOCKED_DOMAIN_LOADING });
    const { data } = yield API.patch(`/api/blocked-domain/${id}/update`, rest);

    if (data.success) {
      yield put({ type: actionTypes.GET_BLOCKED_DOMAIN });
      toast.success("Successfully Updated The Roles ");

      // navigate("/settings");
    } else {
      toast.error("Unable Update The Roles");
      yield put({
        type: actionTypes.UPDATE_BLOCKED_DOMAIN_FAILURE,
        errorMessage: "Unable Update The Roles",
      });
    }
  } catch (error) {
    toast.error("Unable Update The Roles");
    yield put({
      type: actionTypes.UPDATE_BLOCKED_DOMAIN_FAILURE,
      errorMessage: "Unable Update The Roles",
    });
  }
}

function* DeleteBlockedDomainByIdSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_BLOCKED_DOMAIN_LOADING });
    const { data } = yield API.delete(`/api/blocked-domain/${params.id}`);

    if (data.success) {
      yield put({
        type: actionTypes.DELETE_BLOCKED_DOMAIN_SUCCESS,
        payload: data.data,
      });
      yield put({ type: actionTypes.GET_BLOCKED_DOMAIN });
      toast.success("Successfully Delete The Roles ");
    } else {
      toast.error("Unable Delete The Roles");
      yield put({
        type: actionTypes.DELETE_BLOCKED_DOMAIN_FAILURE,
        errorMessage: "Unable Delete The Roles",
      });
    }
  } catch (error) {
    toast.error("Unable Delete The Roles");
    yield put({
      type: actionTypes.DELETE_BLOCKED_DOMAIN_FAILURE,
      errorMessage: "Unable Delete The Roles",
    });
  }
}

function* BlockedDomainSaga() {
  yield all([
    takeLatest(actionTypes.CREATE_BLOCKED_DOMAIN, createBlockedDomainSaga),
    takeLatest(actionTypes.GET_BLOCKED_DOMAIN, GetBlockedDomainSaga),
    takeLatest(actionTypes.UPDATE_BLOCKED_DOMAIN, updateBlockedDomainSaga),
    takeLatest(actionTypes.DELETE_BLOCKED_DOMAIN, DeleteBlockedDomainByIdSaga),
  ]);
}

export default BlockedDomainSaga;
