module.exports = {
  backgroundColor: "#f4f8f9",
  backgroundDark: "#D4D8D9",
  backgroundDeleted: "rgba(214,64,69,0.15)",
  primaryColor: "#D64045",
  primaryLightColor: "#DE9598",
  primaryDarkColor: "#BA363B",
  secondaryColor: "#F7B801",
  primaryTextColor: "#25262A",
  mainTextColor: "#1B254B",
  subTextColor: "#616060",
  disabledTextColor: "#a1a1a1",
  whiteText: "#FFFFFF",
  blackText: "#000000",
  primaryButtonColor: "#2364AA",
  secondaryButtonColor: "#8EA604",
  secondaryLightColor: "#d1db99",
  successColor: "#0CCE6B",
  warningColor: "#fde047",
  errorColor: "#D00000",
};
