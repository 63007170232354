import * as actionTypes from "./actionTypes";

function getTodayAndBefore7Days() {
  const currentDate = new Date();

  const sevenDaysAgo = new Date(currentDate);
  sevenDaysAgo.setDate(currentDate.getDate() - 7);

  const todayDateString = currentDate.toISOString().split("T")[0];
  const sevenDaysAgoDateString = sevenDaysAgo.toISOString().split("T")[0];

  return {
    today: todayDateString,
    sevenDaysAgo: sevenDaysAgoDateString,
  };
}

const dates = getTodayAndBefore7Days();

const initialState = {
  error: false,
  errorMessage: "",
  message: "",
  loading: false,
  AuditsList: {
    totalCount: 10,
    totalPages: 0,
    currentPage: 1,
    perPage: 10,
    data: [],
  },
  selectedAudit: {},
  auditFilters: {
    policyName: [],
    startDate: dates.sevenDaysAgo,
    endDate: dates.today,
  },
};

const Audits = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUDITS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_AUDITS_SUCCESS:
      return {
        ...state,
        loading: false,
        AuditsList: action.payload,
      };
    case actionTypes.GET_AUDITS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.GET_AUDIT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAudit: action.payload,
      };
    case actionTypes.GET_AUDIT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
};

export default Audits;
