export const GET_AUDITS = "GET_AUDITS";
export const GET_AUDITS_SUCCESS = "GET_AUDITS_SUCCESS";
export const GET_AUDITS_FAILURE = "GET_AUDITS_FAILURE";

export const GET_AUDIT_BY_ID = "GET_AUDIT_BY_ID";
export const GET_AUDIT_BY_ID_SUCCESS = "GET_AUDIT_BY_ID_SUCCESS";
export const GET_AUDIT_BY_ID_FAILURE = "GET_AUDIT_BY_ID_FAILURE";

export const RESTORE_DATA_BY_ID = "RESTORE_DATA_BY_ID";
export const RESTORE_DATA_BY_ID_SUCCESS =
  "RESTORE_DATA_BY_ID_SUCCESS";
export const RESTORE_DATA_BY_ID_FAILURE =
  "RESTORE_DATA_BY_ID_FAILURE";

export const SET_AUDITS_LOADING = "SET_AUDITS_LOADING";
