/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { CircularProgress, Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import variables from "../../../Assets/Styles/variables";
import { Link, useNavigate } from "react-router-dom";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import { PrimaryButton } from "../Buttons/Buttons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  deletePolicyIdAction,
  runNowPolicy,
  skipNextPolicyRun,
  getPolicyAction,
  changeSelectedPolicies,
} from "../../../store/Policy/actions";
import { useDispatch, useSelector } from "react-redux";
import { formatTime, getNextCronRun } from "../../../utils/timeUtils";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { HasPermission } from "../../../utils/HasPermission";

const MySwal = withReactContent(Swal);

function EnhancedTableHead(props) {
  const headCells = [
    {
      id: "name",
      disablePadding: true,
      label: "Policy Name",
    },
    {
      id: "schedule",
      disablePadding: false,
      label: "Schedule",
    },
    {
      id: "created_at",
      disablePadding: false,
      label: "Created At",
    },
    {
      id: "last_next_run",
      disablePadding: false,
      label: "Last & Next Run",
    },
  ];

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    permissions,
    filters,
    isAllSelected,
  } = props;

  if (
    // permissions.includes("create") ||
    // permissions.includes("update") ||
    // permissions.includes("delete") ||
    true
  ) {
    headCells.push({
      id: "actions",
      disablePadding: false,
      label: "Actions",
    });
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: variables.backgroundDark }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={
              (rowCount > 0 && numSelected === rowCount) || isAllSelected
            }
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: "bold" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton></IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ThemeTable({
  rows,
  pagination,
  permissions,
  filters,
  isAllSelected,
  setIsAllSelected,
  playSelectedPolicies,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [paginate, setPaginate] = useState({
    currentPage: pagination.currentPage - 1 || 0,
    perPage: pagination.perPage || 10,
    totalCount: pagination.totalCount || 0,
    totalPages: Math.ceil(pagination.totalCount / pagination.perPage) || 0,
  });
  const { selectedPolicies, runNowProgress, skipPolicyProgress, loading } =
    useSelector((state) => state.Policy);

  useEffect(() => {
    let temp = paginate;
    if (pagination.hasOwnProperty("currentPage")) {
      temp.currentPage = pagination.currentPage - 1;
    }
    if (pagination.hasOwnProperty("perPage")) {
      temp.perPage = pagination.perPage;
    }
    if (pagination.hasOwnProperty("totalCount")) {
      temp.totalCount = pagination.totalCount;
    }
    if (pagination.hasOwnProperty("totalPages")) {
      temp.totalPages = pagination.totalPages;
    }
    setPaginate({ ...temp });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setIsAllSelected(true);
      const newSelecteds = rows.map((policy) => ({
        id: policy.id,
        value: policy["Policy name"],
      }));
      setSelected([...newSelecteds]);
    } else {
      setIsAllSelected(false);
      setSelected([]);
    }
  };

  const handleClick = (event, id, name) => {
    const selectedIndex = selected.findIndex((p) => p.id === id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { id: id, value: name });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setIsAllSelected(false);

    setSelected(newSelected);
  };

  const getFilters = () => ({
    title: filters.policiesName.map((itm) => itm.title).join(","),
    keyword: filters.search_keyword.map((itm) => itm.title).join(","),
    location: filters.locations.map((itm) => itm.title).join("|"),
    device_type: filters.device.map((itm) => itm.title).join(","),
    run_paused: filters.status.map((itm) => itm).join(","),
  });

  const handleChangePage = (event, newPage) => {
    let params = getFilters();
    dispatch(
      getPolicyAction({
        ...params,
        currentPage: newPage + 1,
        perPage: paginate.perPage,
      })
    );
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    let params = getFilters();
    dispatch(
      getPolicyAction({
        ...params,
        currentPage: 1,
        perPage: parseInt(event.target.value, 10),
      })
    );
    setSelected([]);
  };

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this Role!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePolicyIdAction({ Id: id }));
      }
    });
  };

  const handleRunNow = (id, value) => {
    MySwal.fire({
      title: "Are you sure you want to manually run the policy?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Run it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          runNowPolicy({
            policies: [{ id: id, value: value }],
            singleRun: true,
            dispatch,
          })
        );
      }
    });
  };

  const handleSkipNextPolicy = (id, value) => {
    MySwal.fire({
      title: "Are you sure you want to skip the next scheduled run?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Skip it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          skipNextPolicyRun({
            policies: [{ id: id, value: value }],
            singleRun: true,
            dispatch,
          })
        );
      }
    });
  };

  const isSelected = (id) => {
    if (isAllSelected) {
      return true;
    }
    return selected.some((p) => p.id === id);
  };

  useEffect(() => {
    dispatch(changeSelectedPolicies(selected));
  }, [selected]);

  useEffect(() => {
    // to catch bulk run_now/skip successful event
    if (selectedPolicies?.length > 0 && selectedPolicies[0] === "0") {
      setSelected([]);
      setIsAllSelected(false);
    }
  }, [selectedPolicies]);

  const getSkipPolicyStatus = (id) => {
    const index = skipPolicyProgress.findIndex((p) => p.id === id);
    return index > -1 ? skipPolicyProgress[index].status : "";
  };
  const getRunNowStatus = (id) => {
    const index = runNowProgress.findIndex((p) => p.id === id);
    return index > -1 ? runNowProgress[index].status : "";
  };

  const emptyRows = 0;

  const SkipIconButton = ({ id, name, skipNextRun, index, row }) => {
    if (skipNextRun || getSkipPolicyStatus(id) === "success") {
      rows[index]["skip_next_run"] = true;
      return (
        <p style={{ color: row.run_paused ? "gray" : variables.primaryColor }}>
          {" "}
          Skipped{" "}
        </p>
      );
    } else if (getSkipPolicyStatus(id) === "loading") {
      return (
        <CircularProgress
          fill={variables.primaryColor}
          color="action"
          size={20}
        />
      );
    } else {
      return (
        <Tooltip title={row.run_paused ? "" : "Click to Skip Next Policy Run"}>
          <IconButton>
            <NextPlanIcon
              fill={row.run_paused ? "gray" : variables.primaryColor}
              onClick={() => {
                if (!row.run_paused) {
                  handleSkipNextPolicy(id, name);
                }
              }}
              style={{
                color: row.run_paused ? "gray" : variables.primaryColor,
                cursor: row.run_paused ? "not-allowed" : "pointer",
              }}
            />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const RunNowButton = ({ id, name }) => {
    if (getRunNowStatus(id) === "loading") {
      return (
        <PrimaryButton
          size="small"
          style={{ cursor: "default", width: "88px", height: "31px" }}
          onClick={(e) => e.prevenDefault()}
        >
          <CircularProgress color="action" size={20} />
        </PrimaryButton>
      );
    } else {
      return (
        <PrimaryButton size="small" onClick={() => handleRunNow(id, name)}>
          Run now
        </PrimaryButton>
      );
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              permissions={permissions}
              isAllSelected={isAllSelected}
            />
            <TableBody>
              {rows?.length > 0 &&
                rows?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        style={{ verticalAlign: "top" }}
                      >
                        <Checkbox
                          onClick={(event) =>
                            handleClick(event, row.id, row["Policy name"])
                          }
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th" //Converted this into Link componet to add rightClick + open in new tab from th (Compoonent)
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{
                          cursor: "pointer",
                          padding: "10px",
                          // textDecoration: "none",
                        }}
                      >
                        <Link
                          to={`/policies/edit/${row.id}`} // Specify the target URL
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            // border: "1px solid red",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {row["Policy name"] ? (
                              row["Policy name"]
                            ) : (
                              <Skeleton />
                            )}
                          </span>
                          <div style={{ display: "flex" }}>
                            <div>
                              <span
                                style={{
                                  color: variables.primaryButtonColor,
                                  fontWeight: "bold",
                                }}
                              >
                                Keywords:&nbsp;
                              </span>
                            </div>
                            {row.keyword ? (
                              <div>{row.keyword}</div>
                            ) : (
                              <Skeleton width={300} />
                            )}
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>
                              <span
                                style={{
                                  color: variables.primaryButtonColor,
                                  fontWeight: "bold",
                                }}
                              >
                                Locations:&nbsp;
                              </span>
                            </div>
                            {row.location ? (
                              <div>{row.location}</div>
                            ) : (
                              <Skeleton width={300} />
                            )}
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>
                              <span
                                style={{
                                  color: variables.primaryButtonColor,
                                  fontWeight: "bold",
                                }}
                              >
                                Device:&nbsp;
                              </span>
                            </div>
                            {row.device ? (
                              <div>{row.device}</div>
                            ) : (
                              <Skeleton width={300} />
                            )}
                          </div>
                        </Link>
                      </TableCell>

                      <TableCell
                        style={{ cursor: "pointer", maxWidth: "200px" }}
                      >
                        <Link
                          to={`/policies/edit/${row.id}`} // Specify the target URL
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            // border: "1px solid red",
                          }}
                        >
                          {row.schedule ? row.schedule : <Skeleton count={3} />}
                        </Link>
                      </TableCell>

                      <TableCell
                        style={{ cursor: "pointer", maxWidth: "200px" }}
                      >
                        <Link
                          // Specify the target URL
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            // border: "1px solid red",
                          }}
                        >
                          {new Date(row.createdAt).toLocaleDateString({
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </Link>
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        <div>
                          <Link
                            to={
                              row.latestReportTime &&
                              `/reports/${row.policy_report_id}`
                            }
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            <span style={{ color: "black", fontWeight: "600" }}>
                              Last: &nbsp;
                            </span>
                            {row.latestReportTime ? (
                              formatTime(row.latestReportTime)
                            ) : loading ? (
                              <Skeleton />
                            ) : (
                              <span>Never</span>
                            )}

                            {row.latestReportTime ? (
                              <Tooltip title="Click to View Report">
                                <IconButton>
                                  <AssignmentIcon
                                    fill={variables.primaryButtonColor}
                                    style={{
                                      color: variables.primaryButtonColor,
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Link>
                        </div>
                        <div style={{ color: row.run_paused ? "gray" : "" }}>
                          <span
                            style={{
                              color: row.run_paused ? "gray" : "black",
                              fontWeight: "600",
                            }}
                          >
                            Next: &nbsp;
                          </span>
                          {row.schedule_time ? (
                            getNextCronRun(row.schedule_time)
                          ) : (
                            <Skeleton width={200} />
                          )}

                          <SkipIconButton
                            id={row.id}
                            name={row["Policy name"]}
                            skipNextRun={row.skip_next_run}
                            index={index}
                            row={row}
                          />
                        </div>
                      </TableCell>

                      {
                        <TableCell>
                          <Grid>
                            {HasPermission("crawler_policies_edit") && (
                              <IconButton
                                color="primary"
                                component="label"
                                onClick={() =>
                                  navigate(`/policies/edit/${row.id}`)
                                }
                              >
                                <Link
                                  to={`/policies/edit/${row.id}`} // Specify the target URL
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  <EditIcon
                                    style={{
                                      color: variables.secondaryButtonColor,
                                    }}
                                  />
                                </Link>
                              </IconButton>
                            )}
                            {HasPermission("crawler_policies_create") && (
                              <IconButton
                                color="primary"
                                component="label"
                                onClick={() =>
                                  navigate(`/policies/clone/${row.id}`)
                                }
                              >
                                <Link
                                  to={`/policies/clone/${row.id}`} // Specify the target URL
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  <FileCopyIcon
                                    style={{
                                      color: variables.secondaryButtonColor,
                                    }}
                                  />
                                </Link>
                              </IconButton>
                            )}
                            {HasPermission("crawler_policies_edit") && (
                              <IconButton color="primary" component="label">
                                {row.run_paused ? (
                                  <PlayCircleIcon
                                    style={{
                                      color: variables.secondaryButtonColor,
                                      fontSize: "28.5px",
                                    }}
                                    onClick={() =>
                                      playSelectedPolicies({
                                        type: "play",
                                        id: row.id,
                                      })
                                    }
                                  />
                                ) : (
                                  <PauseCircleIcon
                                    style={{
                                      color: variables.secondaryButtonColor,
                                      fontSize: "28.5px",
                                    }}
                                    onClick={() =>
                                      playSelectedPolicies({
                                        type: "pause",
                                        id: row.id,
                                      })
                                    }
                                  />
                                )}
                              </IconButton>
                            )}

                            {HasPermission("crawler_policies_delete") && (
                              <IconButton
                                color="primary"
                                component="label"
                                onClick={() => handleDelete(row.id)}
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </IconButton>
                            )}
                            {HasPermission("crawler_policies_edit") && (
                              <RunNowButton
                                id={row.id}
                                name={row["Policy name"]}
                              />
                            )}
                          </Grid>
                        </TableCell>
                      }
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>

          {!rows?.length && !loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "50px",
                textAlign: "center",
              }}
            >
              No data found!
            </div>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 300, 500]}
          component="div"
          count={paginate.totalCount}
          rowsPerPage={paginate.perPage}
          page={paginate.currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
