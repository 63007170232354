export const GET_POLICY = "GET_POLICY";
export const GET_POLICY_SUCCESS = "GET_POLICY_SUCCESS";
export const GET_POLICY_FAILURE = "GET_POLICY_FAILURE";

export const GET_POLICY_BY_ID = "GET_POLICY_BY_ID";
export const GET_POLICY_BY_ID_SUCCESS = "GET_POLICY_BY_ID_SUCCESS";
export const GET_POLICY_BY_ID_FAILURE = "GET_POLICY_BY_ID_FAILURE";

export const CREATE_POLICY = "CREATE_POLICY";
export const CREATE_POLICY_SUCCESS = "CREATE_POLICY_SUCCESS";
export const CREATE_POLICY_FAILURE = "CREATE_POLICY_FAILURE";

export const RUN_NOW_POLICY = "RUN_NOW_POLICY";
export const RUN_NOW_POLICY_SUCCESS = "RUN_NOW_POLICY_SUCCESS";
export const RUN_NOW_POLICY_FAILURE = "RUN_NOW_POLICY_FAILURE";

export const RUN_NOW_POLICY_MULTIPLE = "RUN_NOW_MULTIPLE_POLICY";
export const RUN_NOW_POLICY_MULTIPLE_SUCCESS =
  "RUN_NOW_POLICY_MULTIPLE_SUCCESS";
export const RUN_NOW_POLICY_MULTIPLE_FAILURE =
  "RUN_NOW_POLICY_MULTIPLE_FAILURE";

export const PAUSE_RESUME_POLICIES = "PAUSE_RESUME_POLICIES";
export const PAUSE_RESUME_POLICIES_SUCCESS = "PAUSE_RESUME_POLICIES_RESUME";
export const PAUSE_RESUME_POLICIES_FAILURE = "PAUSE_RESUME_POLICIES_FAILURE";

export const SKIP_NEXT_POLICY = "SKIP_NEXT_POLICY";
export const SKIP_NEXT_POLICY_SUCCESS = "SKIP_NEXT_POLICY_SUCCESS";
export const SKIP_NEXT_POLICY_FAILURE = "SKIP_NEXT_POLICY_FAILURE";

export const SKIP_NEXT_POLICY_MULTIPLE = "SKIP_NEXT_POLICY_MULTIPLE";
export const SKIP_NEXT_POLICY_MULTIPLE_SUCCESS =
  "SKIP_NEXT_POLICY_MULTIPLE_SUCCESS";
export const SKIP_NEXT_POLICY_MULTIPLE_FAILURE =
  "SKIP_NEXT_POLICY_MULTIPLE_FAILURE";

export const UPDATE_POLICY = "UPDATE_POLICY";
export const UPDATE_POLICY_SUCCESS = "UPDATE_POLICY_SUCCESS";
export const UPDATE_POLICY_FAILURE = "UPDATE_POLICY_FAILURE";

export const DELETE_POLICY = "DELETE_POLICY";
export const DELETE_POLICY_SUCCESS = "DELETE_POLICY_SUCCESS";
export const DELETE_POLICY_FAILURE = "DELETE_POLICY_FAILURE";

export const BULK_DELETE_POLICIES = "BULK_DELETE_POLICIES";
export const BULK_DELETE_POLICIES_SUCCESS = "BULK_DELETE_POLICIES_SUCCESS";
export const BULK_DELETE_POLICIES_FAILURE = "BULK_DELETE_POLICIES_FAILURE";

export const CHANGE_SELECTED_POLICIES = "CHANGE_SELECTED_POLICIES";

export const SET_POLICY_LOADING = "SET_POLICY_LOADING";

export const SET_RUN_NOW_PROGRESS = "SET_RUN_NOW_PROGRESS";
export const SET_RUN_NOW_SUCCESS = "SET_RUN_NOW_SUCCESS";
export const SET_RUN_NOW_FAILURE = "SET_RUN_NOW_FAILURE";
export const SET_SINGLE_RUN_NOW_SUCCESS = "SET_SINGLE_RUN_NOW_SUCCESS";
export const SET_SINGLE_RUN_NOW_FAILURE = "SET_SINGLE_RUN_NOW_FAILURE";

export const SET_SKIP_POLICY_PROGRESS = "SET_SKIP_POLICY_PROGRESS";
export const SET_SKIP_POLICY_SUCCESS = "SET_SKIP_POLICY_SUCCESS";
export const SET_SKIP_POLICY_FAILURE = "SET_SKIP_POLICY_FAILURE";
export const SET_SINGLE_SKIP_POLICY_SUCCESS = "SET_SINGLE_SKIP_POLICY_SUCCESS";
export const SET_SINGLE_SKIP_POLICY_FAILURE = "SET_SINGLE_SKIP_POLICY_FAILURE";

export const POLICY_FILTERS_ACTION = "POLICY_FILTERS_ACTION";
export const POLICY_FILTERS_ACTION_SUCCESS = "POLICY_FILTERS_ACTION_SUCCESS";

export const GET_ALL_POLICIES_TITLES = "GET_ALL_POLICIES_TITLES";
export const GET_ALL_POLICIES_TITLES_SUCCESS =
  "GET_ALL_POLICIES_TITLES_SUCCESS";
export const GET_ALL_POLICIES_TITLES_FAILURE =
  "GET_ALL_POLICIES_TITLES_FAILURE";
