/* eslint-disable react/jsx-no-target-blank */
import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Avatar, Grid } from "@mui/material";
import variables from "../../Assets/Styles/variables";
import Google from "../../Assets/Google__G__Logo.svg.png";
import { useSelector } from "react-redux";

const GoogleResults = ({ ad }) => {
  return (
    <div style={{ padding: "10px 0px" }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Avatar style={{ width: "27px", height: "27px" }} src={Google} />
        </Grid>
        <Grid item>
          <Typography variant="body2" style={{ fontSize: "14px" }}>
            {ad.displayed_link}
          </Typography>
          <Typography variant="body2" style={{ fontSize: "12px" }}>
            {ad.link} › ...
          </Typography>
        </Grid>
      </Grid>
      <Typography
        style={{ color: "#1a0dab", fontSize: "20px", fontWeight: 400 }}
      >
        <a
          target="_blank"
          href={ad.link}
          style={{ textDecoration: "none", color: "#290dbc" }}
        >
          {ad.title}
        </a>
      </Typography>
      <Typography style={{ display: "block" }} variant="caption">
        Sep 20, 2018
      </Typography>
      <Typography variant="caption">{ad.description}</Typography>
    </div>
  );
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: 5,

  [`& .${treeItemClasses.content}`]: {
    minHeight: "50px",
    maxWidth: "900px",
    borderLeft: `5px solid ${variables.primaryColor}`,
    marginTop: "5px",
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
    color: "var(--tree-view-color)",
    borderTopRightRadius: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    clipPath:
      "polygon(0% 0%, 100% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%, 0% 100%)",
    // boxShadow:'0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 30,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

function ChainedLinks({ links }) {
  return (
    <div>
      {links.map((link, index) => {
        return (
          <StyledTreeItem
            key={index}
            nodeId={link._id}
            labelText={
              <a
                target="_blank"
                href={link.url}
                style={{ textDecoration: "none", color: "#290dbc" }}
              >
                {link.url}
              </a>
            }
            color="#1a0dab"
            bgColor="rgba(0,0,0,0.04)"
          />
        );
      })}
    </div>
  );
}

function AdsList({ ads }) {
  return (
    <div>
      {ads.map((ad, index) => {
        const chainedLinks = ad.chain;
        return (
          <StyledTreeItem
            key={index}
            nodeId={ad._id}
            labelText={<GoogleResults ad={ad} />}
            color="#000000"
            bgColor="rgba(0,0,0,0.04)"
          >
            <ChainedLinks links={chainedLinks} />
          </StyledTreeItem>
        );
      })}
    </div>
  );
}

function DevicesList({ devices, locationId, reportId }) {
  return (
    <div>
      {devices.map(({ device, ads }, index) => {
        const id = `device_${index}_${locationId}_${reportId}`;
        return (
          <StyledTreeItem
            key={index}
            nodeId={id}
            labelText={device}
            color="#1a0dab"
            bgColor="rgba(0,0,0,0.04)"
          >
            <AdsList ads={ads} />
          </StyledTreeItem>
        );
      })}
    </div>
  );
}

function LocationsList({ locations, reportId }) {
  return (
    <div>
      {locations.map(({ location, devices }, index) => {
        const id = `location_${index}_${reportId}`;
        return (
          <StyledTreeItem
            key={index}
            nodeId={id}
            labelText={location}
            color="#1a0dab"
            bgColor="rgba(0,0,0,0.04)"
          >
            <DevicesList
              devices={devices}
              locationId={index}
              reportId={reportId}
            />
          </StyledTreeItem>
        );
      })}
    </div>
  );
}

function TreeViewBody({ reports }) {
  return (
    <div>
      {reports.map((report, reportIndex) => {
        return (
          <StyledTreeItem
            nodeId={reportIndex}
            labelText={report.keyword}
            color="#000000"
            bgColor="rgba(0,0,0,0.04)"
          >
            <LocationsList
              locations={report.locations}
              reportId={reportIndex}
            />
          </StyledTreeItem>
        );
      })}
    </div>
  );
}

function sortReports(reports) {
  let ids = [];
  let keywords = [];
  let locationList = [];
  let locationResult = [];
  let devicesList = [];
  let length = 0;

  reports.map((report) => {
    if (!keywords.includes(report.keyword)) {
      // keywords.push(report.keyword);
      keywords[length] = report.keyword;
      length++;
    }

    const index = keywords.indexOf(report.keyword);

    if (ids[index] === undefined) ids[index] = [];
    ids[index].push(report._id);

    if (locationList[index] === undefined) locationList[index] = [];
    if (!locationList[index].includes(report.location))
      locationList[index].push(report.location);

    const locationIndex = locationList[index].indexOf(report.location);

    const ads = report.ads.map((ad) => {
      return { ...ad, reportId: report._id };
    });

    if (devicesList[index] === undefined) devicesList[index] = [];
    if (devicesList[index][locationIndex] === undefined)
      devicesList[index][locationIndex] = [];
    devicesList[index][locationIndex].push({ device: report.device, ads: ads });
  });

  locationList.map((locations, index) => {
    locations.map((location, locationIndex) => {
      if (locationResult[index] === undefined) locationResult[index] = [];
      locationResult[index].push({
        location: location,
        devices: devicesList[index][locationIndex],
      });
    });
  });

  const results = keywords.map((keyword, index) => {
    return {
      keyword: keyword,
      locations: locationResult[index],
    };
  });

  return results;
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default function StyledTreeView() {
  const { selectedPolicy } = useSelector((state) => state.PolicyReport);
  const reports = sortReports(selectedPolicy.report);

  return (
    <TreeView
      aria-label="gmail"
      defaultExpanded={["3"]}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ flexGrow: 1, overflowY: "auto" }}
    >
      <TreeViewBody reports={reports} />
    </TreeView>
  );
}
