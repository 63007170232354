/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import ThemeTable from "../../components/UI/Table/Table";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import PageHeader from "../../components/UI/PageWrapper/PageHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PrimaryButton } from "../../components/UI/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  getPolicyAction,
  pauseResumePolicies,
} from "../../store/Policy/actions";
import PoliciesFilterCard from "./PoliciesFilterCard";
import AddIcon from "@mui/icons-material/Add";
import withReactContent from "sweetalert2-react-content";
import cronstrue from "cronstrue";
import {
  getPolicyReportFiltersAction,
  policyFiltersAction,
} from "../../store/PolicyReport/actions";
import Collapse from "@mui/material/Collapse";
import Swal from "sweetalert2";
import { TablePagination, Tooltip } from "@mui/material";
import { HasPermission } from "../../utils/HasPermission";
import NotPermitted from "../../components/UI/NotPermitted";

const MySwal = withReactContent(Swal);

const ViewLink = ({ showLess, setShowLess }) => {
  return (
    <Tooltip title="Ctrl + Left Click" placement="top" arrow>
      <a
        style={{ color: "blue", cursor: "pointer", marginBottom: "10px" }}
        onClick={() => setShowLess(!showLess)}
      >
        {" "}
        &nbsp;View {showLess ? "More" : "Less"}{" "}
      </a>
    </Tooltip>
  );
};

const Locations = ({ locations, cutoff = 3 }) => {
  const [showLess, setShowLess] = useState(true);
  const style = { marginLeft: "10px" };

  if (locations?.length <= cutoff) {
    return locations?.map((location) => (
      <div style={style}>{location.canonicalName}</div>
    ));
  } else {
    return (
      <div style={style}>
        <Collapse in={!showLess} collapsedSize={60}>
          {locations?.map((location, index) => (
            <div>
              {location.canonicalName}
              {showLess && index === cutoff - 1 && (
                <ViewLink showLess={showLess} setShowLess={setShowLess} />
              )}
              {!showLess && index === locations?.length - 1 && (
                <ViewLink showLess={showLess} setShowLess={setShowLess} />
              )}
            </div>
          ))}
        </Collapse>
      </div>
    );
  }
};

function Policies({
  permissions,
  lastRouteInHistory,
  lastLocationBeforeUnmount,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isAllSelected, setIsAllSelected] = useState(false);

  const filtersInitialState = {
    policiesName: [],
    search_keyword: [],
    locations: [],
    device: [],
    status: [],
  };
  let [filtersREMOVED, setFilters] = useState(filtersInitialState);
  let [searchParams, setSearchParams] = useSearchParams();
  const { PoliciesList, filters } = useSelector((state) => state.Policy);

  const [query, setQuery] = useState({
    policiesName: Array.isArray(filters?.policiesName)
      ? filters?.policiesName.map((itm) => itm.title).join(",") || ""
      : filters?.policiesName || "",
    search_keyword: Array.isArray(filters?.search_keyword)
      ? filters?.search_keyword.map((itm) => itm.title).join(",") || ""
      : filters?.search_keyword || "",
    locations: Array.isArray(filters?.locations)
      ? filters?.locations.map((itm) => itm.title).join("|") || ""
      : filters?.locations || "",
    device: Array.isArray(filters?.device)
      ? filters?.device.map((itm) => itm.title).join(",") || ""
      : filters?.device || "",
    status: Array.isArray(filters?.status)
      ? filters?.status.map((itm) => itm).join(",") || ""
      : filters?.status || "",
    currentPage:
      PoliciesList.currentPage || parseInt(searchParams.get("currentPage")),
    perPage: PoliciesList.perPage || parseInt(searchParams.get("perPage")),
  });
  const playSelectedPolicies = ({ type, id }) => {
    MySwal.fire({
      title: `Are you sure you want to ${type} the selected policies?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${type} them!`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          pauseResumePolicies({
            params: {
              title: filters.policiesName.map((itm) => itm.title).join(","),
              keyword: filters.search_keyword.map((itm) => itm.title).join(","),
              location: filters.locations.map((itm) => itm.title).join("|"),
              device_type: filters.device.map((itm) => itm.title).join(","),
            },
            body: {
              policy_ids: [id],
              action: isAllSelected ? "all" : "",
              type,
            },
            getParams: {
              title: Array.isArray(filters.policiesName)
                ? filters.policiesName.map((itm) => itm.title).join(",")
                : filters.policiesName,
              keyword: Array.isArray(filters.search_keyword)
                ? filters.search_keyword.map((itm) => itm.title).join(",")
                : filters.search_keyword,
              location: Array.isArray(filters.locations)
                ? filters.locations.map((itm) => itm.title).join("|")
                : filters.locations,
              device_type: Array.isArray(filters.device)
                ? filters.device.map((itm) => itm.title).join(",")
                : filters.device,
              run_paused: Array.isArray(filters.status)
                ? filters.status.join("|")
                : filters.status,
              currentPage: query.currentPage,
              perPage: query.perPage,
            },
          })
        );
      }
    });
  };

  const rows = useMemo(
    () =>
      PoliciesList?.data?.map(
        ({
          _id,
          search_keyword,
          title,
          next_run,
          last_run,
          report_id,
          nextRunAt,
          latestReportTime,
          skip_next_run,
          device_type,
          location,
          schedule_time,
          run_paused,
          createdAt,
        }) => ({
          id: _id,
          keyword: search_keyword?.map((item) => (
            <div style={{ marginLeft: "10px" }}>{item}</div>
          )),
          "Policy name": title,
          device: device_type?.map((item) => (
            <div style={{ marginLeft: "30px" }}>{item}</div>
          )),
          createdAt: createdAt,
          location: <Locations locations={location} cutoff={3} />,
          last_run: last_run,
          next_run: next_run,
          schedule: cronstrue
            .toString(schedule_time, { verbose: true })
            .toLocaleString(),
          schedule_time: schedule_time,
          skip_next_run: skip_next_run,
          latestReportTime: latestReportTime,
          policy_report_id: report_id,
          nextRunAt: nextRunAt,
          run_paused: run_paused,
        })
      ),
    [PoliciesList]
  );
  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      policiesName: Array.isArray(filters?.policiesName)
        ? filters?.policiesName.map((itm) => itm.title).join(",") || ""
        : filters?.policiesName || "",
      search_keyword: Array.isArray(filters?.search_keyword)
        ? filters?.search_keyword.map((itm) => itm.title).join(",") || ""
        : filters?.search_keyword || "",
      locations: Array.isArray(filters?.locations)
        ? filters?.locations.map((itm) => itm.title).join("|") || ""
        : filters?.locations || "",
      device: Array.isArray(filters?.device)
        ? filters?.device.map((itm) => itm.title).join(",") || ""
        : filters?.device || "",
      status: Array.isArray(filters?.status)
        ? filters?.status.map((itm) => itm).join(",") || ""
        : filters?.status || "",
      currentPage: PoliciesList.currentPage,
      perPage: PoliciesList.perPage,
    }));
  }, [PoliciesList, filters]);

  useEffect(() => {
    setSearchParams((params) => {
      params.set("currentPage", query?.currentPage);
      params.set("perPage", query?.perPage);
      params.set("policiesName", query?.policiesName);
      params.set("Keywords", query?.search_keyword);
      params.set("locations", query?.locations);
      params.set("device", query?.device);
      params.set("status", query?.status);
      return params;
    });
  }, [query]);

  if (!HasPermission("crawler_policies_view")) {
    return <NotPermitted />;
  }
  return (
    <div>
      <PageWrapper>
        <div>
          <PageHeader
            header="Policies"
            headerButton={
              HasPermission("crawler_policies_create") && (
                <PrimaryButton
                  startIcon={<AddIcon />}
                  variant="contained"
                  onClick={() => window.open("/policies/create", "_blank")}
                >
                  Create Policy
                </PrimaryButton>
              )
            }
          />
          <PoliciesFilterCard
            lastRouteInHistory={lastRouteInHistory}
            permissions={permissions}
            filtersInitialState={filtersInitialState}
            filters={filters}
            setFilters={setFilters}
            isAllSelected={isAllSelected}
            setIsAllSelected={setIsAllSelected}
            lastLocationBeforeUnmount={lastLocationBeforeUnmount}
            pagination={PoliciesList}
            query={query}
          />

          <ThemeTable
            isAllSelected={isAllSelected}
            setIsAllSelected={setIsAllSelected}
            rows={rows}
            permissions={permissions}
            filters={filters}
            pagination={PoliciesList}
            playSelectedPolicies={playSelectedPolicies}
          />
        </div>
      </PageWrapper>
    </div>
  );
}

export default Policies;
