import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  message:"",
  loading: false,
  locations: [],
  randomLocations: [],
  countries: [],
};

const Utilities = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_UTILITIES_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_SELECT_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        locations:action.payload
      };
    case actionTypes.GET_SELECT_LOCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_RANDOM_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        randomLocations:action.payload
      };
    case actionTypes.GET_RANDOM_LOCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries:action.payload
      };
    case actionTypes.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.SET_LOCATIONS_EMPTY:
      return {
        ...state,
        loading: false,
        locations: [],
        randomLocations: [],
      };
    default:
      return state;
  }
};

export default Utilities;
