import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { formatTimeWithSec } from "../../utils/timeUtils";

function AuditDetailsRowHead({ audit }) {
  const toSentenceCase = (str = "") => {
    return str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : "";
  };

  return (
    <div>
      <Paper elevation={3} style={{ padding: "30px", borderRadius: "20px" }}>
        {audit && (
          <div>
            <Grid container>
              <Grid item sm={4}>
                {" "}
                <Typography>
                  {" "}
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    Module Name :
                  </span>
                  {toSentenceCase(audit?.module_name)}
                </Typography>{" "}
              </Grid>
              <Grid item sm={4}>
                {" "}
                <Typography>
                  {" "}
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    Action Type :{" "}
                  </span>{" "}
                  {toSentenceCase(audit?.action_type)}{" "}
                </Typography>{" "}
              </Grid>
              <Grid item sm={4}>
                {" "}
                <Typography>
                  {" "}
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    Date :{" "}
                  </span>{" "}
                  {formatTimeWithSec(audit.createdAt)}{" "}
                </Typography>{" "}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={4}>
                {" "}
                <Typography>
                  {" "}
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    User Name :{" "}
                  </span>{" "}
                  {audit?.action_by?.name}{" "}
                </Typography>{" "}
              </Grid>
              <Grid item sm={4}>
                {" "}
                <Typography>
                  {" "}
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    User Email :{" "}
                  </span>{" "}
                  {audit?.action_by?.email || audit?.userInfo?.email}{" "}
                </Typography>{" "}
              </Grid>
            </Grid>
          </div>
        )}
      </Paper>
    </div>
  );
}

export default AuditDetailsRowHead;
