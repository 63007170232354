var parser = require("cron-parser");

export function formatTime(time) {
  if (!time) return "N/A"; // Return "N/A" if time is not provided

  const formattedTime = new Intl.DateTimeFormat("en-IN", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  }).format(new Date(time));

  return formattedTime;
}

export function formatTimeWithSec(time) {
  if (!time) return "N/A"; // Return "N/A" if time is not provided

  const formattedTime = new Intl.DateTimeFormat("en-IN", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  }).format(new Date(time));

  return formattedTime;
}

export function getNextCronRun(expression) {
  var interval = parser.parseExpression(expression);
  let time = interval.next().toString();
  const formattedTime = new Intl.DateTimeFormat("en-IN", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  }).format(new Date(time));

  return formattedTime;
}
