import React from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  createTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import variables from "../../Assets/Styles/variables";
import CampaignIcon from "@mui/icons-material/Campaign";
import HubIcon from "@mui/icons-material/Hub";
import DevicesIcon from "@mui/icons-material/Devices";
import ComputerIcon from "@mui/icons-material/Computer";
import TabletAndroidIcon from "@mui/icons-material/TabletAndroid";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LocationIcon from "@mui/icons-material/LocationOn";
import { formatTimeWithSec } from "../../utils/timeUtils";
import AdView from "./AdView";
import TreeView from "./TreeView";
import { SecondaryButton } from "../UI/Buttons/Buttons";

let theme = createTheme();

const useStyles = makeStyles(() => ({
  imageArea: {
    width: "100%",
    height: "250px",
    borderRadius: "15px 15px 0px 0px",
    background: `url("https://picsum.photos/1000/250") top center / cover no-repeat`,
  },
  contentArea: {},
  avatarBox: {
    display: "flex",
    "& .MuiAvatar-root": {
      width: 56,
      height: 56,
      margin: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(
        2
      )} ${theme.spacing(3)}`,
      backgroundColor: variables.primaryColor,
    },
    "& .MuiGrid-root": {
      padding: theme.spacing(1),
    },
    "& .MuiBox-root": {
      marginTop: theme.spacing(1),
    },
  },
  iconArea: {
    margin: `${theme.spacing(2)} 0`,
  },
  iconDetails: {
    display: "flex",
    alignItems: "top",
    margin: `${theme.spacing(1)} 0 ${theme.spacing(2)} 0`,
    padding: `0 ${theme.spacing(3)}`,
    "& $small": {
      color: variables.disabledTextColor,
      fontWeight: "bolder",
    },
    "& .MuiAvatar-root": {
      marginRight: theme.spacing(1),
    },
  },
  deviceIcon: {
    fontSize: "1.2rem",
    marginRight: theme.spacing(1),
    display: "inline-block",
  },
  locationsGrid: {
    padding: `${theme.spacing(3)} !important`,
    paddingTop: "0px !important",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DevicesList = ({ list }) => {
  const classes = useStyles();
  return (
    <div>
      {list?.length > 0 &&
        list?.map((device) => {
          if (device.toLowerCase() === "desktop") {
            return <ComputerIcon className={classes.deviceIcon} />;
          } else if (device.toLowerCase() === "tablet") {
            return <TabletAndroidIcon className={classes.deviceIcon} />;
          } else if (device.toLowerCase() === "mobile") {
            return <PhoneAndroidIcon className={classes.deviceIcon} />;
          }
        })}
    </div>
  );
};

function ReportDetails({ Individual_Policy_Report }) {
  if (!Individual_Policy_Report) {
    Individual_Policy_Report = {
      createdAt: "",
      locations: [],
      policy: {},
      report: [],
      totalAdsFound: 0,
      uniqueOverallNetwork: [],
    };
  }
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [collapseController, setCollapseController] = React.useState(false);
  const startDateTime = Individual_Policy_Report.policy
    ? formatTimeWithSec(Individual_Policy_Report.createdAt)
    : "";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Paper elevation={3} sx={{ borderRadius: "15px" }}>
        {Individual_Policy_Report.policy && (
          <Grid container className={classes.contentArea}>
            <Grid item sm={7} className={classes.avatarBox}>
              <Avatar sx={{ width: 56, height: 56 }}>
                {Individual_Policy_Report.policy?.title
                  .substring(0, 2)
                  .toUpperCase()}
              </Avatar>
              <Box>
                <Typography variant="h5" component="h5">
                  {Individual_Policy_Report.policy?.title}
                </Typography>
                <Typography variant="p" component="p">
                  {Individual_Policy_Report.policy?.search_keyword?.join(", ")}
                </Typography>
                {startDateTime && (
                  <Typography
                    variant="small"
                    component="small"
                    sx={{ color: variables.mainTextColor }}
                  >
                    {startDateTime}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item sm={4}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Ad View" sx={{ width: "50%" }} {...a11yProps(0)} />
                <Tab
                  label="Tree View"
                  sx={{ width: "50%" }}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Grid>
          </Grid>
        )}

        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
        {true && (
          <Grid container className={classes.iconArea}>
            <Grid item sm={2.5} className={classes.iconDetails}>
              <Avatar
                sx={{ bgcolor: variables.secondaryColor }}
                variant="rounded"
              >
                <CampaignIcon />
              </Avatar>
              <Box>
                <Typography variant="small" component="small">
                  Total Ads:
                </Typography>
                <Typography variant="p" component="p">
                  {Individual_Policy_Report.totalAdsFound || "0"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={2.5} className={classes.iconDetails}>
              <Avatar
                sx={{ bgcolor: variables.secondaryColor }}
                variant="rounded"
              >
                <HubIcon />
              </Avatar>
              <Box>
                <Typography variant="small" component="small">
                  Total Networks:
                </Typography>
                <Typography variant="p" component="p">
                  {Individual_Policy_Report.uniqueOverallNetwork?.length || "0"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={2.5} className={classes.iconDetails}>
              <Avatar
                sx={{ bgcolor: variables.secondaryColor }}
                variant="rounded"
              >
                <DevicesIcon />
              </Avatar>
              <Box>
                <Typography variant="small" component="small">
                  Selected Devices:
                </Typography>
                {Individual_Policy_Report.policy?.device_type ? (
                  <DevicesList
                    list={Individual_Policy_Report.policy?.device_type}
                  />
                ) : (
                  <div> NA </div>
                )}
              </Box>
            </Grid>

            <Grid item sm={4.5} className={classes.iconDetails}>
              <Avatar
                sx={{ bgcolor: variables.secondaryColor }}
                variant="rounded"
              >
                <LocationIcon />
              </Avatar>
              <Box>
                <Typography variant="small" component="small">
                  Locations:
                </Typography>
                {Individual_Policy_Report.locations ? (
                  Individual_Policy_Report.locations?.map((location) => (
                    <p style={{ whiteSpace: "nowrap" }}>
                      {location.length <= 35
                        ? location
                        : location.substring(0, 32) + "..."}
                    </p>
                  ))
                ) : (
                  <div> NA </div>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Paper>

      {Individual_Policy_Report.report && (
        <div style={{ marginTop: "20px" }}>
          <TabPanel value={value} index={0}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{ marginBottom: "15px" }}
                >
                  AD VIEW{" "}
                </Typography>
              </div>
              <div>
                <Button
                  size="small"
                  style={{
                    marginRight: "10px",
                    backgroundColor: variables.primaryColor,
                    color: variables.whiteText,
                  }}
                  onClick={() => setCollapseController(true)}
                >
                  Expand All
                </Button>
                <SecondaryButton
                  size="small"
                  onClick={() => setCollapseController(false)}
                >
                  Collapse all
                </SecondaryButton>
              </div>
            </div>
            <AdView
              collapseController={collapseController}
              Individual_Policy_Report={Individual_Policy_Report}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography
              variant="h5"
              component="h5"
              sx={{ marginBottom: "15px" }}
            >
              TREE VIEW
            </Typography>
            <TreeView />
          </TabPanel>
        </div>
      )}
    </div>
  );
}

export default ReportDetails;
