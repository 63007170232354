import React, { useState } from "react";
import { Divider, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { PrimaryButton } from "../../components/UI/Buttons/Buttons";
import { sendResetPasswordLink } from "../../store/Account/actions";
import { useDispatch, useSelector } from "react-redux";
import UnauthPageWrapper from "../../components/UI/PageWrapper/UnauthPageWrapper";
import CircularProgress from "@mui/material/CircularProgress";

let theme = createTheme();

const useStyles = makeStyles(() => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.Account);

  const [email, setEmail] = useState("");

  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(sendResetPasswordLink({ email: email, navigate }));
  };

  return (
    <UnauthPageWrapper>
      <div className="login_container">
        <h2>Forgot Password!</h2>
        <p>
          Enter your email address so that we can send you a link to reset your
          password.
        </p>
        <form onSubmit={handleSubmit}>
          <TextField
            type="email"
            label="Email"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={email}
            style={{ margin: "15px 0px" }}
            onChange={(event) => setEmail(event.target.value)}
          />

          <PrimaryButton fullWidth type="submit" style={{ margin: "15px 0px" }}>
            {loading ? (
              <CircularProgress size={25} color="action" />
            ) : (
              "Send Link"
            )}
          </PrimaryButton>
        </form>

        <Divider style={{ marginTop: "30px" }} />
      </div>
    </UnauthPageWrapper>
  );
}

export default ForgotPassword;
