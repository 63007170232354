import axios from "axios";
import { setWebsiteLoadingAction } from "../store/Dashboard/actions";
import Store from "../store/index";

export const BASE_URL = window.location.hostname.includes("aldebaranmedia")
  ? "https://api-lms.aldebaranmedia.com/" // Production link
  : "https://projects.xcitech.in/adb-crawler-api/"; //Staging link

const API = axios.create({
  baseURL: BASE_URL,
});

API.interceptors.request.use((request) => {
  dispatcher(true);
  if (request.url.endsWith("login")) return request;

  const token = localStorage.getItem("token");
  request.headers["authorization"] = `Bearer ${token}`;
  request.headers["Content-Type"] =
    request.headers["Content-Type"] || "application/json";

  return request;
});

API.interceptors.response.use(
  (response) => {
    dispatcher(false);
    return response;
  },
  (error) => {
    dispatcher(false);
    if (error.response.status === 401) {
      // localStorage.removeItem("token");
    }

    return Promise.reject(error);
  }
);

function dispatcher(val) {
  Store.dispatch(setWebsiteLoadingAction(val));
}

export default API;
