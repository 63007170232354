import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Logo from "../../Assets/logo.png";
import OnboardingFirst from "../../components/Onboarding/OnboardingFirstPolicy";
import OnboardingSecond from "../../components/Onboarding/OnboardingPolicyName";
import OnboardingThird from "../../components/Onboarding/OnboardingPolicyKeyword";
import OnboardingFourth from "../../components/Onboarding/OnboardingPolicyLocation";
import OnboardingFifth from "../../components/Onboarding/OnboardingPolicyDeviceAndLanguage";
import OnboardingSixth from "../../components/Onboarding/OnboardingPolicySchedule";
import OnboardingSeventh from "../../components/Onboarding/OnboardingGeneralInfo";
import "./Onboarding.scss";
import { useDispatch } from "react-redux";
import { doOnboardingAction } from "../../store/Account/actions";
import { useNavigate } from "react-router-dom";

const INITIALSTATE = {
  firstPolicy: true,
  title: "",
  search_keyword: [],
  location: [],
  device_type: [],
  language: "",
  schedule_type: "frequently",
  schedule_time: new Date(),
  scheduleFrequency: "12",
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  password: "",
  business_name: "",
  address: "",
};

const initTabs = {
  "First policy": {
    questions: "Do you want to create your first policy?",
    discription: "Lorem ipsum dolor sit amet consectetur.",
    inputType: "radio",
    name: "firstPolicy",
  },
  "Policy Name": {
    questions: "What would you like to call your policy?",
    discription: "Lorem ipsum dolor sit amet consectetur.",
    inputType: "Input",
    name: "PolicyName",
  },
  Keyword: {
    questions: "Enter keyword that you want to target",
    discription: "Lorem ipsum dolor sit amet consectetur.",
    inputType: "Input Box",
    name: "keyword",
  },
  Location: {
    questions: "Enter location that you are targetting",
    discription: "Lorem ipsum dolor sit amet consectetur.",
    inputType: "Auto select input",
    name: "location",
  },
  Device: {
    questions: "Select device and language",
    discription: "Lorem ipsum dolor sit amet consectetur.",
    inputType: "Dropdown",
    name: "device",
  },
  Schedule: {
    questions: "Schedule your policy: Scheduling UI",
    discription: "Lorem ipsum dolor sit amet consectetur.",
    inputType: "Dropdown",
  },
  "General Info": {
    questions: "Let's get you started",
    discription: "Lorem ipsum dolor sit amet consectetur.",
    inputType: "Dropdown",
  },
};

function Onboarding(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState({ ...initTabs });
  const [OnboardingData, setOnboardingData] = React.useState({
    ...INITIALSTATE,
  });
  const [percentage, setPercentage] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPersentage = () => {
    return (activeStep / Object.keys(steps).length) * 100;
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleSubmit = () => {
    dispatch(
      doOnboardingAction({
        ...OnboardingData,
        navigate,
        type: "admin",
        action_type: OnboardingData["firstPolicy"]
          ? "policy-true"
          : "policy-false",
      })
    );
  };
  const handleStep = (step) => {
    setActiveStep(step);
  };

  const handleInput = ({ name, value, next }) => {
    setOnboardingData((OnboardingData) => ({
      ...OnboardingData,
      [name]: value,
    }));
    if (next) {
      handleNext();
    }
  };

  useEffect(() => {
    if (OnboardingData.firstPolicy === false) {
      setSteps({
        "First policy": initTabs["First policy"],
        "General Info": initTabs["General Info"],
      });
      setOnboardingData({ ...INITIALSTATE, firstPolicy: false });
    } else {
      setSteps(initTabs);
      setOnboardingData({ ...INITIALSTATE, firstPolicy: true });
    }
  }, [OnboardingData.firstPolicy]);

  useEffect(() => {
    setPercentage(getPersentage());
  }, [activeStep, getPersentage]);

  return (
    <div>
      <Container>
        <div style={{ marginTop: "50px" }}>
          <img src={Logo} style={{ maxHeight: "50px" }} alt="" />
        </div>

        <Grid
          container
          style={{ margin: "50px 0px" }}
          justifyContent="space-between"
          alignItems="center"
          gap={3}
        >
          <Grid item sm={6}>
            <Button variant="text">
              <ArrowBack /> Home
            </Button>
          </Grid>
          <Grid item>
            <h2>
              {activeStep + 1} of {Object.keys(steps).length}
            </h2>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                percentage
              )}%`}</Typography>
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {Object.keys(steps).map((label, index) => (
                  <Step key={label}>
                    <StepButton
                      color="inherit"
                      onClick={() => handleStep(index)}
                    >
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
        </Grid>

        {activeStep === Object.keys(steps).length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <>
            {activeStep === 0 && <OnboardingFirst handleInput={handleInput} />}
            {activeStep === 1 && OnboardingData.firstPolicy && (
              <OnboardingSecond
                handleInput={handleInput}
                handleNext={handleNext}
                OnboardingData={OnboardingData}
              />
            )}
            {activeStep === 2 && (
              <OnboardingThird
                handleInput={handleInput}
                handleNext={handleNext}
                OnboardingData={OnboardingData}
              />
            )}
            {activeStep === 3 && (
              <OnboardingFourth
                handleInput={handleInput}
                handleNext={handleNext}
                OnboardingData={OnboardingData}
              />
            )}
            {activeStep === 4 && (
              <OnboardingFifth
                handleInput={handleInput}
                handleNext={handleNext}
                OnboardingData={OnboardingData}
              />
            )}
            {activeStep === 5 && (
              <OnboardingSixth
                handleInput={handleInput}
                handleNext={handleNext}
                OnboardingData={OnboardingData}
              />
            )}
            {(activeStep === 6 ||
              (activeStep === 1 && !OnboardingData.firstPolicy)) && (
              <OnboardingSeventh
                handleInput={handleInput}
                handleNext={handleSubmit}
                OnboardingData={OnboardingData}
              />
            )}
          </>
        )}
      </Container>
    </div>
  );
}

export default Onboarding;
