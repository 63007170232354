import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  message: "",
  loading: false,
  runNowProgress: [],
  skipPolicyProgress: [],
  PoliciesList: {
    totalCount: 10,
    totalPages: 0,
    currentPage: 1,
    perPage: 10,
    data: [],
  },
  selectedPolicy: {},
  selectedPolicies: [],
  titles: [],
  filters: {
    policiesName: [],
    search_keyword: [],
    locations: [],
    device: [],
    status: [],
  },
};

const Policy = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_POLICY_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.CREATE_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        PoliciesList: action.payload,
      };
    case actionTypes.GET_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_ALL_POLICIES_TITLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        titles: action.payload,
      };
    case actionTypes.GET_ALL_POLICIES_TITLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_POLICY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPolicy: action.payload,
      };
    case actionTypes.GET_POLICY_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.UPDATE_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.DELETE_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.BULK_DELETE_POLICIES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.BULK_DELETE_POLICIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.RUN_NOW_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPolicies: ["0"],
      };
    case actionTypes.RUN_NOW_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.PAUSE_RESUME_POLICIES_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPolicies: ["0"],
      };
    case actionTypes.PAUSE_RESUME_POLICIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.RUN_NOW_POLICY_MULTIPLE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPolicies: ["0"],
      };
    case actionTypes.RUN_NOW_POLICY_MULTIPLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.SKIP_NEXT_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPolicies: ["0"],
      };
    case actionTypes.SKIP_NEXT_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.SKIP_NEXT_POLICY_MULTIPLE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPolicies: ["0"],
      };
    case actionTypes.SKIP_NEXT_POLICY_MULTIPLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.CHANGE_SELECTED_POLICIES:
      return {
        ...state,
        loading: false,
        selectedPolicies: action.payload,
      };
    case actionTypes.SET_RUN_NOW_PROGRESS:
      return {
        ...state,
        loading: false,
        runNowProgress: action.payload,
      };
    case actionTypes.SET_RUN_NOW_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPolicies: ["0"],
      };
    case actionTypes.SET_RUN_NOW_FAILURE:
      return {
        ...state,
        loading: false,
        // runNowProgress: [],
        // selectedPolicies: ['0'],
      };
    case actionTypes.SET_SINGLE_RUN_NOW_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SET_SINGLE_RUN_NOW_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.SET_SKIP_POLICY_PROGRESS:
      return {
        ...state,
        loading: false,
        skipPolicyProgress: action.payload,
      };
    case actionTypes.SET_SKIP_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPolicies: ["0"],
      };
    case actionTypes.SET_SKIP_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        // skipPolicyProgress: [],
        // selectedPolicies: ['0'],
      };
    case actionTypes.SET_SINGLE_SKIP_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SET_SINGLE_SKIP_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.POLICY_FILTERS_ACTION_SUCCESS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default Policy;
