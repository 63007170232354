import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../utils/API";

function* doLoginSaga({ params }) {
  const { lastRouteInHistory, navigate, ...rest } = params;
  try {
    yield put({ type: actionTypes.SET_ACCOUNT_LOADING });
    const { data } = yield API.post("/api/users/login", rest);

    if (data.success) {
      yield put({
        type: actionTypes.DO_LOGIN_SUCCESS,
        payload: data.token,
      });
      toast.success(" Successfully Logged in ");
      localStorage.setItem("token", data.token);
      navigate(lastRouteInHistory);
    } else {
      toast.error("Unable to Login.");
      yield put({
        type: actionTypes.DO_LOGIN_FAILURE,
        errorMessage: "Unable to login.",
      });
    }
  } catch (error) {
    toast.error("Unable to Login.");
    yield put({
      type: actionTypes.DO_LOGIN_FAILURE,
      errorMessage: "Unable to Login.",
    });
  }
}

function* getUserProfileSaga() {
  try {
    // console.log('params', params)
    // yield put({ type: actionTypes.SET_ACCOUNT_LOADING });
    const { data } = yield API.get("/api/users/profile");

    if (data.success) {
 
      // yield put({
      //   type: actionTypes.GET_USER_SUCCESS,
      //   payload: data.data[0],
      // });
      // toast.success("Successfully Logged in ");
    } else {
      toast.error("Unable to load user data.");
      // yield put({
      //   type: actionTypes.GET_USER_FAILURE,
      //   errorMessage: "Unable to get User details.",
      // });
    }
  } catch (error) {
    toast.error("Unable to Login.");
    yield put({
      type: actionTypes.DO_LOGIN_FAILURE,
      errorMessage: "Unable to Login.",
    });
  }
}

function* doOnboardingSaga({ params }) {
  const { navigate, ...rest } = params;
  try {
    yield put({ type: actionTypes.SET_ACCOUNT_LOADING });
    const { data } = yield API.post("/api/users/signup-onboarding", rest);

    if (data.success) {
      yield put({
        type: actionTypes.DO_ONBOARDING_SUCCESS,
        payload: data,
      });
      navigate("/login");
      toast.success("Onboarding Success");
    } else {
      yield put({
        type: actionTypes.DO_ONBOARDING_FAILURE,
        errorMessage: "Unable to load Locations.",
      });
      toast.error(data.errorMessage);
    }
  } catch (error) {
    toast.error("Unable to load Locations.");
    yield put({
      type: actionTypes.DO_ONBOARDING_FAILURE,
      errorMessage: "Unable to load Locations.",
    });
  }
}

function* sendResetPasswordLinkSaga({ params }) {
  const { navigate, ...rest } = params;
  try {
    yield put({ type: actionTypes.SET_ACCOUNT_LOADING });
    const { data } = yield API.post("/api/users/forget-password", rest);

    if (data.success) {
      yield put({
        type: actionTypes.SEND_RESET_PASSWORD_LINK_SUCCESS,
        payload: data,
      });
      toast.success(data.message);
      navigate("/login");
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.SEND_RESET_PASSWORD_LINK_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (error) {
    toast.error(
      error?.response?.data?.message || "Unable to Send Reset Password Link."
    );
    yield put({
      type: actionTypes.SEND_RESET_PASSWORD_LINK_FAILURE,
      errorMessage: "Unable to Send Reset Password Link.",
    });
  }
}

function* resetPasswordSaga({ params }) {
  const { navigate, ...rest } = params;
  try {
    yield put({ type: actionTypes.SET_ACCOUNT_LOADING });
    const { data } = yield API.post("/api/users/reset-password", rest);

    if (data.success) {
      yield put({
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        payload: data,
      });
      toast.success(data.message);
      navigate("/login");
    } else {
      toast.error(data.message);
      yield put({
        type: actionTypes.RESET_PASSWORD_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.message || "Unable to Reset Password.");
    yield put({
      type: actionTypes.RESET_PASSWORD_FAILURE,
      errorMessage:
        error?.response?.data?.message || "Unable to Reset Password.",
    });
  }
}

function* AccountSaga() {
  yield all([
    takeLatest(actionTypes.DO_LOGIN, doLoginSaga),
    takeLatest(actionTypes.DO_ONBOARDING, doOnboardingSaga),
    takeLatest(actionTypes.SEND_RESET_PASSWORD_LINK, sendResetPasswordLinkSaga),
    takeLatest(actionTypes.RESET_PASSWORD, resetPasswordSaga),
    takeLatest(actionTypes.GET_USER, getUserProfileSaga),
  ]);
}

export default AccountSaga;
