import * as actionTypes from "./actionTypes";

export const doLoginAction = (params) => ({
  type: actionTypes.DO_LOGIN,
  params,
});
export const doOnboardingAction = (params) => ({
  type: actionTypes.DO_ONBOARDING,
  params,
});
export const setTokenFromLocalstorage = (token) => ({
  type: actionTypes.SET_TOKEN_FROM_LOCALSTORAGE,
  payload: token,
});

export const doLogoutAction = () => ({
  type: actionTypes.DO_LOGOUT,
});

export const sendResetPasswordLink = (params) => ({
  type: actionTypes.SEND_RESET_PASSWORD_LINK,
  params,
});

export const resetPassword = (params) => ({
  type: actionTypes.RESET_PASSWORD,
  params,
});

export const getUserProfile = (params) => ({
  type: actionTypes.GET_USER,
  params,
});
