import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { PrimaryButton } from "../UI/Buttons/Buttons";
import variables from "../../Assets/Styles/variables";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";

import "./Onboarding.scss";

const frequentlyOptions = [1, 2, 3, 4, 6, 8, 12];
function OnboardingPolicySchedule({ handleInput, handleNext, OnboardingData }) {
  const handleFormSubmit = () => {
    handleNext();
  };

  return (
    <div>
      <Grid
        container
        style={{ marginTop: "10px", textAlign: "center" }}
        flexDirection="column"
        alignItems="center"
        gap={3}
      >
        <Grid item sm={10} className="Question">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: variables.primaryTextColor,
            }}
          >
            <h1>Schedule your policy</h1>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginTop: "30px",
              textAlign: "center",
              color: variables.subTextColor,
            }}
          >
            <h2>Lorem ipsum dolor sit amet consectetur.</h2>
          </Box>
          <Grid container sx={{ width: "100%", marginTop: "50px" }} spacing={2}>
            <Grid item sm={6}>
              <FormControl variant="filled" sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-filled-label">
                  Schedule Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  // value={age}
                  variant="filled"
                  fullWidth
                  onChange={(e) =>
                    handleInput({
                      name: "schedule_type",
                      value: e.target.value,
                    })
                  }
                >
                  <MenuItem value="Daily">Once in A Day</MenuItem>
                  <MenuItem value="frequently">Frequently</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={6}>
              {OnboardingData.schedule_type === "frequently" ? (
                <TextField
                  id="filled-basic"
                  label="demo"
                  variant="filled"
                  fullWidth
                  value={OnboardingData.demo}
                  onChange={(e) =>
                    handleInput({ name: "demo", value: e.target.value })
                  }
                />
              ) : null}
            </Grid>

            <Grid item sm={6}>
              {OnboardingData.schedule_type === "Daily" ? (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StaticTimePicker
                    displayStaticWrapperAs="mobile"
                    value={OnboardingData.schedule_time}
                    onChange={(newValue) => {
                      handleInput({ name: "schedule_time", value: newValue });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              ) : (
                <FormControl variant="filled" sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Schedule Time
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={OnboardingData.scheduleFrequency}
                    variant="filled"
                    fullWidth
                    onChange={(e) =>
                      handleInput({
                        name: "scheduleFrequency",
                        value: e.target.value,
                      })
                    }
                  >
                    {frequentlyOptions.map((item) => (
                      <MenuItem value={item}>{item} Hours</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "20px",
            }}
            spacing={2}
          >
            <PrimaryButton
              style={{ height: "60px", minWidth: "240px" }}
              onClick={handleFormSubmit}
            >
              Next
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default OnboardingPolicySchedule;
