/* eslint-disable no-new-wrappers */
import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import PageHeader from "../../components/UI/PageWrapper/PageHeader";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  FilledInput,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import variables from "../../Assets/Styles/variables";
import PasswordIcon from "@mui/icons-material/Password";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/UI/Buttons/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import "./CreateUser.scss";
import { useDispatch, useSelector } from "react-redux";
import { getRolesAction } from "../../store/Roles/actions";
import {
  updateUsersAction,
  getUsersByIdAction,
  deleteUsersByIdAction,
} from "../../store/Users/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import HeaderWithBackButton from "../../components/UI/HeaderWithBackButton";
import { HasPermission } from "../../utils/HasPermission";

const MySwal = withReactContent(Swal);

function generatePassword(length) {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*_-+";
  let password = "";
  for (let i = 0; i < length; i++) {
    password += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return password;
}

const deviderStyles = { margin: "5px 0" };

const INITIALSTATE = {
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  password: "",
  roles: [],
  type: "admin",
  is_active: false,
};

function EditUser({ permissions }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({ ...INITIALSTATE });
  const { RolesList } = useSelector((state) => state.Roles);
  const { selectedRole } = useSelector((state) => state.Users);

  const handleInput = (e) => {
    if (e.target.name === "roles") {
      setUserData((cur) => {
        let obj = { ...cur };
        let arr = [
          { permission_id: e.target.value._id, title: e.target.value.title },
        ];
        obj.roles = arr;
        return obj;
      });
    } else {
      setUserData({ ...userData, [e.target.name]: e.target.value });
    }
  };

  const handleClickShowPassword = () => {
    const randomPassword = generatePassword(12);
    setUserData({ ...userData, password: randomPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleDelete = (Id) => {
    MySwal.fire({
      title: "Are you sure you want to delete this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete user!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUsersByIdAction(Id));
        navigate("/users");
      }
    });
  };
  const handleSubmit = () => {
    dispatch(
      updateUsersAction({
        ...userData,
        deleted_at: userData.deleted_at ? userData.deleted_at : "",
        navigate,
      })
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(getUsersByIdAction(id));
    }
    handleClickShowPassword();
    dispatch(getRolesAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    if (selectedRole._id) {
      setUserData({ ...selectedRole });
    }
  }, [selectedRole]);

  return (
    <PageWrapper>
      <PageHeader header={<HeaderWithBackButton header="User Management" />} />

      <Card>
        <CardHeader title="Edit User" />
        <Divider />
        <Grid
          container
          alignItems="center"
          justifyContent={id ? "center" : "start"}
          spacing={2}
          style={{ padding: "20px" }}
        >
          <Grid item sm={id ? 8.1 : 10}>
            <Divider textAlign="left">General Info</Divider>
          </Grid>
          <Grid item sm={4} style={{ display: id ? "none" : "block" }}>
            <Typography
              variant="body1"
              className="field-name"
              style={{ fontWeight: "bold" }}
            >
              Name{" "}
            </Typography>
            <Typography
              variant="caption"
              className="field-description"
              style={{ color: variables.subTextColor }}
            >
              Full name of the user
            </Typography>
          </Grid>
          <Grid item sm={id ? 4 : 3}>
            <TextField
              fullWidth
              label="First Name"
              name="first_name"
              value={userData.first_name}
              onChange={handleInput}
              variant="filled"
              placeholder="First Name..."
            />
          </Grid>
          <Grid item sm={id ? 4 : 3}>
            <TextField
              fullWidth
              label="Last Name"
              name="last_name"
              value={userData.last_name}
              onChange={handleInput}
              variant="filled"
              placeholder="Last Name..."
            />
          </Grid>
          <Grid item sm={4} style={{ display: id ? "none" : "block" }}>
            <Typography
              variant="body1"
              className="field-name"
              style={{ fontWeight: "bold" }}
            >
              Email Address{" "}
            </Typography>
            <Typography variant="caption" className="field-description">
              Email is used for communication purposes
            </Typography>
          </Grid>
          <Grid item sm={id ? 8 : 6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={userData.email}
              onChange={handleInput}
              variant="filled"
              placeholder="User Email Id..."
            />
          </Grid>
          <Grid item sm={4} style={{ display: id ? "none" : "block" }}>
            <Typography
              variant="body1"
              className="field-name"
              style={{ fontWeight: "bold" }}
            >
              Phone Number{" "}
            </Typography>
            <Typography variant="caption" className="field-description">
              Phone Number is used for communication purposes
            </Typography>
          </Grid>

          <Grid item sm={id ? 8 : 6}>
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              value={userData.phone}
              onChange={handleInput}
              variant="filled"
              placeholder="Phone"
            />
          </Grid>
          <Grid item sm={id ? 8 : 10}>
            <Divider style={deviderStyles} textAlign="left">
              Security
            </Divider>
          </Grid>
          <Grid item sm={4} style={{ display: id ? "none" : "block" }}>
            <Typography
              variant="body1"
              className="field-name"
              style={{ fontWeight: "bold" }}
            >
              Password{" "}
            </Typography>
            <Typography variant="caption" className="field-description">
              Collects user's password for authentication and security.
            </Typography>
          </Grid>
          <Grid item sm={id ? 8 : 6}>
            <FormControl fullWidth variant="filled">
              <InputLabel htmlFor="filled-adornment-password">
                Password
              </InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type="text"
                value={userData.password}
                name="password"
                onChange={handleInput}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      <PasswordIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item sm={4} style={{ display: id ? "none" : "block" }}>
            <Typography
              variant="body1"
              className="field-name"
              style={{ fontWeight: "bold" }}
            >
              Active Status{" "}
            </Typography>
            <Typography variant="caption" className="field-description">
              Indicates user account or feature's current active or inactive.
            </Typography>
          </Grid>
          <Grid item sm={id ? 8 : 6}>
            <FormControlLabel
              value="end"
              control={
                <Switch
                  color="primary"
                  checked={userData.is_active}
                  name="is_active"
                  onClick={() =>
                    setUserData((cur) => {
                      return { ...cur, is_active: !userData.is_active };
                    })
                  }
                />
              }
              label="Active Status Enabled"
              labelPlacement="end"
            />
          </Grid>
          <Grid item sm={id ? 8 : 10}>
            <Divider style={deviderStyles} textAlign="left">
              Roles and Permissions
            </Divider>
          </Grid>
          <Grid item sm={4} style={{ display: id ? "none" : "block" }}>
            <Typography
              variant="body1"
              className="field-name"
              style={{ fontWeight: "bold" }}
            >
              Permissions{" "}
            </Typography>
            <Typography variant="caption" className="field-description">
              Specifies user access level and actions allowed in the
              application.
            </Typography>
          </Grid>
          <Grid item sm={id ? 8 : 6}>
            <FormControl fullWidth variant="filled" sx={{ minWidth: 300 }}>
              <InputLabel id="demo-simple-select-filled-label">
                User Role
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                selected={{
                  _id: userData.roles[0]?.permission_id,
                  title: userData.roles[0]?.title,
                }}
                value={{
                  _id: userData.roles[0]?.permission_id,
                  title: userData.roles[0]?.title,
                }}
                onChange={handleInput}
                name="roles"
                input={<FilledInput id="select-multiple-chip" label="Chip" />}
                variant="filled"
                fullWidth
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    <Chip
                      variant="filled"
                      style={{
                        backgroundColor: variables.primaryColor,
                        color: variables.whiteText,
                      }}
                      key={selected.title}
                      label={selected.title}
                    />
                  </Box>
                )}
              >
                {RolesList?.map((item) => {
                  return (
                    <MenuItem value={{ _id: item._id, title: item.title }}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={id ? 8 : 10}>
            <Divider style={{ marginTop: "5px" }} textAlign="left">
              Actions
            </Divider>
          </Grid>
          <Grid item sm={id ? 8 : 10}>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                widht: "100%",
                alignItems: "center",
                justifyContent: "end",
                gap: "20px",
              }}
            >
              {id && HasPermission("crawler_users_delete") ? (
                <Button
                  variant="outlined"
                  onClick={() => handleDelete(id)}
                  color="error"
                >
                  Delete the Account
                </Button>
              ) : null}

              <SecondaryButton
                variant="outlined"
                onClick={() => navigate("/users")}
              >
                Cancel
              </SecondaryButton>
              {HasPermission("crawler_users_edit") && id ? (
                <PrimaryButton className="" onClick={handleSubmit}>
                  {!id ? "Add New User" : "Edit the User"}
                </PrimaryButton>
              ) : (
                <span></span>
              )}

              {HasPermission("crawler_users_create") && !id ? (
                <PrimaryButton className="" onClick={handleSubmit}>
                  {!id ? "Add New User" : "Edit the User"}
                </PrimaryButton>
              ) : (
                <span></span>
              )}
            </div>
          </Grid>
        </Grid>
      </Card>
    </PageWrapper>
  );
}

export default EditUser;
